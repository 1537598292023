import * as marketOrdersActions from "Base/store/trade/marketOrders/actions"
import * as tradesActions from "Base/store/trade/trades/actions"
import { takeLatest, call, put } from "redux-saga/effects"

import {
  TPostMarketOrdersReqAction,
  TPutMarketOrdersReqAction,
  TDeleteMarketOrdersReqAction,
  TGetMarketOrdersUserReqAction,
} from "./types"
import { getErrorMessage } from "Base/utils/exaptations"
import { toast } from "react-toastify"
import { marketOrdersProvider } from "Base/api/rest/globalApiProviders"
import {
  TDeleteMarketOrdersResponse,
  TGetMarketOrdersResponse,
  TGetMarketOrdersUserResponse,
  TPostMarketOrdersResponse,
  TPutMarketOrdersResponse,
} from "Base/api/rest/types/trade/marketOrders"

export function* getMarketOrdersSaga() {
  try {
    const resp: TGetMarketOrdersResponse = yield call([
      marketOrdersProvider,
      marketOrdersProvider.getMarketOrders,
    ])
    if (!resp.data) throw new Error("Unknown error")
    yield put(marketOrdersActions.getMarketOrdersAction.success(resp.data))
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      marketOrdersActions.getMarketOrdersAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* getMarketOrdersUserSaga({ payload }: TGetMarketOrdersUserReqAction) {
  try {
    const resp: TGetMarketOrdersUserResponse = yield call([
      marketOrdersProvider,
      marketOrdersProvider.getMarketOrdersUser,
    ])
    if (!resp.data) throw new Error("Unknown error")
    yield put(marketOrdersActions.getMarketOrdersUserAction.success(resp.data))
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      marketOrdersActions.getMarketOrdersUserAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* postMarketOrdersSaga({ payload }: TPostMarketOrdersReqAction) {
  try {
    const resp: TPostMarketOrdersResponse = yield call(
      [marketOrdersProvider, marketOrdersProvider.postMarketOrders],
      {
        data: payload,
      },
    )
    if (!resp.success) throw new Error("Unknown error")
    console.log("postMarketOrdersSaga, resp: ", resp)

    yield put(marketOrdersActions.postMarketOrdersAction.success(resp.data))
    toast.success("Успешно")
    yield put(tradesActions.getTradesUserAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      marketOrdersActions.postMarketOrdersAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* putMarketOrdersSaga({ payload }: TPutMarketOrdersReqAction) {
  try {
    const resp: TPutMarketOrdersResponse = yield call(
      [marketOrdersProvider, marketOrdersProvider.putMarketOrders],
      {
        data: payload,
      },
    )
    if (!resp.success) throw new Error("Unknown error")
    yield put(marketOrdersActions.putMarketOrdersAction.success(resp.data))
    toast.success("Успешно")
    yield put(tradesActions.getTradesAction.call({}))
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      marketOrdersActions.putMarketOrdersAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* deleteMarketOrdersSaga({ payload }: TDeleteMarketOrdersReqAction) {
  try {
    const resp: TDeleteMarketOrdersResponse = yield call(
      [marketOrdersProvider, marketOrdersProvider.deleteMarketOrders],
      {
        params: payload,
      },
    )
    if (!resp.success) throw new Error("Unknown error")
    yield put(marketOrdersActions.deleteMarketOrdersAction.success(resp.data))
    toast.success("Успешно")
    yield put(marketOrdersActions.getMarketOrdersUserAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      marketOrdersActions.deleteMarketOrdersAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* marketOrdersWatcher() {
  yield takeLatest(marketOrdersActions.getMarketOrdersAction.call.type, getMarketOrdersSaga)
  yield takeLatest(marketOrdersActions.getMarketOrdersUserAction.call.type, getMarketOrdersUserSaga)
  yield takeLatest(marketOrdersActions.postMarketOrdersAction.call.type, postMarketOrdersSaga)
  yield takeLatest(marketOrdersActions.putMarketOrdersAction.call.type, putMarketOrdersSaga)
  yield takeLatest(marketOrdersActions.deleteMarketOrdersAction.call.type, deleteMarketOrdersSaga)
}
