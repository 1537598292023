import React, { useEffect } from "react"
import { Route, Navigate } from "react-router-dom"

import { adminRoutes } from "Base/constants/routes/adminRoutes"
import { Roles, Users } from "Admin/tsx/pages"
import { FinancialAssetTypes } from "Admin/tsx/pages/FinancialAssetTypes"
import { FinancialAssets } from "Admin/tsx/pages/FinancialAssets"
import * as rolesActions from "Base/store/user/roles/actions"
import * as financialAssetTypesActions from "Base/store/trade/financialAssetTypes/actions"
import { useDispatch } from "react-redux"
import { Platforms } from "Admin/tsx/pages/Platforms"
import { BaseLayout } from "Base/tsx/layouts"
import { AdminLayout } from "Admin/tsx/layouts/AdminLayout"

export const getAdminRouters = () => {
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   console.log("getAdminRouters, useEffect")
  //   dispatch(rolesActions.getRolesAction.call())
  //   dispatch(financialAssetTypesActions.getFinancialAssetTypesAction.call())
  // }, [])
  return (
    <>
      <Route
        path={adminRoutes.users.root}
        element={
          <BaseLayout>
            <AdminLayout>
              <Users />
            </AdminLayout>
          </BaseLayout>
        }
      />
      <Route
        path={adminRoutes.roles.root}
        element={
          <BaseLayout>
            <AdminLayout>
              <Roles />
            </AdminLayout>
          </BaseLayout>
        }
      />
      <Route
        path={adminRoutes.platforms.root}
        element={
          <BaseLayout>
            <AdminLayout>
              <Platforms />
            </AdminLayout>
          </BaseLayout>
        }
      />
      <Route
        path={adminRoutes["financial-asset-types"].root}
        element={
          <BaseLayout>
            <AdminLayout>
              <FinancialAssetTypes />
            </AdminLayout>
          </BaseLayout>
        }
      />
      <Route
        path={adminRoutes["financial-assets"].root}
        element={
          <BaseLayout>
            <AdminLayout>
              <FinancialAssets />
            </AdminLayout>
          </BaseLayout>
        }
      />
      <Route
        path={`${adminRoutes.root}/*`}
        element={<Navigate to={adminRoutes.users.root} replace={false} />}
      />
    </>
  )
}
