import { cryptoIcons } from "Base/assets/icons/cryptocurrency/incex"

export type TCurrencyOption = {
  label: string
  value: string
  icon?: string
}
export const currencyOptions: Array<TCurrencyOption> = [
  {
    label: "Russian ruble",
    value: "RUB",
  },
  {
    label: "Euro",
    value: "EUR",
  },
  {
    label: "United States dollar",
    value: "USD",
  },
  {
    label: "Tether USDt",
    value: "USDT",
    icon: cryptoIcons.USDT,
  },
]

export const currencyOptionsObj: {
  [key: string]: TCurrencyOption
} = currencyOptions.reduce(
  (currencyOptionObj: { [key: string]: TCurrencyOption }, currencyOption: TCurrencyOption) => ({
    ...currencyOptionObj,
    [currencyOption.value]: currencyOption,
  }),
  {},
)
