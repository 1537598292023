import styles from "./ChangeInPurchaseRateItem.module.scss"
import React from "react"
import cn from "classnames"
import { TExchangeRateTonapi } from "Base/store/deprecated/exchangeRate/types"
import { getProfit } from "Base/utils/profit"
import { TTrade } from "Base/api/rest/types/trade/trades"
import { TFinancialAssetRate } from "Base/api/rest/types/trade/financialAssetRates"

export const ChangeInPurchaseRateItem = ({
  tonapi,
  cryptocurrencyName,
  currencyName,
  cryptocurrencyRate,
}: {
  tonapi: TExchangeRateTonapi
  cryptocurrencyName: string
  currencyName: string
  cryptocurrencyRate: number
}) => {
  if (!tonapi?.[cryptocurrencyName]?.prices?.[currencyName]) return <></>
  const price = tonapi[cryptocurrencyName].prices[currencyName]
  const profit = getProfit(price, cryptocurrencyRate)
  return (
    <div
      className={cn(styles.profit, {
        [styles.profit__green]: profit > 0,
        [styles.profit__red]: profit < 0,
      })}
    >
      {profit} %
    </div>
  )
}

export const ChangeInPurchaseRateItemV2 = ({
  financialAssetRate,
  trade,
}: {
  financialAssetRate: TFinancialAssetRate
  trade: TTrade
}) => {
  const newRate = financialAssetRate.rate
  // console.log("ChangeInPurchaseRateItemV2, financialAssetRate: ", financialAssetRate)
  // console.log("ChangeInPurchaseRateItemV2, newRate: ", newRate)
  // console.log("ChangeInPurchaseRateItemV2, trade.rate: ", trade.rate)
  const profit = getProfit(newRate, trade.rate)
  // console.log("ChangeInPurchaseRateItemV2, profit: ", profit)
  return (
    <div
      className={cn(styles.profit, {
        [styles.profit__green]: profit > 0,
        [styles.profit__red]: profit < 0,
      })}
    >
      {profit} %
    </div>
  )
}
