import { format } from "date-fns"
import { TFullTransaction } from "Base/types/deprecated/trade/transactions"

export const handleDownload = (data: TFullTransaction[]) => {
  const blob = new Blob([JSON.stringify(creatingObjectForDownloading(data))], {
    type: "application/json",
  })
  const url = URL.createObjectURL(blob)
  const a = document.createElement("a")
  const formattedDateTime = format(new Date(), "yyyy-MM-dd HH:mm:ss")
  a.href = url
  a.download = "trade-data (" + formattedDateTime + ").json"
  a.click()
  URL.revokeObjectURL(url)
}

export type TObjectForDownloadingVersions = "1"

export type TObjectDownloadV1 = {
  version: TObjectForDownloadingVersions
  data: Array<TFullTransaction>
}

export const creatingObjectForDownloading = (data: TFullTransaction[]) => {
  return {
    version: "1",
    data: data as TFullTransaction[],
  }
}
