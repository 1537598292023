import { add } from "Base/utils/formulas"
import { TCurrenciesTurnover } from "Base/store/deprecated/transactionsInfo/reducer"
import { TFullTransaction } from "Base/types/deprecated/trade/transactions"

export const calculateCurrenciesTurnoverPlus = (
  currenciesTurnover: TCurrenciesTurnover,
  transaction: TFullTransaction,
) => {
  const value =
    (currenciesTurnover[transaction.currencyName] || 0) + (transaction.sell?.currencyValue || 0)
  return {
    ...currenciesTurnover,
    [transaction.currencyName]: value,
  }
}

export const calculateCurrenciesTurnoverMinus = (
  currenciesTurnover: TCurrenciesTurnover,
  transaction: TFullTransaction,
) => {
  const value =
    (currenciesTurnover[transaction.currencyName] || 0) - (transaction.sell?.currencyValue || 0)
  return {
    ...currenciesTurnover,
    [transaction.currencyName]: value,
  }
}
