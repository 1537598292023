import { Button, Modal } from "Base/tsx/components"
import styles from "./DeleteFinancialAssetTypeFormModal.module.scss"
import React from "react"
import { useDispatch } from "react-redux"
import * as financialAssetTypesActions from "Base/store/trade/financialAssetTypes/actions"
import { TFinancialAssetType } from "Base/api/rest/types/trade/financialAssetTypes"

export const DeleteFinancialAssetTypeFormModal = ({
  financialAssetTypeChange,
  isModalVisible,
  setIsModalVisible,
}: {
  financialAssetTypeChange?: TFinancialAssetType
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
}) => {
  const dispatch = useDispatch()

  return (
    <Modal
      title='FinancialAssetType removal confirmation'
      visible={isModalVisible}
      onClose={() => setIsModalVisible(false)}
    >
      <h3>Are you sure you want to delete the financialAssetType?</h3>
      <div className={styles.main}>
        <Button
          onClick={() => {
            financialAssetTypeChange &&
              dispatch(
                financialAssetTypesActions.deleteFinancialAssetTypesAction.call(
                  financialAssetTypeChange,
                ),
              )
            setIsModalVisible(false)
          }}
        >
          Delete
        </Button>
        <Button
          theme='secondary'
          onClick={() => {
            setIsModalVisible(false)
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}
