import { createReducer } from "@reduxjs/toolkit"

import { TGetExchangeInfoFailureAction } from "./types"
import { TRequestInfoChildType } from "Base/types/store/reducer"
import { RequestInfoChildState } from "Base/constants/statesDefault"
import { binanceActions } from "Base/store/deprecated/binance/actions"

export type BinanceStateType = {
  getExchangeInfo: TRequestInfoChildType
}

const initialState: BinanceStateType = {
  getExchangeInfo: {
    ...RequestInfoChildState,
  },
}

export const binanceReducer = createReducer(initialState, (builder) => {
  builder
    //wsExchangeRateAction
    .addCase(binanceActions.getExchangeInfoAction.call.type, (state) => {
      state.getExchangeInfo.isLoading = true
    })
    .addCase(binanceActions.getExchangeInfoAction.success.type, (state) => {
      state.getExchangeInfo.isLoading = false
    })
    .addCase(
      binanceActions.getExchangeInfoAction.failure.type,
      (state, action: TGetExchangeInfoFailureAction) => {
        state.getExchangeInfo.error = action.payload.error
        state.getExchangeInfo.isLoading = false
      },
    )
})
