import { toast } from "react-toastify"
import { takeLatest, put, call } from "redux-saga/effects"

import * as authPersistActions from "Base/store/user/authPersist/actions"
import * as profilePersistActions from "Base/store/user/profilePersist/actions"
import { getErrorMessage } from "Base/utils/exaptations"
import { authProvider } from "Base/api/rest/globalApiProviders"

export function* getAuthLogoutSaga() {
  try {
    yield call([authProvider, authProvider.getAuthLogout])
    yield put(authPersistActions.getAuthLogoutAction.success())
    yield put(profilePersistActions.getProfileAction.initial())
    console.log("authPersistLogoutSaga")
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      authPersistActions.getAuthLogoutAction.failure({
        error: message,
      }),
    )
  }
}

export function* authPersistWatcher() {
  yield takeLatest(authPersistActions.getAuthLogoutAction.call.type, getAuthLogoutSaga)
}
