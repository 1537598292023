import React from "react"

import styles from "MainModule/tsx/pages/MainPage/MainPage.module.scss"
import { LanguageChanger } from "Base/tsx/containers"
import { ThemeSwitch } from "Base/tsx/components"
import { LogoIcon } from "Base/tsx/components/icons/LogoIcon"
import { authModuleRoutes } from "Base/constants/routes"

const projects = [
  {
    id: 1,
    title: "Проект 1",
    description: "Описание проекта 1. Использованы технологии: React, Web3.",
    link: authModuleRoutes.auth.root,
  },
  {
    id: 2,
    title: "Проект 2",
    description: "Описание проекта 2. Использованы технологии: TypeScript, Redux.",
    link: authModuleRoutes.auth.root,
  },
  {
    id: 3,
    title: "Проект 3",
    description: "Описание проекта 3. Использованы технологии: Solana, Phantom.",
    link: authModuleRoutes.auth.root,
  },
]

const ProjectCards = ({ projects }: any) => {
  return (
    <div className={styles.cardsContainer}>
      {projects.map((project: any) => (
        <div key={project.id} className={styles.card}>
          <h3>{project.title}</h3>
          <p>{project.description}</p>
          <a href={project.link} target='_blank' rel='noopener noreferrer' className={styles.link}>
            Открыть проект
          </a>
        </div>
      ))}
    </div>
  )
}

export function MainPage() {
  return (
    <div className={styles.main_page}>
      <header>
        <div className={styles.logo}>
          <LogoIcon width={40} height={40} />
          <h2>Genemer (v{process.env.REACT_APP_VERSION})</h2>
        </div>
        <div className={styles.controls}>
          <ThemeSwitch />
          <LanguageChanger backgroundOn={false} />
        </div>
      </header>
      <div>
        <ProjectCards projects={projects} />
      </div>
    </div>
  )
}
