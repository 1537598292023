import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as userAssetsActions from "Base/store/user/userAssets/actions"
import { TRequestInfoChildType } from "Base/types/store/reducer"

import { TGetUserAssetsFailureAction, TGetUserAssetsSuccessAction } from "./types"
import { TGetUserAssetsResponseData } from "Base/api/rest/types/trade/userAssets"

export type UserAssetsStateType = {
  getUserAssets: {
    data?: TGetUserAssetsResponseData
  } & TRequestInfoChildType
}

const initialState: UserAssetsStateType = {
  getUserAssets: {
    ...RequestInfoChildState,
  },
}

export const userAssetsReducer = createReducer(initialState, {
  [userAssetsActions.getUserAssetsAction.call.type]: (state) => ({
    ...state,
    getUserAssets: {
      ...state.getUserAssets,
      isLoading: true,
    },
  }),
  [userAssetsActions.getUserAssetsAction.success.type]: (
    state,
    action: TGetUserAssetsSuccessAction,
  ) => ({
    ...state,
    getUserAssets: {
      ...state.getUserAssets,
      data: action.payload,
      isLoading: false,
    },
  }),
  [userAssetsActions.getUserAssetsAction.failure.type]: (
    state,
    action: TGetUserAssetsFailureAction,
  ) => ({
    ...state,
    getUserAssets: {
      ...state.getUserAssets,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
  //------------------------------------------------------------------
})
