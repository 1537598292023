import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as marketOrdersActions from "Base/store/trade/marketOrders/actions"
import { TRequestInfoChildType } from "Base/types/store/reducer"

import {
  TGetMarketOrdersFailureAction,
  TGetMarketOrdersSuccessAction,
  TPostMarketOrdersFailureAction,
  TPostMarketOrdersSuccessAction,
  TPutMarketOrdersFailureAction,
  TPutMarketOrdersSuccessAction,
  TDeleteMarketOrdersSuccessAction,
  TDeleteMarketOrdersFailureAction,
  TGetMarketOrdersUserFailureAction,
} from "./types"
import {
  TDeleteMarketOrdersResponseData,
  TGetMarketOrdersResponseData,
  TGetMarketOrdersUserResponseData,
  TPostMarketOrdersResponseData,
  TPutMarketOrdersResponseData,
} from "Base/api/rest/types/trade/marketOrders"

export type MarketOrdersStateType = {
  getMarketOrders: {
    data?: TGetMarketOrdersResponseData
  } & TRequestInfoChildType
  getMarketOrdersUser: {
    data?: TGetMarketOrdersUserResponseData
  } & TRequestInfoChildType
  postMarketOrders: {
    data?: TPostMarketOrdersResponseData
  } & TRequestInfoChildType
  putMarketOrders: {
    data?: TPutMarketOrdersResponseData
  } & TRequestInfoChildType
  deleteMarketOrders: {
    data?: TDeleteMarketOrdersResponseData
  } & TRequestInfoChildType
}

const initialState: MarketOrdersStateType = {
  getMarketOrders: {
    ...RequestInfoChildState,
  },
  getMarketOrdersUser: {
    ...RequestInfoChildState,
  },
  postMarketOrders: {
    ...RequestInfoChildState,
  },
  putMarketOrders: {
    ...RequestInfoChildState,
  },
  deleteMarketOrders: {
    ...RequestInfoChildState,
  },
}

export const marketOrdersReducer = createReducer(initialState, {
  [marketOrdersActions.getMarketOrdersAction.call.type]: (state) => ({
    ...state,
    getMarketOrders: {
      ...state.getMarketOrders,
      isLoading: true,
    },
  }),
  [marketOrdersActions.getMarketOrdersAction.success.type]: (
    state,
    action: TGetMarketOrdersSuccessAction,
  ) => ({
    ...state,
    getMarketOrders: {
      ...state.getMarketOrders,
      data: action.payload,
      isLoading: false,
    },
  }),
  [marketOrdersActions.getMarketOrdersAction.failure.type]: (
    state,
    action: TGetMarketOrdersFailureAction,
  ) => ({
    ...state,
    getMarketOrders: {
      ...state.getMarketOrders,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
  [marketOrdersActions.getMarketOrdersUserAction.call.type]: (state) => ({
    ...state,
    getMarketOrdersUser: {
      ...state.getMarketOrdersUser,
      isLoading: true,
    },
  }),
  [marketOrdersActions.getMarketOrdersUserAction.success.type]: (state, action) => ({
    ...state,
    getMarketOrdersUser: {
      ...state.getMarketOrdersUser,
      data: action.payload,
      isLoading: false,
    },
  }),
  [marketOrdersActions.getMarketOrdersUserAction.failure.type]: (
    state,
    action: TGetMarketOrdersUserFailureAction,
  ) => ({
    ...state,
    getMarketOrdersUser: {
      ...state.getMarketOrdersUser,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [marketOrdersActions.getMarketOrdersUserAction.initial.type]: (state) => ({
    ...state,
    getMarketOrdersUser: {
      data: undefined,
      error: undefined,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
  [marketOrdersActions.postMarketOrdersAction.call.type]: (state) => ({
    ...state,
    postMarketOrders: {
      ...state.postMarketOrders,
      isLoading: true,
    },
  }),
  [marketOrdersActions.postMarketOrdersAction.success.type]: (
    state,
    action: TPostMarketOrdersSuccessAction,
  ) => ({
    ...state,
    postMarketOrders: {
      ...state.postMarketOrders,
      data: action.payload,
      isLoading: false,
    },
  }),
  [marketOrdersActions.postMarketOrdersAction.failure.type]: (
    state,
    action: TPostMarketOrdersFailureAction,
  ) => ({
    ...state,
    postMarketOrders: {
      ...state.postMarketOrders,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
  [marketOrdersActions.putMarketOrdersAction.call.type]: (state) => ({
    ...state,
    putMarketOrders: {
      ...state.putMarketOrders,
      isLoading: true,
    },
  }),
  [marketOrdersActions.putMarketOrdersAction.success.type]: (
    state,
    action: TPutMarketOrdersSuccessAction,
  ) => ({
    ...state,
    putMarketOrders: {
      ...state.putMarketOrders,
      data: action.payload,
      isLoading: false,
    },
  }),
  [marketOrdersActions.putMarketOrdersAction.failure.type]: (
    state,
    action: TPutMarketOrdersFailureAction,
  ) => ({
    ...state,
    putMarketOrders: {
      ...state.putMarketOrders,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
  [marketOrdersActions.deleteMarketOrdersAction.call.type]: (state) => ({
    ...state,
    deleteMarketOrders: {
      ...state.deleteMarketOrders,
      isLoading: true,
    },
  }),
  [marketOrdersActions.deleteMarketOrdersAction.success.type]: (
    state,
    action: TDeleteMarketOrdersSuccessAction,
  ) => ({
    ...state,
    deleteMarketOrders: {
      ...state.deleteMarketOrders,
      data: action.payload,
      isLoading: false,
    },
  }),
  [marketOrdersActions.deleteMarketOrdersAction.failure.type]: (
    state,
    action: TDeleteMarketOrdersFailureAction,
  ) => ({
    ...state,
    deleteMarketOrders: {
      ...state.deleteMarketOrders,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
})
