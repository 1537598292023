import { takeLatest, put, call, select } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"
import { exchangeRateActions } from "Base/store/deprecated/exchangeRate/actions"
import { tonapiActions } from "Base/store/deprecated/tonapi/actions"
import axios from "axios"
import { RootState } from "Starter/store/configureStore"
import { TExchangeRateTonapi } from "Base/store/deprecated/exchangeRate/types"

function* getRatesSaga() {
  // Здесь вы можете, например, диспетчеризовать экшен с полученными данными
  try {
    const { currenciesNames, cryptocurrenciesNames } = yield select(
      ({ base }: RootState) => base.transactionsInfo,
    )
    const tokens = Object.keys(cryptocurrenciesNames).join(",")
    const currencies = Object.keys(currenciesNames).join(",")
    if (tokens && currencies) {
      const response: {
        data: {
          rates: TExchangeRateTonapi
        }
      } = yield call(
        axios.get,
        "https://tonapi.io/v2/rates?tokens=" + tokens + "&currencies=" + currencies,
      )
      if (!response?.data?.rates) throw new Error("Unknown error")

      yield put(
        exchangeRateActions.setExchangeRateAction.call({
          tonapi: response.data.rates,
        }),
      )
    }
    yield put(tonapiActions.getRatesAction.success())
  } catch (e) {
    yield put(
      tonapiActions.getRatesAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* tonapiWatcher(): SagaIterator {
  yield takeLatest(tonapiActions.getRatesAction.call.type, getRatesSaga)
}
