import {
  Button,
  FileDownload,
  FileUpload,
  DropdownButton,
  CurrencyNameItem,
  ChangeInPurchaseRateItem,
  Checkbox,
} from "Base/tsx/components"
import { Table } from "Base/tsx/components/other/Table"
import { Column } from "react-table"
import styles from "./MainContent.module.scss"
import React, { useEffect, useMemo, useState } from "react"
import { RootState } from "Starter/store/configureStore"
import { useDispatch, useSelector } from "react-redux"
import { transactionsActions } from "Base/store/deprecated/transactions/actions"
import { TFullTransaction, TransactionStatuses } from "Base/types/deprecated/trade/transactions"
import { cryptocurrencyOptionsObj } from "Base/constants/cryptocurrencyOptions"
import { currencyOptionsObj } from "Base/constants/currencyOptions"
import cn from "classnames"
import { ArchiveFormModal, BuyFormModal, DeleteFormModal, SaleFormModal } from "../../modals"
import { getProfit } from "Base/utils/profit"
import { ProfitItem } from "Base/tsx/components/ProfitItem"
import { StringParam, useQueryParam } from "use-query-params"

interface TData extends TFullTransaction {
  cryptocurrencyName: string
  buyCryptocurrencyValue: string
  buyCryptocurrencyRate: string
  buyCurrencyValue: string
  buyDateOfPurchase: string
  sellCurrencyValue: string
  action?: React.ReactNode
  changeInPurchaseRate?: React.ReactNode
  profit?: React.ReactNode
}

export const MainContent = () => {
  const dispatch = useDispatch()
  const {
    transactions: { transactions },
    exchangeRate: {
      providers: { tonapi },
    },
    transactionsInfo: { cryptocurrenciesNames },
  } = useSelector((state: RootState) => state.base)
  const [isModalVisibleTransactionBuy, setIsModalVisibleTransactionBuy] = useState(false)
  const [isModalVisibleTransactionArchive, setIsModalVisibleTransactionArchive] = useState(false)
  const [isModalVisibleTransactionDelete, setIsModalVisibleTransactionDelete] = useState(false)
  const [isModalVisibleTransactionSale, setIsModalVisibleTransactionSale] = useState(false)
  const [transactionChange, setTransactionChange] = useState<TFullTransaction | undefined>()
  const [cryptocurrencyName, setCryptocurrencyName] = useQueryParam(
    "cryptocurrencyName",
    StringParam,
  )
  const [transactionsStatus = TransactionStatuses.purchased, setTransactionsStatus] = useQueryParam(
    "transactionsStatus",
    StringParam,
  )

  useEffect(() => {
    dispatch(transactionsActions.getTransactionsAction.call())
  }, [])

  const getActionOptions = (transaction: TFullTransaction) => {
    if (transaction.status === TransactionStatuses.purchased) {
      return [
        {
          label: "edit",
          onClick: () => {
            setTransactionChange(transaction)
            setIsModalVisibleTransactionBuy(true)
          },
        },
        {
          label: "archive",
          onClick: () => {
            setTransactionChange(transaction)
            setIsModalVisibleTransactionArchive(true)
          },
        },
        {
          label: "sale",
          onClick: () => {
            setTransactionChange(transaction)
            setIsModalVisibleTransactionSale(true)
          },
        },
      ]
    }
    if (transaction.status === TransactionStatuses.sold) {
      return [
        {
          label: "archive",
          onClick: () => {
            setTransactionChange(transaction)
            setIsModalVisibleTransactionArchive(true)
          },
        },
      ]
    }
    if (transaction.status === TransactionStatuses.archive) {
      return [
        {
          label: "delete",
          onClick: () => {
            setTransactionChange(transaction)
            setIsModalVisibleTransactionDelete(true)
          },
        },
      ]
    }

    return [
      {
        label: "archive",
        onClick: () => {
          setTransactionChange(transaction)
          setIsModalVisibleTransactionArchive(true)
        },
      },
    ]
  }

  const columns: Column<TData>[] = useMemo(
    () => [
      // const columns: Column<TData>[] = [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Trade platform name",
        accessor: "tradePlatformName",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          return (
            <span
              className={cn({
                [styles.status__blue]: row.original.status === TransactionStatuses.purchased,
                [styles.status__green]: row.original.status === TransactionStatuses.sold,
                [styles.status__red]: row.original.status === TransactionStatuses.archive,
              })}
            >
              {row.original.status}
            </span>
          )
        },
      },
      {
        Header: "Cryptocurrency name",
        accessor: "cryptocurrencyName",
        Cell: ({ row }) => {
          return (
            <CurrencyNameItem
              cryptocurrencyName={row.original.cryptocurrencyName}
              currencyOptionsObj={cryptocurrencyOptionsObj}
            />
          )
        },
      },
      {
        Header: "Cryptocurrency value",
        accessor: "buyCryptocurrencyValue",
        Cell: ({ row }) => <span>{row.original.buy.cryptocurrencyValue.toFixed(9)}</span>,
      },
      {
        Header: "Cryptocurrency rate",
        accessor: "buyCryptocurrencyRate",
        Cell: ({ row }) => <span>{row.original.buy.cryptocurrencyRate.toFixed(6)}</span>,
      },
      {
        Header: "Currency name",
        accessor: "currencyName",
        Cell: ({ row }) => {
          return (
            <CurrencyNameItem
              cryptocurrencyName={row.original.currencyName}
              currencyOptionsObj={currencyOptionsObj}
            />
          )
        },
      },
      {
        Header: "Currency value",
        accessor: "buyCurrencyValue",
      },
      {
        Header: "Profit",
        accessor: "profit",
        Cell: ({ row }) => {
          return <ProfitItem transaction={row.original} />
        },
      },
      {
        Header: "Change in purchase rate",
        accessor: "changeInPurchaseRate",
        sortType: (a: any, b: any) => {
          if (!tonapi?.[a.original.cryptocurrencyName]?.prices?.[a.original.currencyName]) return
          if (!tonapi?.[b.original.cryptocurrencyName]?.prices?.[b.original.currencyName]) return
          const priceA = tonapi[a.original.cryptocurrencyName].prices[a.original.currencyName]
          const profitA = getProfit(priceA, a.original.buy.cryptocurrencyRate)
          const priceB = tonapi[b.original.cryptocurrencyName].prices[b.original.currencyName]
          const profitB = getProfit(priceB, b.original.buy.cryptocurrencyRate)
          return Number(profitA) - Number(profitB)
        },
        Cell: ({ row }) => {
          return (
            <ChangeInPurchaseRateItem
              tonapi={tonapi}
              cryptocurrencyRate={row.original.buy.cryptocurrencyRate}
              cryptocurrencyName={row.original.cryptocurrencyName}
              currencyName={row.original.currencyName}
            />
          )
        },
      },
      {
        Header: "Date of purchase",
        accessor: "buyDateOfPurchase",
        Cell: ({ row }) => {
          return <span>{row.original.buy.dateOfPurchase}</span>
        },
      },
      {
        Header: "Date of change",
        accessor: "dateOfChange",
      },
      {
        Header: "Actions",
        accessor: "action",
        Cell: ({ row }) => {
          return <DropdownButton options={getActionOptions(row.original)}>select</DropdownButton>
        },
      },
    ],
    [tonapi, transactions],
  )

  const tableData: TData[] = useMemo(
    () =>
      transactions
        .filter((transaction) => !transactionsStatus || transaction.status === transactionsStatus)
        .filter(
          (transaction) =>
            !cryptocurrencyName || transaction.cryptocurrencyName === cryptocurrencyName,
        )
        .map((transaction) => ({
          ...transaction,
          buyCryptocurrencyValue: String(transaction.buy.cryptocurrencyValue),
          buyCryptocurrencyRate: String(transaction.buy.cryptocurrencyRate),
          buyCurrencyValue: String(transaction.buy.currencyValue),
          buyDateOfPurchase: transaction.buy.dateOfPurchase,
          sellCurrencyValue: transaction.sell ? String(transaction.sell.currencyValue) : "",
        })),
    [transactions, transactionsStatus, cryptocurrencyName, tonapi],
  )

  const handleFileLoaded = (json: TFullTransaction[]) => {
    dispatch(transactionsActions.importTransactionsFromFileAction.call(json))
  }

  // console.log("tableData: ", tableData)
  return (
    <main className={styles.main_content}>
      <>
        <div className={styles.file_actions}>
          <FileUpload onFileLoaded={handleFileLoaded} />
          <FileDownload data={transactions} />
        </div>
        <Button onClick={() => setIsModalVisibleTransactionBuy(true)}>Add transaction</Button>
        <div className={styles.checkbox_wrapper}>
          <span>Statuses:</span>
          {Object.keys(TransactionStatuses).map((status, index) => (
            <Checkbox
              title={status}
              key={index}
              id={status}
              checked={transactionsStatus === status}
              onChange={() => {
                setTransactionsStatus(transactionsStatus === status ? undefined : status)
              }}
            />
          ))}
        </div>
        <div className={styles.checkbox_wrapper}>
          <span>Cryptocurrencies:</span>
          {Object.keys(cryptocurrenciesNames).map((name, index) => (
            <Checkbox
              key={index}
              id={name}
              title={name}
              checked={cryptocurrencyName === name}
              onChange={() => {
                setCryptocurrencyName(cryptocurrencyName === name ? undefined : name)
              }}
            />
          ))}
        </div>
        <Table<TData>
          data={tableData}
          columns={columns}
          // currentPage={currentPage}
          // onPageChange={handlePageChange}
          defaultSorting={[
            {
              id: "changeInPurchaseRate", // Идентификатор колонки, по которой будет сортировка
              desc: true, // Направление сортировки
            },
          ]}
        />
      </>
      <>
        <BuyFormModal
          isModalVisible={isModalVisibleTransactionBuy}
          setIsModalVisible={(value) => {
            setTransactionChange(undefined)
            setIsModalVisibleTransactionBuy(value)
          }}
          transactionChange={transactionChange}
        />
        <SaleFormModal
          isModalVisible={isModalVisibleTransactionSale}
          setIsModalVisible={(value) => {
            setTransactionChange(undefined)
            setIsModalVisibleTransactionSale(value)
          }}
          transactionChange={transactionChange}
        />
        <DeleteFormModal
          isModalVisible={isModalVisibleTransactionDelete}
          setIsModalVisible={(value) => {
            setTransactionChange(undefined)
            setIsModalVisibleTransactionDelete(value)
          }}
          transactionChange={transactionChange}
        />
        <ArchiveFormModal
          isModalVisible={isModalVisibleTransactionArchive}
          setIsModalVisible={(value) => {
            setTransactionChange(undefined)
            setIsModalVisibleTransactionArchive(value)
          }}
          transactionChange={transactionChange}
        />
      </>
    </main>
  )
}
