import * as financialAssetTypesActions from "Base/store/trade/financialAssetTypes/actions"
import { takeLatest, call, put } from "redux-saga/effects"

import {
  TPostFinancialAssetTypesReqAction,
  TPutFinancialAssetTypesReqAction,
  TDeleteFinancialAssetTypesReqAction,
} from "./types"
import { getErrorMessage } from "Base/utils/exaptations"
import { toast } from "react-toastify"
import { financialAssetTypesProvider } from "Base/api/rest/globalApiProviders"
import {
  TGetFinancialAssetTypesResponse,
  TPostFinancialAssetTypesResponse,
  TPutFinancialAssetTypesResponse,
} from "Base/api/rest/types/trade/financialAssetTypes"
import { TDeleteFinancialAssetsResponse } from "Base/api/rest/types/trade/financialAssets"

export function* getFinancialAssetTypesSaga() {
  try {
    const resp: TGetFinancialAssetTypesResponse = yield call([
      financialAssetTypesProvider,
      financialAssetTypesProvider.getFinancialAssetTypes,
    ])

    if (!resp.data) throw new Error("Unknown error")
    yield put(financialAssetTypesActions.getFinancialAssetTypesAction.success(resp.data))
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      financialAssetTypesActions.getFinancialAssetTypesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* postFinancialAssetTypesSaga({ payload }: TPostFinancialAssetTypesReqAction) {
  try {
    const resp: TPostFinancialAssetTypesResponse = yield call(
      [financialAssetTypesProvider, financialAssetTypesProvider.postFinancialAssetTypes],
      {
        data: payload,
      },
    )
    if (!resp.data) throw new Error("Unknown error")
    yield put(financialAssetTypesActions.postFinancialAssetTypesAction.success(resp.data))
    toast.success("Успешно")
    yield put(financialAssetTypesActions.getFinancialAssetTypesAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      financialAssetTypesActions.postFinancialAssetTypesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* putFinancialAssetTypesSaga({ payload }: TPutFinancialAssetTypesReqAction) {
  try {
    const resp: TPutFinancialAssetTypesResponse = yield call(
      [financialAssetTypesProvider, financialAssetTypesProvider.putFinancialAssetTypes],
      {
        data: payload,
      },
    )
    if (!resp.data) throw new Error("Unknown error")
    yield put(financialAssetTypesActions.putFinancialAssetTypesAction.success(resp.data))
    toast.success("Успешно")
    yield put(financialAssetTypesActions.getFinancialAssetTypesAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      financialAssetTypesActions.putFinancialAssetTypesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* deleteFinancialAssetTypesSaga({ payload }: TDeleteFinancialAssetTypesReqAction) {
  try {
    const resp: TDeleteFinancialAssetsResponse = yield call(
      [financialAssetTypesProvider, financialAssetTypesProvider.deleteFinancialAssetTypes],
      {
        params: payload,
      },
    )
    if (!resp.data) throw new Error("Unknown error")
    yield put(financialAssetTypesActions.deleteFinancialAssetTypesAction.success(resp.data))
    toast.success("Успешно")
    yield put(financialAssetTypesActions.getFinancialAssetTypesAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      financialAssetTypesActions.deleteFinancialAssetTypesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* financialAssetTypesWatcher() {
  yield takeLatest(
    financialAssetTypesActions.getFinancialAssetTypesAction.call.type,
    getFinancialAssetTypesSaga,
  )
  yield takeLatest(
    financialAssetTypesActions.postFinancialAssetTypesAction.call.type,
    postFinancialAssetTypesSaga,
  )
  yield takeLatest(
    financialAssetTypesActions.putFinancialAssetTypesAction.call.type,
    putFinancialAssetTypesSaga,
  )
  yield takeLatest(
    financialAssetTypesActions.deleteFinancialAssetTypesAction.call.type,
    deleteFinancialAssetTypesSaga,
  )
}
