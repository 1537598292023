import { SVGProps } from "react"

export function EditingPenIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4.5 19.5H5.6L16.675 8.425L15.575 7.325L4.5 18.4V19.5ZM19.85 7.35L16.65 4.15L17.7 3.1C17.9833 2.81667 18.3333 2.675 18.75 2.675C19.1667 2.675 19.5167 2.81667 19.8 3.1L20.9 4.2C21.1833 4.48334 21.325 4.83334 21.325 5.25C21.325 5.66667 21.1833 6.01667 20.9 6.3L19.85 7.35ZM18.8 8.4L6.2 21H3V17.8L15.6 5.2L18.8 8.4ZM16.125 7.875L15.575 7.325L16.675 8.425L16.125 7.875Z'
        fill='currentColor'
      />
    </svg>
  )
}
