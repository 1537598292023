import { Button, InputCustom, InputDate, Modal } from "Base/tsx/components"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import styles from "./SaleFormModal.module.scss"
import React from "react"
import { useDispatch } from "react-redux"
import { TTrade } from "Base/api/rest/types/trade/trades"
import { InputWrapper } from "Base/tsx/components/inputs/InputWrapper"
import { getIconPlatformURL } from "Base/utils/url"
import * as marketOrdersActions from "Base/store/trade/marketOrders/actions"
import { format } from "date-fns"

interface IFormInput {
  baseFinancialAssetQuantity: string
  quoteFinancialAssetQuantity: string
  soldDate: string
}

export const SaleFormModal = ({
  tradeChange,
  isModalVisible,
  setIsModalVisible,
}: {
  tradeChange: TTrade
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
}) => {
  const dispatch = useDispatch()

  const defaultValues = {
    baseFinancialAssetQuantity: "",
    quoteFinancialAssetQuantity: "",
    soldDate: "",
  }

  const methods = useForm<IFormInput>({
    defaultValues,
  })
  const { handleSubmit, reset, watch } = methods

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    dispatch(
      marketOrdersActions.postMarketOrdersAction.call({
        trade: tradeChange,
        quoteFinancialAssetQuantity: Number(data.quoteFinancialAssetQuantity),
        baseFinancialAssetQuantity: Number(data.baseFinancialAssetQuantity),
        soldDate: format(new Date(data.soldDate), "yyyy-MM-dd HH:mm:ss"),
        quoteFinancialAsset: tradeChange.quoteFinancialAsset,
        baseFinancialAsset: tradeChange.baseFinancialAsset,
      }),
    )
    reset(defaultValues)
    setIsModalVisible(false)
  }

  return (
    <Modal
      title='Sale a transaction'
      visible={isModalVisible}
      onClose={() => setIsModalVisible(false)}
    >
      {/*{tradeChange && (*/}
      {/*  <div className={styles.info}>*/}
      {/*    <div className={styles.item}>*/}
      {/*      <CurrencyNameItem*/}
      {/*        cryptocurrencyName={tradeChange.cryptocurrencyName}*/}
      {/*        currencyOptionsObj={cryptocurrencyOptionsObj}*/}
      {/*      />*/}
      {/*      <span>: {tradeChange.buy.cryptocurrencyValue}</span>*/}
      {/*    </div>*/}
      {/*    <div className={styles.item}>*/}
      {/*      <CurrencyNameItem*/}
      {/*        cryptocurrencyName={tradeChange.currencyName}*/}
      {/*        currencyOptionsObj={currencyOptionsObj}*/}
      {/*      />*/}
      {/*      <span>: {tradeChange.buy.currencyValue}</span>*/}
      {/*    </div>*/}
      {/*    <div className={styles.item}>*/}
      {/*      <span>Profit: </span> <span>123</span>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.block}>
            <div className={styles.item}>
              <InputDate type={"datetime-local"} name='soldDate' label='Sold Date' required />
            </div>
            <div className={styles.item}>
              <InputWrapper
                label={
                  tradeChange.baseFinancialAsset.symbol +
                  ": Base Financial Asset Remaining Quantity"
                }
              >
                <span className={styles.text}>
                  {tradeChange.baseFinancialAssetRemainingQuantity}
                </span>
              </InputWrapper>
              <InputWrapper label='Wallet / Platform'>
                <span className={styles.text}>
                  <img
                    src={getIconPlatformURL(tradeChange.platform.id)}
                    alt=''
                    height={20}
                    width={20}
                  />
                  <span>{tradeChange.platform.domain}</span>
                </span>
              </InputWrapper>
            </div>
            <div className={styles.item}>
              <InputCustom
                label={tradeChange.baseFinancialAsset.symbol + ": Base Financial Asset Quantity"}
                name='baseFinancialAssetQuantity'
                type='number'
                required
                max={tradeChange.baseFinancialAssetRemainingQuantity}
              />
              <InputCustom
                label={tradeChange.quoteFinancialAsset.symbol + ": Quote Financial Asset"}
                name='quoteFinancialAssetQuantity'
                type='number'
                required
              />
            </div>
            <div className={styles.item}>
              <InputWrapper
                label={tradeChange.baseFinancialAsset.symbol + ": Base Financial Asset"}
              >
                <span className={styles.text}>{tradeChange.baseFinancialAssetQuantity}</span>
              </InputWrapper>
              <InputWrapper
                label={tradeChange.quoteFinancialAsset.symbol + ": Quote Financial Asset"}
              >
                <span className={styles.text}>{tradeChange.quoteFinancialAssetQuantity}</span>
              </InputWrapper>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button type='submit'>Submit</Button>
            <Button
              theme={"secondary"}
              onClick={() => {
                reset(defaultValues)
                setIsModalVisible(false)
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}
