import { Button, Modal } from "Base/tsx/components"
import styles from "./DeleteFormModal.module.scss"
import React from "react"
import { transactionsActions } from "Base/store/deprecated/transactions/actions"
import { TFullTransaction } from "Base/types/deprecated/trade/transactions"
import { useDispatch } from "react-redux"

export const DeleteFormModal = ({
  transactionChange,
  isModalVisible,
  setIsModalVisible,
}: {
  transactionChange?: TFullTransaction
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
}) => {
  const dispatch = useDispatch()

  return (
    <Modal
      title='Transaction deletion confirmation'
      visible={isModalVisible}
      onClose={() => setIsModalVisible(false)}
    >
      <h3>Are you sure you want to delete the transaction?</h3>
      <div className={styles.main}>
        <Button
          theme='secondary'
          onClick={() => {
            transactionChange &&
              dispatch(transactionsActions.deleteTransactionAction.call(transactionChange))
            setIsModalVisible(false)
          }}
        >
          Delete
        </Button>
        <Button
          onClick={() => {
            setIsModalVisible(false)
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}
