import { modulesRoutes } from "./modulesRoutes"

const getServicesRoutes = (baseURL: string): any => {
  const url = baseURL !== "" ? `${baseURL}` : ""
  return {
    root: `${url}`,
    title: "Services",
    personal: {
      root: `${url}/personal`,
      title: "Personal",
    },
  }
}

export const servicesModuleRoutes: any = getServicesRoutes(modulesRoutes.landing.root)
