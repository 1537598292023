import { TFullTransaction } from "Base/types/deprecated/trade/transactions"
import { TProfit } from "Base/store/deprecated/transactionsInfo/reducer"
import { getPercentageChange } from "Base/utils/formulas"

export const getProfitKey = (transaction: TFullTransaction) => {
  return transaction.cryptocurrencyName + transaction.currencyName
}
export const getProfitValue = (transaction: TFullTransaction) => {
  if (!transaction.sell?.currencyValue) return 0
  return transaction.sell?.currencyValue - transaction.buy.currencyValue
}

export const calculateProfitPlus = (transactionsProfit: TProfit, transaction: TFullTransaction) => {
  const profitKey = getProfitKey(transaction)
  const profitValue = getProfitValue(transaction)
  const value = transactionsProfit[profitKey]
    ? transactionsProfit[profitKey] + profitValue
    : profitValue
  return {
    ...transactionsProfit,
    [profitKey]: value,
  }
}
export const calculateProfitMinus = (
  transactionsProfit: TProfit,
  transaction: TFullTransaction,
) => {
  const profitKey = getProfitKey(transaction)
  const profitValue = getProfitValue(transaction)
  const value = transactionsProfit[profitKey]
    ? transactionsProfit[profitKey] - profitValue
    : profitValue
  return {
    ...transactionsProfit,
    [profitKey]: value,
  }
}

export const getProfit = (newRate: number, oldRate: number) => {
  return Number(getPercentageChange(oldRate, newRate).toFixed(2))
}
