import { baseCSF } from "Base/utils/store"
import {
  TGetExchangeInfoCallPayload,
  TGetExchangeInfoFailurePayload,
  TGetExchangeInfoSuccessPayload,
  TSearchingForProfitableCurrencyPairCallPayload,
} from "Base/store/deprecated/binance/types"

const prefix = "binance"

const getExchangeInfoAction = baseCSF<
  TGetExchangeInfoCallPayload,
  TGetExchangeInfoSuccessPayload,
  TGetExchangeInfoFailurePayload,
  undefined
>(prefix, "get-exchange-info")

const searchingForProfitableCurrencyPairAction = baseCSF<
  TSearchingForProfitableCurrencyPairCallPayload,
  undefined,
  undefined,
  undefined
>(prefix, "searching-for-profitable-currency-pair")

export const binanceActions = {
  getExchangeInfoAction,
  searchingForProfitableCurrencyPairAction,
}
