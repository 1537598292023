import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { translationsEN } from "Base/i18n/en"
import { translationsRU } from "Base/i18n/ru"

const resources = {
  en: {
    translation: translationsEN,
  },
  ru: {
    translation: translationsRU,
  },
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false, // React уже экранирует значения
    },
    detection: {
      // Настройки для LanguageDetector
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag", "path", "subdomain"],
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"], // Если язык 'cimode', не использовать кэш
    },
  })

export default i18n
