import Decimal from "decimal.js"

// export const getPercentDifference = (oldValue: number, newValue: number): Decimal => {
//   const oldVal = new Decimal(oldValue)
//   const newVal = new Decimal(newValue)
//
//   // Prevent division by zero and return zero if oldValue is zero
//   if (oldVal.isZero()) {
//     return new Decimal(0)
//   }
//
//   const difference = newVal.minus(oldVal)
//   const percentDifference = difference.div(oldVal).times(100)
//
//   return percentDifference // This will be a Decimal object
// }

export const getPercentageChange = (oldRate: number, newRate: number) => {
  // Проверяем, чтобы старый курс не был нулевым, чтобы избежать деления на ноль
  if (oldRate === 0) {
    throw new Error("Старый курс не может быть нулевым.")
  }
  // console.log("getPercentageChange, newRate: ", newRate)
  // console.log("getPercentageChange, oldRate: ", oldRate)
  const difference = newRate - oldRate
  // console.log("getPercentageChange, difference: ", difference)
  const percentageChange = (difference / oldRate) * 100
  // console.log("getPercentageChange, percentageChange: ", percentageChange)
  return percentageChange
}

export function getDecimalLength(number: number) {
  const str = number.toString()
  const decimals = str.split(".")[1]
  return decimals ? decimals.length : 0
}

export function add(a: number, b: number) {
  const factor = Math.pow(10, Math.max(getDecimalLength(a), getDecimalLength(b)))
  return (a * factor + b * factor) / factor
}

export function subtract(a: number, b: number) {
  const factor = Math.pow(10, Math.max(getDecimalLength(a), getDecimalLength(b)))
  return (a * factor - b * factor) / factor
}

const number1 = 1.663686869
const number2 = 1.202149297
const result = add(number1, number2)
