import React from "react"
import { Route, Navigate } from "react-router-dom"

import { mainModuleRoutes } from "Base/constants/routes"
import { MainPage } from "MainModule/tsx/pages"

export const getMainModuleRouters = () => (
  <>
    <Route path={mainModuleRoutes.root} element={<MainPage />} />
    <Route
      path={`${mainModuleRoutes.root}/*`}
      element={<Navigate to={mainModuleRoutes.root} replace={false} />}
    />
  </>
)
