// api/providers/PlatformsProvider.ts
import { ApiProviderBase } from "Base/api/rest/ApiProviderBase"
import {
  TDeletePlatformsRequest,
  TDeletePlatformsResponse,
  TDeletePlatformsResponseData,
  TGetPlatformsResponse,
  TGetPlatformsResponseData,
  TPostPlatformsRequest,
  TPostPlatformsResponse,
  TPostPlatformsResponseData,
  TPutPlatformsRequest,
  TPutPlatformsResponse,
  TPutPlatformsResponseData,
} from "Base/api/rest/types/trade/platforms"

export class PlatformsProvider extends ApiProviderBase {
  async getPlatforms(): Promise<TGetPlatformsResponse> {
    return this.request<TGetPlatformsResponseData>({
      url: "/platforms",
      method: "GET",
    })
  }

  async postPlatforms(params: TPostPlatformsRequest): Promise<TPostPlatformsResponse> {
    return this.request<TPostPlatformsResponseData>({
      url: "/platforms",
      method: "POST",
      data: params.data,
    })
  }

  async putPlatforms(params: TPutPlatformsRequest): Promise<TPutPlatformsResponse> {
    return this.request<TPutPlatformsResponseData>({
      url: "/platforms",
      method: "PUT",
      data: params.data,
    })
  }

  async deletePlatforms(params: TDeletePlatformsRequest): Promise<TDeletePlatformsResponse> {
    return this.request<TDeletePlatformsResponseData>({
      url: "/platforms/" + params.params?.id,
      method: "DELETE",
    })
  }
}
