import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as financialAssetsActions from "Base/store/trade/financialAssets/actions"
import { TRequestInfoChildType } from "Base/types/store/reducer"

import {
  TGetFinancialAssetsFailureAction,
  TGetFinancialAssetsSuccessAction,
  TPostFinancialAssetsFailureAction,
  TPostFinancialAssetsSuccessAction,
  TPutFinancialAssetsFailureAction,
  TPutFinancialAssetsSuccessAction,
  TDeleteFinancialAssetsSuccessAction,
  TDeleteFinancialAssetsFailureAction,
} from "./types"
import {
  TDeleteFinancialAssetsResponseData,
  TGetFinancialAssetsResponseData,
  TPostFinancialAssetsResponseData,
  TPutFinancialAssetsResponseData,
} from "Base/api/rest/types/trade/financialAssets"

export type FinancialAssetsStateType = {
  getFinancialAssets: {
    data?: TGetFinancialAssetsResponseData
  } & TRequestInfoChildType
  postFinancialAssets: {
    data?: TPostFinancialAssetsResponseData
  } & TRequestInfoChildType
  putFinancialAssets: {
    data?: TPutFinancialAssetsResponseData
  } & TRequestInfoChildType
  deleteFinancialAssets: {
    data?: TDeleteFinancialAssetsResponseData
  } & TRequestInfoChildType
}

const initialState: FinancialAssetsStateType = {
  getFinancialAssets: {
    ...RequestInfoChildState,
  },
  postFinancialAssets: {
    ...RequestInfoChildState,
  },
  putFinancialAssets: {
    ...RequestInfoChildState,
  },
  deleteFinancialAssets: {
    ...RequestInfoChildState,
  },
}

export const financialAssetsReducer = createReducer(initialState, {
  [financialAssetsActions.getFinancialAssetsAction.call.type]: (state) => ({
    ...state,
    getFinancialAssets: {
      ...state.getFinancialAssets,
      isLoading: true,
    },
  }),
  [financialAssetsActions.getFinancialAssetsAction.success.type]: (
    state,
    action: TGetFinancialAssetsSuccessAction,
  ) => ({
    ...state,
    getFinancialAssets: {
      ...state.getFinancialAssets,
      data: action.payload,
      isLoading: false,
    },
  }),
  [financialAssetsActions.getFinancialAssetsAction.failure.type]: (
    state,
    action: TGetFinancialAssetsFailureAction,
  ) => ({
    ...state,
    getFinancialAssets: {
      ...state.getFinancialAssets,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
  //------------------------------------------------------------------
  [financialAssetsActions.postFinancialAssetsAction.call.type]: (state) => ({
    ...state,
    postFinancialAssets: {
      ...state.postFinancialAssets,
      isLoading: true,
    },
  }),
  [financialAssetsActions.postFinancialAssetsAction.success.type]: (
    state,
    action: TPostFinancialAssetsSuccessAction,
  ) => ({
    ...state,
    postFinancialAssets: {
      ...state.postFinancialAssets,
      data: action.payload,
      isLoading: false,
    },
  }),
  [financialAssetsActions.postFinancialAssetsAction.failure.type]: (
    state,
    action: TPostFinancialAssetsFailureAction,
  ) => ({
    ...state,
    postFinancialAssets: {
      ...state.postFinancialAssets,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
  [financialAssetsActions.putFinancialAssetsAction.call.type]: (state) => ({
    ...state,
    putFinancialAssets: {
      ...state.putFinancialAssets,
      isLoading: true,
    },
  }),
  [financialAssetsActions.putFinancialAssetsAction.success.type]: (
    state,
    action: TPutFinancialAssetsSuccessAction,
  ) => ({
    ...state,
    putFinancialAssets: {
      ...state.putFinancialAssets,
      data: action.payload,
      isLoading: false,
    },
  }),
  [financialAssetsActions.putFinancialAssetsAction.failure.type]: (
    state,
    action: TPutFinancialAssetsFailureAction,
  ) => ({
    ...state,
    putFinancialAssets: {
      ...state.putFinancialAssets,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
  [financialAssetsActions.deleteFinancialAssetsAction.call.type]: (state) => ({
    ...state,
    deleteFinancialAssets: {
      ...state.deleteFinancialAssets,
      isLoading: true,
    },
  }),
  [financialAssetsActions.deleteFinancialAssetsAction.success.type]: (
    state,
    action: TDeleteFinancialAssetsSuccessAction,
  ) => ({
    ...state,
    deleteFinancialAssets: {
      ...state.deleteFinancialAssets,
      data: action.payload,
      isLoading: false,
    },
  }),
  [financialAssetsActions.deleteFinancialAssetsAction.failure.type]: (
    state,
    action: TDeleteFinancialAssetsFailureAction,
  ) => ({
    ...state,
    deleteFinancialAssets: {
      ...state.deleteFinancialAssets,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
})
