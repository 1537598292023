import React, { useEffect } from "react"
import styles from "./Management.module.scss"
import { ExchangeRateTableWrapper, MainContent, TransactionsInfo } from "Trader/tsx/containers"
import { tonapiActions } from "Base/store/deprecated/tonapi/actions"
import { useDispatch } from "react-redux"
import { BaseLayout } from "Base/tsx/layouts"
// import { Sidebar } from "Base/tsx/containers"
import { DefaultLayout } from "Base/tsx/layouts/DefaultLayout"
// import { binanceActions } from "Base/store/binance/actions"
// import { binanceWsActions } from "Base/store/binanceWs/actions"

export function Management() {
  const dispatch = useDispatch()
  // const icons = useAsyncIcon({ iconNames: ["logo.svg"] })
  useEffect(() => {
    // dispatch(binanceActions.getExchangeInfoAction.call())
    // dispatch(binanceWsActions.wsExchangeRateAction.call())
    dispatch(tonapiActions.getRatesAction.call())
    const interval = setInterval(() => {
      dispatch(tonapiActions.getRatesAction.call())
    }, 10000)
    return () => clearInterval(interval)
  }, [])
  return (
    <DefaultLayout>
      <ExchangeRateTableWrapper />
      <TransactionsInfo />
      <MainContent />
    </DefaultLayout>
  )
}
