import { useDispatch, useSelector } from "react-redux"
import { RootState } from "Starter/store/configureStore"
import styles from "./SettingsProfile.module.scss"
import { Button, InputCustom } from "Base/tsx/components"
import { FormProvider, useForm } from "react-hook-form"
import { TOption } from "Base/tsx/components/inputs/SelectCustom"
import { InputWrapper } from "Base/tsx/components/inputs/InputWrapper"
import { useEffect, useMemo } from "react"

type TFormData = {
  firstName: string
  lastName: string
  email: string
  password: string
  role: TOption<string>
  jobTitle: TOption<string>
  department: TOption<string>
}

export const SettingsProfile = () => {
  // const { getProfile } = useSelector((state: RootState) => state.base.profile)
  // const profile = getProfile.data?.result
  // const dispatch = useDispatch()
  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  }
  const methods = useForm<TFormData>({ defaultValues })
  const { handleSubmit, reset, watch } = methods

  // useEffect(() => {
  //   if (!profile) return
  //   reset({
  //     firstName: profile.firstName,
  //     lastName: profile.lastName,
  //     email: profile.email,
  //     password: profile.password,
  //   })
  // }, [reset, profile])

  const onSubmit = (user: TFormData) => {
    // if (!profile) return
    // const userNew = {
    //   firstName: user.firstName,
    //   lastName: user.lastName,
    //   email: user.email,
    //   password: user.password,
    // }
    // dispatch(
    //   usersActions.putUsersAction.call({
    //     ...profile,
    //     ...userNew,
    //   }),
    // )
  }
  const firstNameValue = watch("firstName")
  const lastNameValue = watch("lastName")
  const emailValue = watch("email")
  const passwordValue = watch("password")

  // const isDisable = useMemo(() => {
  //   return (
  //     profile?.firstName === firstNameValue &&
  //     profile?.lastName === lastNameValue &&
  //     profile?.email === emailValue &&
  //     profile?.password === passwordValue
  //   )
  // }, [profile, firstNameValue, lastNameValue, emailValue, passwordValue])

  return (
    <div className={styles.setting_profile}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form_child}>
            {/*<InputCustom name='firstName' label='Имя' placeholder='Иван' required />*/}
            {/*<InputCustom name='lastName' label='Фамилия' placeholder='Иванов' required />*/}
            {/*<InputCustom name='email' label='Емаил' placeholder='example@mail.ru' required />*/}
            {/*<InputCustom*/}
            {/*  name='password'*/}
            {/*  label='Пароль'*/}
            {/*  placeholder='*****'*/}
            {/*  required*/}
            {/*  type='password'*/}
            {/*/>*/}
            {/*{profile && (*/}
            {/*  <>*/}
            {/*    <InputWrapper label='Ролвфцвуь' name='role'>*/}
            {/*      <span className={styles.text}>{roles[profile.role]}</span>*/}
            {/*    </InputWrapper>*/}
            {/*    <InputWrapper label='Должность' name='jobTitle'>*/}
            {/*      <span className={styles.text}>{jobTitles[profile.jobTitle]}</span>*/}
            {/*    </InputWrapper>*/}
            {/*    <InputWrapper label='Отдел' name='department'>*/}
            {/*      <span className={styles.text}>{departments[profile.department]}</span>*/}
            {/*    </InputWrapper>*/}
            {/*  </>*/}
            {/*)}*/}
          </div>
          {/*<Button disabled={isDisable} type='submit'>*/}
          {/*  Сохранить*/}
          {/*</Button>*/}
        </form>
      </FormProvider>
    </div>
  )
}
