// api/providers/MarketOrdersProvider.ts
import { ApiProviderBase } from "Base/api/rest/ApiProviderBase"
import {
  TDeleteMarketOrdersRequest,
  TDeleteMarketOrdersResponse,
  TDeleteMarketOrdersResponseData,
  TGetMarketOrdersResponse,
  TGetMarketOrdersResponseData,
  TPostMarketOrdersRequest,
  TPostMarketOrdersResponse,
  TPostMarketOrdersResponseData,
  TPutMarketOrdersRequest,
  TPutMarketOrdersResponse,
  TPutMarketOrdersResponseData,
  TGetMarketOrdersUserResponse,
  TGetMarketOrdersUserResponseData,
} from "Base/api/rest/types/trade/marketOrders"

export class MarketOrdersProvider extends ApiProviderBase {
  async getMarketOrders(): Promise<TGetMarketOrdersResponse> {
    return this.request<TGetMarketOrdersResponseData>({
      url: "/market-orders",
      method: "GET",
    })
  }

  async getMarketOrdersUser(): Promise<TGetMarketOrdersUserResponse> {
    return this.request<TGetMarketOrdersUserResponseData>({
      url: "/market-orders/user",
      method: "GET",
    })
  }

  async postMarketOrders(params: TPostMarketOrdersRequest): Promise<TPostMarketOrdersResponse> {
    return this.request<TPostMarketOrdersResponseData>({
      url: "/market-orders",
      method: "POST",
      data: params.data,
    })
  }

  async putMarketOrders(params: TPutMarketOrdersRequest): Promise<TPutMarketOrdersResponse> {
    return this.request<TPutMarketOrdersResponseData>({
      url: "/market-orders/" + params.data?.id,
      method: "PUT",
      data: params.data,
    })
  }

  async deleteMarketOrders(
    params: TDeleteMarketOrdersRequest,
  ): Promise<TDeleteMarketOrdersResponse> {
    return this.request<TDeleteMarketOrdersResponseData>({
      url: "/market-orders/" + params.params?.id,
      method: "DELETE",
    })
  }
}
