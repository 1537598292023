// api/providers/RolesProvider.ts
import { ApiProviderBase } from "Base/api/rest/ApiProviderBase"
import {
  TDeleteRolesRequest,
  TDeleteRolesResponse,
  TDeleteRolesResponseData,
  TGetRolesResponse,
  TGetRolesResponseData,
  TPostRolesRequest,
  TPostRolesResponse,
  TPostRolesResponseData,
  TPutRolesRequest,
  TPutRolesResponse,
  TPutRolesResponseData,
} from "Base/api/rest/types/user/roles"

export class RolesProvider extends ApiProviderBase {
  async getRoles(): Promise<TGetRolesResponse> {
    return this.request<TGetRolesResponseData>({
      url: "/user-roles",
      method: "GET",
    })
  }

  async postRoles(params: TPostRolesRequest): Promise<TPostRolesResponse> {
    return this.request<TPostRolesResponseData>({
      url: "/user-roles",
      method: "POST",
      data: params.data,
    })
  }

  async putRoles(params: TPutRolesRequest): Promise<TPutRolesResponse> {
    return this.request<TPutRolesResponseData>({
      url: "/user-roles/" + params.data?.id,
      method: "PUT",
      data: params.data,
    })
  }

  async deleteRoles(params: TDeleteRolesRequest): Promise<TDeleteRolesResponse> {
    return this.request<TDeleteRolesResponseData>({
      url: "/user-roles/" + params.params?.id,
      method: "DELETE",
    })
  }
}
