import { SVGProps } from "react"

export function ServicesListIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' {...props}>
      {/* Ободок блокнота */}
      <rect
        x='4'
        y='3'
        width='16'
        height='18'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />

      {/* Линии на страницах блокнота */}
      <line
        x1='6'
        y1='7'
        x2='18'
        y2='7'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line
        x1='6'
        y1='11'
        x2='18'
        y2='11'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line
        x1='6'
        y1='15'
        x2='18'
        y2='15'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />

      {/* Спираль блокнота */}
      <circle cx='5' cy='5' r='0.7' fill='currentColor' />
      <circle cx='5' cy='9' r='0.7' fill='currentColor' />
      <circle cx='5' cy='13' r='0.7' fill='currentColor' />
      <circle cx='5' cy='17' r='0.7' fill='currentColor' />
    </svg>
  )
}
