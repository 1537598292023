import { cryptoIcons } from "Base/assets/icons/cryptocurrency/incex"
import { TOption } from "Base/tsx/components/inputs/SelectCustom"

export const cryptocurrencyOptions: Array<TOption<string>> = [
  {
    label: "Bitcoin",
    value: "BTC",
    icon: cryptoIcons.BTC,
  },
  {
    label: "Ethereum",
    value: "ETH",
    icon: cryptoIcons.ETH,
  },
  {
    label: "Toncoin",
    value: "TON",
    icon: cryptoIcons.TON,
  },
  {
    label: "Tether USDt",
    value: "USDT",
    icon: cryptoIcons.USDT,
  },
  {
    label: "Ripple",
    value: "XRP",
    icon: cryptoIcons.XRP,
  },
  {
    label: "Litecoin",
    value: "LTC",
    icon: cryptoIcons.LTC,
  },
]

export const cryptocurrencyOptionsObj: {
  [key: string]: TOption<string>
} = cryptocurrencyOptions.reduce(
  (
    cryptocurrencyOptionObj: { [key: string]: TOption<string> },
    cryptocurrencyOption: TOption<string>,
  ) => ({
    ...cryptocurrencyOptionObj,
    [cryptocurrencyOption.value]: cryptocurrencyOption,
  }),
  {},
)
