import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as profileActions from "Base/store/user/profilePersist/actions"
import { TRequestInfoChildType } from "Base/types/store/reducer"
import { TGetProfileFailureAction } from "Base/store/user/profilePersist/types"
import { TProfile } from "Base/api/rest/types/user/profile"

export type TProfileState = {
  profile?: TProfile
  getProfile: TRequestInfoChildType
}

const initialState: TProfileState = {
  getProfile: RequestInfoChildState,
}

export const profilePersistReducer = createReducer(initialState, {
  //------------------------------------------------------------------

  //------------------------------------------------------------------

  [profileActions.getProfileAction.call.type]: (state) => ({
    ...state,
    getProfile: {
      ...state.getProfile,
      isLoading: true,
    },
  }),
  [profileActions.getProfileAction.success.type]: (state, action) => ({
    ...state,
    getProfile: {
      ...state.getProfile,
      isLoading: false,
    },
    profile: action.payload,
  }),
  [profileActions.getProfileAction.failure.type]: (state, action: TGetProfileFailureAction) => ({
    ...state,
    getProfile: {
      ...state.getProfile,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [profileActions.getProfileAction.initial.type]: (state) => ({
    ...state,
    profile: undefined,
    getProfile: {
      data: undefined,
      error: undefined,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
})
