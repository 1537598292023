import { DropdownButton } from "Base/tsx/components"
import { Column, Row } from "react-table"
import styles from "./MainContent.module.scss"
import React, { useEffect, useMemo, useState } from "react"
import { RootState } from "Starter/store/configureStore"
import { useDispatch, useSelector } from "react-redux"
import {
  ArchiveFormModal,
  TradeFormModal,
  DeleteFormModal,
  SaleFormModal,
} from "TraderV2/tsx/modals"
import { StringParam, useQueryParam } from "use-query-params"
import * as tradesActions from "Base/store/trade/trades/actions"
import { getIconFinancialAssetsURL, getIconPlatformURL } from "Base/utils/url"
import { ChangeInPurchaseRateItemV2 } from "Base/tsx/components/ChangeInPurchaseRateItem"
import { format } from "date-fns"
import { getProfit } from "Base/utils/profit"
import Decimal from "decimal.js"
import { TTrade, TTradeStatus } from "Base/api/rest/types/trade/trades"
import { TableWindow } from "Base/tsx/components/other/TableWindow"
import { EditingPenIcon } from "Base/tsx/components/icons/EditingPenIcon"

type TData = TTrade

export const MainContent = () => {
  const dispatch = useDispatch()
  const {
    trades: { getTradesUser },
    financialAssetRates: { getFinancialAssetRates },
    platforms: { getPlatforms },
  } = useSelector((state: RootState) => state.base)
  const [isModalVisibleTransactionEdit, setIsModalVisibleTransactionEdit] = useState(false)
  const [isModalVisibleTransactionArchive, setIsModalVisibleTransactionArchive] = useState(false)
  const [isModalVisibleTransactionDelete, setIsModalVisibleTransactionDelete] = useState(false)
  const [isModalVisibleTransactionSale, setIsModalVisibleTransactionSale] = useState(false)
  const [tradeChange, setTradeChange] = useState<TTrade | undefined>()

  const [tradeStatus = TTradeStatus.PURCHASED] = useQueryParam("tradeStatus", StringParam)
  const [tradePrice] = useQueryParam("tradePrice", StringParam)
  const [tradePlatform] = useQueryParam("tradePlatform", StringParam)

  useEffect(() => {
    dispatch(tradesActions.getTradesUserAction.call({ status: tradeStatus }))
  }, [tradeStatus])

  const getActionOptions = (trade: TTrade) => {
    const editOption = {
      label: "edit",
      onClick: () => {
        setTradeChange(trade)
        setIsModalVisibleTransactionEdit(true)
      },
    }
    const archiveOption = {
      label: "archive",
      onClick: () => {
        setTradeChange(trade)
        setIsModalVisibleTransactionArchive(true)
      },
    }
    const saleOption = {
      label: "sale",
      onClick: () => {
        setTradeChange(trade)
        setIsModalVisibleTransactionSale(true)
      },
    }

    const deleteOption = {
      label: "delete",
      onClick: () => {
        setTradeChange(trade)
        setIsModalVisibleTransactionDelete(true)
      },
    }

    if (trade.status === TTradeStatus.PURCHASED) {
      return [editOption, archiveOption, saleOption]
    }
    if (trade.status === TTradeStatus.SOLD) {
      return [archiveOption]
    }
    if (trade.status === TTradeStatus.ARCHIVED) {
      return [deleteOption]
    }

    return [archiveOption]
  }

  // @ts-ignore
  const columns: Column<TData>[] = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        width: 38,
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   Cell: ({ row }) => {
      //     return <span>{row.original.status}</span>
      //   },
      // },
      {
        Header: "Platform",
        accessor: "platform",
        width: 200,
        Cell: ({ row }) => {
          const { domain, id } = row.original.platform
          return (
            <div className={styles.item_icon}>
              <img src={getIconPlatformURL(id)} alt='' height={20} width={20} />
              <span>{domain}</span>
            </div>
          )
        },
      },
      {
        Header: "Rate",
        accessor: "rate",
        width: 200,
        sortType: (a: any, b: any) => {
          // Получение текущего курса для обеих сделок
          const financialAssetRateA = getFinancialAssetRates?.data?.find(
            (rate) =>
              rate.baseFinancialAsset.symbol === a.original.baseFinancialAsset.symbol &&
              rate.quoteFinancialAsset.symbol === a.original.quoteFinancialAsset.symbol,
          )
          const financialAssetRateB = getFinancialAssetRates?.data?.find(
            (rate) =>
              rate.baseFinancialAsset.symbol === b.original.baseFinancialAsset.symbol &&
              rate.quoteFinancialAsset.symbol === b.original.quoteFinancialAsset.symbol,
          )

          // Если какой-либо курс не найден, устанавливаем профит в ноль
          const profitA = financialAssetRateA
            ? getProfit(financialAssetRateA.rate, a.original.rate)
            : 0
          const profitB = financialAssetRateB
            ? getProfit(financialAssetRateB.rate, b.original.rate)
            : 0

          // Сравнение профитов с использованием Decimal для точности
          const comparisonResult = new Decimal(profitA).minus(new Decimal(profitB))

          // Возврат результата сравнения
          return comparisonResult.toNumber() // Для сортировки по убыванию, если нужна сортировка по возрастанию, поменяйте местами profitA и profitB
        },

        Cell: ({ row }) => {
          const financialAssetRate = getFinancialAssetRates?.data?.find(
            (financialAssetRate: any) =>
              financialAssetRate.baseFinancialAsset.symbol ===
                row.original.baseFinancialAsset.symbol &&
              financialAssetRate.quoteFinancialAsset.symbol ===
                row.original.quoteFinancialAsset.symbol,
          )
          return (
            <>
              {row.original.rate}
              {financialAssetRate && (
                <ChangeInPurchaseRateItemV2
                  financialAssetRate={financialAssetRate}
                  trade={row.original}
                />
              )}
            </>
          )
        },
      },
      {
        Header: "Base Financial Asset Remaining Quantity",
        accessor: "baseFinancialAssetRemainingQuantity",
        width: 200,
        Cell: ({ row }) => {
          return <span>{row.original.baseFinancialAssetRemainingQuantity}</span>
        },
      },
      {
        Header: "Base Financial Asset",
        accessor: "baseFinancialAsset",
        width: 200,
        Cell: ({ row }) => {
          const { name, symbol, id } = row.original.baseFinancialAsset
          return (
            <div className={styles.item_icon}>
              <img src={getIconFinancialAssetsURL(id)} alt='' height={20} width={20} />
              <span>
                {symbol} ({name})
              </span>
            </div>
          )
        },
      },
      {
        Header: "Base Financial Asset Quantity",
        accessor: "baseFinancialAssetQuantity",
        width: 200,
        Cell: ({ row }) => {
          return <span>{row.original.baseFinancialAssetQuantity}</span>
        },
      },
      {
        Header: "Quote Financial Asset",
        accessor: "quoteFinancialAsset",
        width: 200,
        Cell: ({ row }) => {
          const { name, symbol, id } = row.original.quoteFinancialAsset
          return (
            <div className={styles.item_icon}>
              <img src={getIconFinancialAssetsURL(id)} alt='' height={20} width={20} />
              <span>
                {symbol} ({name})
              </span>
            </div>
          )
        },
      },
      {
        Header: "Quote Financial Asset Quantity",
        accessor: "quoteFinancialAssetQuantity",
        width: 200,
        Cell: ({ row }) => {
          return <span>{row.original.quoteFinancialAssetQuantity}</span>
        },
      },
      {
        Header: "Purchase date",
        accessor: "purchaseDate",
        width: 200,
        Cell: ({ row }) => {
          const { purchaseDate } = row.original
          return <span> {format(new Date(purchaseDate), "yyyy-MM-dd HH:mm:ss")}</span>
        },
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        width: 200,
        Cell: ({ row }) => {
          const { createdAt } = row.original
          return <span> {format(new Date(createdAt), "yyyy-MM-dd HH:mm:ss")}</span>
        },
      },
      {
        Header: "Updated At",
        accessor: "updatedAt",
        width: 200,
        Cell: ({ row }) => {
          const { updatedAt } = row.original
          return <span> {format(new Date(updatedAt), "yyyy-MM-dd HH:mm:ss")}</span>
        },
      },
      {
        id: "actions",
        Header: "Actions",
        sticky: "right",
        width: 76,
        Cell: ({ row }: { row: Row<TData> }) => {
          return (
            <div className={styles.actions}>
              <DropdownButton options={getActionOptions(row.original)}>
                <EditingPenIcon />
              </DropdownButton>
            </div>
          )
        },
      },
    ],
    [getFinancialAssetRates.data, getTradesUser.data],
  )

  const tableData: TData[] = useMemo(() => {
    if (!getTradesUser?.data) return []
    return getTradesUser.data.filter((trade) => {
      const financialAssetRate = getFinancialAssetRates?.data?.find(
        // @ts-ignore
        (rate) =>
          rate.baseFinancialAsset.symbol === trade.baseFinancialAsset.symbol &&
          rate.quoteFinancialAsset.symbol === trade.quoteFinancialAsset.symbol,
      )
      if (tradePlatform !== "ALL" && tradePlatform !== trade.platform.id.toString()) return false
      return tradePrice !== "ALL" && financialAssetRate
        ? (trade.status === TTradeStatus.PURCHASED
            ? trade.baseFinancialAssetRemainingQuantity
            : trade.baseFinancialAssetQuantity) *
            financialAssetRate.rate >
            Number(tradePrice)
        : true
    })
  }, [getTradesUser.data, tradePrice, tradePlatform, getFinancialAssetRates.data])

  return (
    <>
      <div className={styles.content}>
        <TableWindow<TData>
          fixedSizeListClassName={styles.fixed_size_list}
          columns={columns}
          data={tableData}
          update={() => undefined}
          hasNextPage={false}
          totalItems={1000}
        />
      </div>
      {/*<>*/}

      {/*  <Table<TData>*/}
      {/*    data={tableData}*/}
      {/*    columns={columns}*/}
      {/*    defaultSorting={[*/}
      {/*      {*/}
      {/*        id: "rate", // Идентификатор колонки, по которой будет сортировка*/}
      {/*        desc: true, // Направление сортировки*/}
      {/*      },*/}
      {/*    ]}*/}
      {/*  />*/}
      {/*</>*/}
      <>
        <TradeFormModal
          isModalVisible={isModalVisibleTransactionEdit}
          setIsModalVisible={(value) => {
            setIsModalVisibleTransactionEdit(value)
          }}
          tradeChange={tradeChange}
        />
        {tradeChange && (
          <SaleFormModal
            isModalVisible={isModalVisibleTransactionSale}
            setIsModalVisible={(value) => {
              setTradeChange(undefined)
              setIsModalVisibleTransactionSale(value)
            }}
            tradeChange={tradeChange}
          />
        )}
        <DeleteFormModal
          isModalVisible={isModalVisibleTransactionDelete}
          setIsModalVisible={(value) => {
            setTradeChange(undefined)
            setIsModalVisibleTransactionDelete(value)
          }}
          tradeChange={tradeChange}
        />
        <ArchiveFormModal
          isModalVisible={isModalVisibleTransactionArchive}
          setIsModalVisible={(value) => {
            setTradeChange(undefined)
            setIsModalVisibleTransactionArchive(value)
          }}
          tradeChange={tradeChange}
        />
      </>
    </>
  )
}
