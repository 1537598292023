import React from "react"
import { Route, Navigate } from "react-router-dom"

import { traderModuleRoutes } from "Base/constants/routes"
import { Management } from "Trader/tsx/pages"

export const getTraderRouters = () => (
  <>
    <Route path={traderModuleRoutes.management.root} element={<Management />} />
    <Route
      path={`${traderModuleRoutes.root}/*`}
      element={<Navigate to={traderModuleRoutes.management.root} replace={false} />}
    />
  </>
)
