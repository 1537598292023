import { SagaIterator } from "redux-saga"
import { select, takeLatest } from "redux-saga/effects"
import { exchangeRateActions } from "Base/store/deprecated/exchangeRate/actions"
import { RootState } from "Starter/store/configureStore"
import {
  TExchangeRateBinance,
  TExchangeRateBinanceElement,
} from "Base/store/deprecated/exchangeRate/types"

// type TCounts = {
//   [key: string]: {
//     [key: string]: {
//       type: "sell" | "buy"
//       value: number
//     }
//   }
// }
//
// function* calculate(counts: TCounts, amount: number, quoteCurrency: string) {
//   const binance: TExchangeRateBinance = yield select(
//     ({ base }: RootState) => base.exchangeRate.providers.binance,
//   )
//   Object.keys(binance).forEach((key) => {
//     const provider = binance[key]
//     if (provider.quoteCurrency === quoteCurrency) {
//       counts = {
//         ...counts,
//         [provider.baseCurrency]: {
//           ...counts[key],
//           [key]: {
//             type: "buy",
//             value: amount / provider.rate,
//           },
//         },
//       }
//     }
//   })
//
//   return counts
// }
//
// export function* setExchangeRateSaga(): SagaIterator {
//   const binance: TExchangeRateBinance = yield select(
//     ({ base }: RootState) => base.exchangeRate.providers.binance,
//   )
//   console.log("setExchangeRateSaga, binance: ", binance)
//   const amount = 100
//   let counts: TCounts = {}
//
//   Object.keys(binance).forEach((key) => {
//     const provider = binance[key]
//     if (provider.quoteCurrency === "USDT") {
//       counts = {
//         ...counts,
//         [provider.baseCurrency]: {
//           ...counts[provider.baseCurrency],
//           [key]: {
//             type: "buy",
//             value: amount / provider.rate,
//           },
//         },
//       }
//     }
//   })
//
//   // Object.keys(binance).forEach((key) => {
//   //   const provider = binance[key]
//   //   if (counts[provider.quoteCurrency]) {
//   //     counts = {
//   //       ...counts,
//   //       [provider.baseCurrency]: {
//   //         ...counts[provider.baseCurrency],
//   //         [key]: amount / provider.rate,
//   //       },
//   //     }
//   //   }
//   // })
//
//   // const res = yield call(getExchangeInfoSymbolsSaga)
//   console.log("setExchangeRateSaga, counts: ", counts)
// }

// interface ICurrencyPair {
//   name: string
//   baseCurrency: string
//   quoteCurrency: string
//   value: number
// }

interface IAction {
  pair: TExchangeRateBinanceElement
  fromValue: number
  toValue: number
}

interface INode {
  currency: string
  value: number
  action?: IAction // Действие, которое привело к данному узлу
  children: INode[]
}

// type Data = { [key: string]: ICurrencyPair }
//
// const Data: Data = {
//   BTCUSDT: {
//     name: "BTCUSDT",
//     baseCurrency: "BTC",
//     quoteCurrency: "USDT",
//     value: 26826.32,
//   },
//   ETHUSDT: {
//     name: "ETHUSDT",
//     baseCurrency: "ETH",
//     quoteCurrency: "USDT",
//     value: 1637.25,
//   },
//   ETHBTC: {
//     name: "ETHBTC",
//     baseCurrency: "ETH",
//     quoteCurrency: "BTC",
//     value: 0.061,
//   },
//   // ... other pairs
// }

interface IAction {
  pair: TExchangeRateBinanceElement
  fromValue: number
  toValue: number
}

interface INode {
  currency: string
  value: number
  valueInUSDT: number
  action?: IAction
  children: INode[]
}

// eslint-disable-next-line require-yield
export function* setExchangeRateSaga(): SagaIterator {
  const binance: TExchangeRateBinance = yield select(
    ({ base }: RootState) => base.exchangeRate.providers.binance,
  )

  function findChains(
    currentCurrency: string,
    currentValue: number,
    valueInUSDT: number,
    visited: Set<string> = new Set(),
    depth: number = 0,
  ): INode {
    const node: INode = {
      currency: currentCurrency,
      value: currentValue,
      valueInUSDT, // <-- устанавливаем значение в USDT
      children: [],
    }

    if (depth > 30) {
      return node
    }

    for (const pairName in binance) {
      const pair = binance[pairName]
      if (pair.baseCurrency === currentCurrency && !visited.has(pair.name)) {
        visited.add(pair.name)
        const nextValue = currentValue * pair.rate
        const nextCurrency = pair.quoteCurrency
        const action: IAction = {
          pair,
          fromValue: currentValue,
          toValue: nextValue,
        }
        let nextValueInUSDT = valueInUSDT
        if (nextCurrency === "USDT") {
          nextValueInUSDT = nextValue
        }
        const childNode = findChains(
          nextCurrency,
          nextValue,
          nextValueInUSDT,
          new Set(visited),
          depth + 1,
        )
        childNode.action = action
        node.children.push(childNode)
      } else if (pair.quoteCurrency === currentCurrency && !visited.has(pair.name)) {
        visited.add(pair.name)
        const nextValue = currentValue / pair.rate
        const nextCurrency = pair.baseCurrency
        const action: IAction = {
          pair,
          fromValue: currentValue,
          toValue: nextValue,
        }
        let nextValueInUSDT = valueInUSDT
        if (nextCurrency === "USDT") {
          nextValueInUSDT = nextValue
        }
        const childNode = findChains(
          nextCurrency,
          nextValue,
          nextValueInUSDT,
          new Set(visited),
          depth + 1,
        )
        childNode.action = action
        node.children.push(childNode)
      }
    }

    return node
  }

  const startCurrency = "USDT"
  const startValue = 1000000
  const startValueInUSDT = startValue
  // const tree = findChains(startCurrency, startValue, startValueInUSDT)

  // console.log("setExchangeRateSaga, tree:", tree)
}

export function* exchangeRateWatcher(): SagaIterator {
  yield takeLatest(exchangeRateActions.setExchangeRateAction.call.type, setExchangeRateSaga)
}

// const Data = {
//   BTCUSDT: {
//     name: "BTCUSDT",
//     baseCurrency: "BTC",
//     quoteCurrency: "USDT",
//     value: 26826.32,
//   },
//   ETHUSDT: {
//     name: "ETHUSDT",
//     baseCurrency: "ETH",
//     quoteCurrency: "USDT",
//     value: 1637.25,
//   },
//   ETHBTC: {
//     name: "ETHBTC",
//     baseCurrency: "ETH",
//     quoteCurrency: "BTC",
//     value: 1637.25,
//   },
//   LTCETH: {
//     name: "LTCETH",
//     baseCurrency: "LTC",
//     quoteCurrency: "ETH",
//     value: 1637.25,
//   },
//   LTCBTC: {
//     name: "LTCBTC",
//     baseCurrency: "LTC",
//     quoteCurrency: "BTC",
//     value: 1637.25,
//   },
//   LTCUSDT: {
//     name: "LTCUSDT",
//     baseCurrency: "LTC",
//     quoteCurrency: "USDT",
//     value: 1637.25,
//   },
// }
//
// const resp = {
//   BTC: {
//     BTCUSDT: {
//       type: "buy",
//       value: 123,
//       LTCBTC: {
//         type: "buy",
//         value: 123,
//         LTCUSDT: {
//           type: "sell",
//           value: 123,
//         },
//       },
//       ETHBTC: {
//         type: "buy",
//         value: 123,
//         ETHUSDT: {
//           type: "sell",
//           value: 123,
//         },
//       },
//     },
//   },
//   ETH: {
//     ETHUSDT: 0.0037177264169742466,
//   },
//   LTC: {
//     LTCUSDT: 0.0037177264169742466,
//   },
// }
