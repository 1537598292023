import { Button, InputCustom, Modal, SelectCustom } from "Base/tsx/components"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import styles from "./FinancialAssetFormModal.module.scss"
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as financialAssetsActions from "Base/store/trade/financialAssets/actions"
import { TFinancialAsset } from "Base/api/rest/types/trade/financialAssets"
import { RootState } from "Starter/store/configureStore"
import { TOption } from "Base/tsx/components/inputs/SelectCustom"
import { TFinancialAssetType } from "Base/api/rest/types/trade/financialAssetTypes"

interface IFormInput {
  name: string
  domain: string
  iconLink: string
  symbol: string
  desc: string
  financialAssetType: TOption<TFinancialAssetType>
}

export const FinancialAssetFormModal = ({
  financialAssetChange,
  isModalVisible,
  setIsModalVisible,
}: {
  financialAssetChange?: TFinancialAsset
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
}) => {
  const dispatch = useDispatch()
  const {
    financialAssetTypes: { getFinancialAssetTypes },
  } = useSelector((state: RootState) => state.base)

  const financialAssetTypeOptions = useMemo(() => {
    if (!getFinancialAssetTypes?.data) return []
    return getFinancialAssetTypes.data.map((financialAssetType) => {
      return {
        label: financialAssetType.symbol,
        value: financialAssetType,
      }
    })
  }, [getFinancialAssetTypes?.data])

  const defaultValues = {
    name: "",
    symbol: "",
    iconLink: "",
    desc: "",
    domain: "",
  }

  const methods = useForm<IFormInput>({
    defaultValues,
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    resolver: undefined,
  })

  const { handleSubmit, reset, setValue, clearErrors, setError } = methods

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (financialAssetChange) {
      dispatch(
        financialAssetsActions.putFinancialAssetsAction.call({
          ...financialAssetChange,
          name: data.name,
          symbol: data.symbol,
          iconLink: data.iconLink,
          desc: data.desc,
          domain: data.domain,
          financialAssetType: data.financialAssetType.value,
        }),
      )
    } else {
      dispatch(
        financialAssetsActions.postFinancialAssetsAction.call({
          name: data.name,
          symbol: data.symbol,
          iconLink: data.iconLink,
          desc: data.desc,
          domain: data.domain,
          financialAssetType: data.financialAssetType.value,
        }),
      )
    }
    reset(defaultValues)
    setIsModalVisible(false)
  }
  useEffect(() => {
    console.log("financialAssetChange: financialAssetChange: ", financialAssetChange)
    if (!financialAssetChange) {
      reset(defaultValues)
      return
    }
    const { financialAssetType } = financialAssetChange
    reset({
      ...financialAssetChange,
      financialAssetType: financialAssetType
        ? financialAssetTypeOptions.filter(
            (financialAssetTypeOption) =>
              financialAssetTypeOption.value.id === financialAssetType.id,
          )[0]
        : undefined,
    })
  }, [financialAssetChange, reset, financialAssetTypeOptions])

  return (
    <Modal
      title='Adding financialAsset'
      visible={isModalVisible}
      onClose={() => setIsModalVisible(false)}
    >
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.block}>
            <div className={styles.item}>
              <SelectCustom
                label='Financial asset type'
                name='financialAssetType'
                required
                options={financialAssetTypeOptions}
              />
              <InputCustom name='name' label='Name' required />
            </div>
            <div className={styles.item}>
              <InputCustom name='symbol' label='Symbol' required />
              <InputCustom name='desc' label='Desc' required />
            </div>
            <div className={styles.item}>
              <InputCustom name='domain' label='Domain' />
              <InputCustom name='iconLink' label='Icon link' />
            </div>
          </div>
          <div className={styles.buttons}>
            <Button type='submit'>Submit</Button>
            <Button
              theme='secondary'
              onClick={() => {
                reset(defaultValues)
                setIsModalVisible(false)
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}
