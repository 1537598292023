import React, { CSSProperties } from "react"
import styles from "./InputWrapper.module.scss"
import cn from "classnames"

interface InputWrapperProps {
  style?: CSSProperties
  label?: string
  name?: string
  required?: boolean
  disabled?: boolean
  errors?: Record<string, any>
  children: React.ReactNode
  className?: string
}

export const InputWrapper: React.FC<InputWrapperProps> = ({
  style,
  label,
  name,
  required,
  errors,
  children,
  className = "",
  disabled,
}) => (
  <div
    style={style}
    className={cn(styles.input_wrapper, className, { [styles.disabled]: disabled })}
  >
    {label && (
      <label>
        {label}
        {required && <span className={styles.required}>*</span>}
      </label>
    )}
    {children}
    {errors && name && errors[name] && <p className={styles.error}>{errors[name]?.message}</p>}
  </div>
)
