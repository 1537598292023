import styles from "./ProfitItem.module.scss"
import React from "react"
import cn from "classnames"
import { TFullTransaction } from "Base/types/deprecated/trade/transactions"

export const ProfitItem = ({ transaction }: { transaction: TFullTransaction }) => {
  if (!transaction.sell?.currencyValue) return <></>
  const profit = transaction.sell.currencyValue - transaction.buy.currencyValue
  return (
    <div
      className={cn(styles.profit, {
        [styles.profit__green]: profit > 0,
        [styles.profit__red]: profit < 0,
      })}
    >
      {profit.toFixed(2)}
    </div>
  )
}
