import { Button, CurrencyNameItem, InputCustom, InputDate, Modal } from "Base/tsx/components"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import styles from "./SaleFormModal.module.scss"
import React, { useEffect } from "react"
import { transactionsActions } from "Base/store/deprecated/transactions/actions"
import { TFullTransaction } from "Base/types/deprecated/trade/transactions"
import { useDispatch } from "react-redux"
import { format } from "date-fns"
import { cryptocurrencyOptionsObj } from "Base/constants/cryptocurrencyOptions"
import { currencyOptionsObj } from "Base/constants/currencyOptions"

interface IFormInput {
  currencyValue: string
  dateOfSale: string
}

export const SaleFormModal = ({
  transactionChange,
  isModalVisible,
  setIsModalVisible,
}: {
  transactionChange?: TFullTransaction
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
}) => {
  const dispatch = useDispatch()

  const defaultValues = {
    currencyValue: "",
    dateOfSale: "",
  }

  const methods = useForm<IFormInput>({
    defaultValues,
  })
  const { handleSubmit, reset } = methods

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (transactionChange) {
      dispatch(
        transactionsActions.updateTransactionAction.call({
          ...transactionChange,
          ...data,
          status: "sold",
          sell: {
            ...transactionChange.sell,
            currencyValue: parseFloat(data.currencyValue),
            dateOfSale: format(new Date(data.dateOfSale), "yyyy-MM-dd HH:mm:ss"),
          },
        }),
      )
    }
    reset({
      currencyValue: "",
      dateOfSale: "",
    })
    setIsModalVisible(false)
  }
  useEffect(() => {
    if (!transactionChange) return
    reset({
      currencyValue: transactionChange.sell?.currencyValue?.toString() || "",
      dateOfSale: transactionChange.sell?.dateOfSale || "",
    })
  }, [transactionChange])
  return (
    <Modal
      title='Sale a transaction'
      visible={isModalVisible}
      onClose={() => setIsModalVisible(false)}
    >
      {transactionChange && (
        <div className={styles.info}>
          <div className={styles.item}>
            <CurrencyNameItem
              cryptocurrencyName={transactionChange.cryptocurrencyName}
              currencyOptionsObj={cryptocurrencyOptionsObj}
            />
            <span>: {transactionChange.buy.cryptocurrencyValue}</span>
          </div>
          <div className={styles.item}>
            <CurrencyNameItem
              cryptocurrencyName={transactionChange.currencyName}
              currencyOptionsObj={currencyOptionsObj}
            />
            <span>: {transactionChange.buy.currencyValue}</span>
          </div>
          <div className={styles.item}>
            <span>Profit: </span> <span>123</span>
          </div>
        </div>
      )}
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.block}>
            <InputDate type={"datetime-local"} name='dateOfSale' label='Date of sale' required />
            <InputCustom name='currencyValue' label='Currency value' type='number' required />
          </div>
          <div className={styles.buttons}>
            <Button type='submit'>Submit</Button>
            <Button
              onClick={() => {
                reset(defaultValues)
                setIsModalVisible(false)
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}
