import { Button, Modal } from "Base/tsx/components"
import styles from "./DeleteFinancialAssetFormModal.module.scss"
import React from "react"
import { useDispatch } from "react-redux"
import * as financialAssetsActions from "Base/store/trade/financialAssets/actions"
import { TFinancialAsset } from "Base/api/rest/types/trade/financialAssets"

export const DeleteFinancialAssetFormModal = ({
  financialAssetChange,
  isModalVisible,
  setIsModalVisible,
}: {
  financialAssetChange?: TFinancialAsset
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
}) => {
  const dispatch = useDispatch()

  return (
    <Modal
      title='FinancialAssetType removal confirmation'
      visible={isModalVisible}
      onClose={() => setIsModalVisible(false)}
    >
      <h3>Are you sure you want to delete the financialAsset?</h3>
      <div className={styles.main}>
        <Button
          onClick={() => {
            financialAssetChange &&
              dispatch(
                financialAssetsActions.deleteFinancialAssetsAction.call(financialAssetChange),
              )
            setIsModalVisible(false)
          }}
        >
          Delete
        </Button>
        <Button
          theme='secondary'
          onClick={() => {
            setIsModalVisible(false)
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}
