import { compose, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import rootSaga from "Starter/store/rootSaga"
import { persistStore } from "redux-persist"
import createSagaMiddleware from "redux-saga"

import { rootReducer } from "./rootReducer"

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const sagaMiddleware = createSagaMiddleware()

const devToolsEnabled = process.env.REACT_APP_REDUX_DEVTOOLS === "enable"
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const middleware = [
  ...getDefaultMiddleware({
    thunk: true,
    serializableCheck: false,
  }),
  sagaMiddleware,
]

export const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: devToolsEnabled,
  // enhancers: [composeEnhancers()],
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

sagaMiddleware.run(rootSaga)
