import React, { useEffect, useRef } from "react"
import styles from "./PageHeader.module.scss"
import cn from "classnames"

export const PageHeader = ({ children }: { children: React.ReactNode }) => {
  const headerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleResize = () => {
      const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0
      document.documentElement.style.setProperty("--page-header-height", `${headerHeight - 1}px`)
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      document.documentElement.style.setProperty("--page-header-height", `0px`)
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div ref={headerRef} className={cn(styles.header)}>
      {children}
    </div>
  )
}
