// api/providers/FinancialAssetsProvider.ts
import { ApiProviderBase } from "Base/api/rest/ApiProviderBase"
import {
  TDeleteFinancialAssetsRequest,
  TDeleteFinancialAssetsResponse,
  TDeleteFinancialAssetsResponseData,
  TGetFinancialAssetsResponse,
  TGetFinancialAssetsResponseData,
  TPostFinancialAssetsRequest,
  TPostFinancialAssetsResponse,
  TPostFinancialAssetsResponseData,
  TPutFinancialAssetsRequest,
  TPutFinancialAssetsResponse,
  TPutFinancialAssetsResponseData,
} from "Base/api/rest/types/trade/financialAssets"

export class FinancialAssetsProvider extends ApiProviderBase {
  async getFinancialAssets(): Promise<TGetFinancialAssetsResponse> {
    return this.request<TGetFinancialAssetsResponseData>({
      url: "/financial-assets",
      method: "GET",
    })
  }

  async postFinancialAssets(
    params: TPostFinancialAssetsRequest,
  ): Promise<TPostFinancialAssetsResponse> {
    return this.request<TPostFinancialAssetsResponseData>({
      url: "/financial-assets",
      method: "POST",
      data: params.data,
    })
  }

  async putFinancialAssets(
    params: TPutFinancialAssetsRequest,
  ): Promise<TPutFinancialAssetsResponse> {
    return this.request<TPutFinancialAssetsResponseData>({
      url: "/financial-assets/" + params.data?.id,
      method: "PUT",
      data: params.data,
    })
  }

  async deleteFinancialAssets(
    params: TDeleteFinancialAssetsRequest,
  ): Promise<TDeleteFinancialAssetsResponse> {
    return this.request<TDeleteFinancialAssetsResponseData>({
      url: "/financial-assets/" + params.params?.id,
      method: "DELETE",
    })
  }
}
