import { baseCSF } from "Base/utils/store"

import {
  TGetFinancialAssetRatesFailurePayload,
  TGetFinancialAssetRatesReqPayload,
  TGetFinancialAssetRatesSuccessPayload,
} from "./types"

const prefix = "financialAssetRates"

export const getFinancialAssetRatesAction = baseCSF<
  TGetFinancialAssetRatesReqPayload,
  TGetFinancialAssetRatesSuccessPayload,
  TGetFinancialAssetRatesFailurePayload,
  undefined
>(prefix, "get")
