import { createReducer } from "@reduxjs/toolkit"

import * as authPersistActions from "Base/store/user/authPersist/actions"

import { RequestInfoChildState } from "Base/constants/statesDefault"
import { TRequestInfoChildType } from "Base/types/store/reducer"
import { GetAuthLogoutFailure } from "Base/store/user/authPersist/types"

export type AuthPersistStateType = {
  getAuthLogout: TRequestInfoChildType
}

const initialState: AuthPersistStateType = {
  getAuthLogout: RequestInfoChildState,
}

export const authPersistReducer = createReducer(initialState, {
  //------------------------------------------------------------------

  [authPersistActions.getAuthLogoutAction.call.type]: (state) => ({
    ...state,
    getAuthLogout: {
      ...state.getAuthLogout,
      isLoading: true,
    },
  }),
  [authPersistActions.getAuthLogoutAction.call.type]: () => initialState,

  [authPersistActions.getAuthLogoutAction.failure.type]: (state, action: GetAuthLogoutFailure) => ({
    ...state,
    getAuthLogout: {
      ...state.getAuthLogout,
      error: action.payload.error,
      isLoading: false,
    },
  }),

  //------------------------------------------------------------------

  //------------------------------------------------------------------
})
