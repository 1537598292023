// Сохранение
import { TExchangeInfoSymbol } from "Base/store/deprecated/binance/types"
import { call } from "redux-saga/effects"
import { dbTransaction } from "Base/store/indexedDB/indexedDBSaga"
import { SagaIterator } from "redux-saga"

export function* saveExchangeInfoSymbolsSaga(exchangeInfoSymbols: TExchangeInfoSymbol[]) {
  yield call(dbTransaction, "exchangeInfoSymbols", "symbol", "readwrite", function* (store) {
    for (const exchangeInfoSymbol of exchangeInfoSymbols) {
      yield call([store, store.put], exchangeInfoSymbol)
    }
  })
}

export function* getExchangeInfoSymbolsSaga(): SagaIterator<TExchangeInfoSymbol[] | undefined> {
  let result: TExchangeInfoSymbol[] | undefined
  yield call(dbTransaction, "exchangeInfoSymbols", "symbol", "readonly", function* (store) {
    result = yield call([store, store.getAll])
  })
  return result
}
