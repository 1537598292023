import { SVGProps } from "react"

export function ListOfObjectsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.5 5.5H1.5M13.5 5.5C14.0523 5.5 14.5 5.05228 14.5 4.5V2.5C14.5 1.94772 14.0523 1.5 13.5 1.5H1.5C0.947715 1.5 0.5 1.94772 0.5 2.5V4.5C0.5 5.05228 0.947716 5.5 1.5 5.5M13.5 5.5C14.0523 5.5 14.5 5.94772 14.5 6.5V8.5C14.5 9.05228 14.0523 9.5 13.5 9.5M1.5 5.5C0.947716 5.5 0.5 5.94772 0.5 6.5V8.5C0.5 9.05228 0.947716 9.5 1.5 9.5M13.5 9.5H1.5M13.5 9.5C14.0523 9.5 14.5 9.94772 14.5 10.5V12.5C14.5 13.0523 14.0523 13.5 13.5 13.5H1.5C0.947716 13.5 0.5 13.0523 0.5 12.5V10.5C0.5 9.94772 0.947716 9.5 1.5 9.5M2 3.5H5M2 7.5H5M2 11.5H5'
        stroke='currentColor'
      />
    </svg>
  )
}
