import * as usersActions from "Base/store/user/users/actions"
import { takeLatest, call, put } from "redux-saga/effects"

import { getErrorMessage } from "Base/utils/exaptations"
import { toast } from "react-toastify"
import { usersProvider } from "Base/api/rest/globalApiProviders"
import { TGetUsersResponse } from "Base/api/rest/types/user/users"

export function* getUsersSaga() {
  try {
    const resp: TGetUsersResponse = yield call([usersProvider, usersProvider.getUsers])
    console.log("getUsersSaga, resp: ", resp)

    if (!resp.success || !resp.data) throw new Error("Unknown error")
    yield put(usersActions.getUsersAction.success(resp.data))
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      usersActions.getUsersAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}
//
// export function* getUsersByIdSaga({ payload }: TGetUsersByIdReqAction) {
//   try {
//     const resp: {
//       date: string
//       result?: TUser
//     } = {
//       date: new Date().toString(),
//     }
//     // yield call(dbNms, "users", "id", "readonly", function* (store) {
//     //   resp.result = yield call([store, store.get], payload.id)
//     // })
//     // if (!resp.success) throw new Error("Unknown error")
//     // yield put(usersActions.getUsersByIdAction.success(resp))
//   } catch (e: unknown) {
//     const message = getErrorMessage(e)
//     toast.error(message)
//     yield put(
//       usersActions.getUsersByIdAction.failure({
//         error: message,
//       }),
//     )
//     console.log(e)
//   }
// }
//
// export function* postUsersSaga({ payload }: TPostUsersReqAction) {
//   try {
//     // console.log("postUsersSaga, payload: ", payload)
//     // yield call(dbNms, "users", "id", "readwrite", function* (store) {
//     //   for (const user of [{ ...payload, id: new Date().getTime() }]) {
//     //     yield call([store, store.put], user)
//     //   }
//     // })
//     // yield put(usersActions.postUsersAction.success())
//     // toast.success("Успешно")
//     // yield put(usersActions.getUsersAction.call())
//   } catch (e: unknown) {
//     const message = getErrorMessage(e)
//     toast.error(message)
//     yield put(
//       usersActions.postUsersAction.failure({
//         error: message,
//       }),
//     )
//     console.log(e)
//   }
// }
//
// export function* putUsersSaga({ payload }: TPutUsersReqAction) {
//   try {
//     // const resp: TPutUsersResp = yield call(apiRequest, "putUsers", {
//     //   params: payload,
//     // })
//     // if (!resp.success) throw new Error(resp.detail || "Unknown error")
//     // yield put(usersActions.putUsersAction.success(resp.data))
//     // const { getUsers } = yield select(({ base }: RootState) => base.calcAsset)
//     // yield put(
//     //   usersActions.getUsersAction.success({
//     //     ...getUsers.data,
//     //     result: getUsers.data?.result.map((asset: TUsers) => {
//     //       if (asset.id !== resp.data.result.id) return asset
//     //       return resp.data.result
//     //     }),
//     //   }),
//     // )
//   } catch (e: unknown) {
//     const message = getErrorMessage(e)
//     toast.error(message)
//     yield put(
//       usersActions.putUsersAction.failure({
//         error: message,
//       }),
//     )
//     console.log(e)
//   }
// }

export function* usersWatcher() {
  yield takeLatest(usersActions.getUsersAction.call.type, getUsersSaga)
  // yield takeLatest(usersActions.getUsersByIdAction.call.type, getUsersByIdSaga)
  // yield takeLatest(usersActions.postUsersAction.call.type, postUsersSaga)
  // yield takeLatest(usersActions.putUsersAction.call.type, putUsersSaga)
}
