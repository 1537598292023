import { Button, SelectCustom, InputCustom, InputDate, Modal } from "Base/tsx/components"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import styles from "./TradeFormModal.module.scss"
import React, { useEffect, useMemo } from "react"
import { TOption } from "Base/tsx/components/inputs/SelectCustom"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "Starter/store/configureStore"
import { TTrade } from "Base/api/rest/types/trade/trades"
import * as tradesActions from "Base/store/trade/trades/actions"
import { format } from "date-fns"
import { getIconFinancialAssetsURL } from "Base/utils/url"
import { TPlatform } from "Base/api/rest/types/trade/platforms"
import { TFinancialAsset } from "Base/api/rest/types/trade/financialAssets"

interface IFormInput {
  quoteFinancialAsset: TOption<TFinancialAsset>
  quoteFinancialAssetQuantity: number
  baseFinancialAsset: TOption<TFinancialAsset>
  baseFinancialAssetQuantity: number
  purchaseDate: string
  platform: TOption<TPlatform>
}

export const TradeFormModal = ({
  tradeChange,
  isModalVisible,
  setIsModalVisible,
}: {
  tradeChange?: TTrade
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
}) => {
  const dispatch = useDispatch()
  const {
    platforms: { getPlatforms },
    financialAssets: { getFinancialAssets },
  } = useSelector((state: RootState) => state.base)

  const defaultValues = {
    quoteFinancialAssetQuantity: "",
    baseFinancialAssetQuantity: "",
    purchaseDate: "",
  }

  const platformOptions = useMemo(() => {
    if (!getPlatforms?.data) return []
    return getPlatforms.data.map((platform) => {
      return {
        label: platform.name,
        value: platform,
        icon: process.env.REACT_APP_API + "/platforms/" + platform.id + "/icon",
      }
    })
  }, [getPlatforms?.data])

  const methods = useForm<IFormInput>({
    // @ts-ignore
    defaultValues,
  })
  const { handleSubmit, reset, setValue, watch } = methods
  const quoteFinancialAsset = watch("quoteFinancialAsset")
  const baseFinancialAsset = watch("baseFinancialAsset")

  const financialAssetOptions = useMemo(() => {
    if (!getFinancialAssets?.data) return []
    return getFinancialAssets.data.map((financialAsset) => {
      return {
        label: financialAsset.symbol + " (" + financialAsset.name + ")",
        value: financialAsset,
        icon: getIconFinancialAssetsURL(financialAsset.id),
      }
    })
  }, [getFinancialAssets?.data])

  const baseFinancialAssetOptions = useMemo(() => {
    return financialAssetOptions.filter(
      (financialAssetOption) => financialAssetOption.value.id !== baseFinancialAsset?.value.id,
    )
  }, [financialAssetOptions, baseFinancialAsset])

  const quoteFinancialAssetOptions = useMemo(() => {
    return financialAssetOptions.filter(
      (financialAssetOption) => financialAssetOption.value.id !== quoteFinancialAsset?.value.id,
    )
  }, [financialAssetOptions, quoteFinancialAsset])

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (tradeChange) {
      dispatch(
        tradesActions.putTradesAction.call({
          ...tradeChange,
          // quoteFinancialAsset: data.quoteFinancialAsset.value,
          // baseFinancialAsset: data.baseFinancialAsset.value,
          // quoteFinancialAssetQuantity: data.quoteFinancialAssetQuantity,
          // baseFinancialAssetQuantity: data.baseFinancialAssetQuantity,
          purchaseDate: new Date(data.purchaseDate),
          platform: data.platform.value,
        }),
      )
    } else {
      dispatch(
        tradesActions.postTradesAction.call({
          quoteFinancialAsset: data.quoteFinancialAsset.value,
          baseFinancialAsset: data.baseFinancialAsset.value,
          quoteFinancialAssetQuantity: data.quoteFinancialAssetQuantity,
          baseFinancialAssetQuantity: data.baseFinancialAssetQuantity,
          purchaseDate: new Date(data.purchaseDate),
          platform: data.platform.value,
        }),
      )
    }
    // @ts-ignore
    reset(defaultValues)
    setIsModalVisible(false)
  }
  useEffect(() => {
    // if (quoteFinancialAsset) return
    if (!tradeChange) {
      // @ts-ignore
      reset(defaultValues)
      return
    }
    console.log("useEffect, tradeChange: ", tradeChange)
    console.log(
      "useEffect, new Date(tradeChange.purchaseDate): ",
      new Date(tradeChange.purchaseDate),
    )
    reset({
      ...tradeChange,
      quoteFinancialAsset: financialAssetOptions.filter(
        (financialAssetOption) =>
          financialAssetOption.value.id === tradeChange.quoteFinancialAsset.id,
      )[0],
      baseFinancialAsset: financialAssetOptions.filter(
        (financialAssetOption) =>
          financialAssetOption.value.id === tradeChange.baseFinancialAsset.id,
      )[0],
      platform: platformOptions.filter(
        (platformOption) => platformOption.value.id === tradeChange.platform.id,
      )[0],
      purchaseDate: format(new Date(tradeChange.purchaseDate), "yyyy-MM-dd'T'HH:mm"), // Форматируем дату для datetime-local
    })
  }, [tradeChange, reset, financialAssetOptions])

  // const handleBlurTradePlatform = (event: React.FocusEvent) => {
  //   // @ts-ignore
  //   const inputValue = event?.target?.value?.trim()
  //   if (isValidUrl(inputValue)) {
  //     dispatch(platformsActions.postPlatformsAction.call({ domain: inputValue }))
  //   }
  // }

  // const currencyValue = watch("currencyValue")
  // const cryptocurrencyValue = watch("cryptocurrencyValue")
  // const cryptocurrencyName = watch("cryptocurrencyName")
  // const currencyName = watch("currencyName")

  const disabled = useMemo(() => !!tradeChange, [tradeChange])

  return (
    <Modal title='Adding a trade' visible={isModalVisible} onClose={() => setIsModalVisible(false)}>
      {/*{currencyValue && cryptocurrencyValue && cryptocurrencyName && currencyName && (*/}
      {/*  <div className={styles.change_in_purchase_rate}>*/}
      {/*    <span>Change in purchase rate: </span>*/}
      {/*    <ChangeInPurchaseRateItem*/}
      {/*      tonapi={tonapi}*/}
      {/*      cryptocurrencyRate={parseFloat(currencyValue) / parseFloat(cryptocurrencyValue)}*/}
      {/*      cryptocurrencyName={cryptocurrencyName.value}*/}
      {/*      currencyName={currencyName.value}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.block}>
            <SelectCustom
              // disabled={disabled}
              label='Wallet / Platform'
              name='platform'
              required
              options={platformOptions}
              // onBlur={handleBlurTradePlatform}
            />
            <div className={styles.item}>
              <SelectCustom
                disabled={disabled}
                label='Base Financial Asset'
                name='baseFinancialAsset'
                required
                options={quoteFinancialAssetOptions}
              />
              <InputCustom
                disabled={disabled}
                label='Base Financial Asset Quantity'
                name='baseFinancialAssetQuantity'
                type='number'
                required
              />
            </div>
            <div className={styles.item}>
              <SelectCustom
                disabled={disabled}
                label='Quote Financial Asset'
                name='quoteFinancialAsset'
                required
                options={baseFinancialAssetOptions}
              />
              <InputCustom
                disabled={disabled}
                name='quoteFinancialAssetQuantity'
                label='Quote Financial Asset Quantity'
                type='number'
                required
              />
            </div>
            <div className={styles.item}>
              <InputDate
                // disabled={disabled}
                type={"datetime-local"}
                name='purchaseDate'
                label='Purchase Date'
                required
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <Button type='submit'>Submit</Button>
            <Button
              theme='secondary'
              onClick={() => {
                // reset(defaultValues)
                setIsModalVisible(false)
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}
