import React from "react"
import ReactDOM from "react-dom/client"
import { App } from "./App"
import reportWebVitals from "../reportWebVitals"
import { register } from "../serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { persistor, store } from "Starter/store/configureStore"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6"
import { QueryParamProvider } from "use-query-params"
import "Base/assets/styles/_colors.variables.scss"
import "Base/i18n"
import "Base/assets/styles/main.scss"
import "Base/api/rest/utils/axiosInterceptors"
import { CustomProvidersWrapper } from "Base/tsx/providers"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <CustomProvidersWrapper>
            <App />
          </CustomProvidersWrapper>
        </QueryParamProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
register()
