import * as rolesActions from "Base/store/user/roles/actions"
import { takeLatest, call, put } from "redux-saga/effects"

import { TPostRolesReqAction, TPutRolesReqAction, TDeleteRolesReqAction } from "./types"
import { getErrorMessage } from "Base/utils/exaptations"
import { toast } from "react-toastify"
import { TDeleteUserRolesResponse } from "Base/api/rest/types/user/userRoles"
import { rolesProvider } from "Base/api/rest/globalApiProviders"
import {
  TGetRolesResponse,
  TPostRolesResponse,
  TPutRolesResponse,
} from "Base/api/rest/types/user/roles"

export function* getRolesSaga() {
  try {
    const resp: TGetRolesResponse = yield call([rolesProvider, rolesProvider.getRoles])
    if (!resp.data) throw new Error("Unknown error")
    yield put(rolesActions.getRolesAction.success(resp.data))
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      rolesActions.getRolesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* postRolesSaga({ payload }: TPostRolesReqAction) {
  try {
    const resp: TPostRolesResponse = yield call([rolesProvider, rolesProvider.postRoles], {
      data: payload,
    })
    if (!resp.success) throw new Error("Unknown error")
    yield put(rolesActions.postRolesAction.success(resp.data))
    toast.success("Успешно")
    yield put(rolesActions.getRolesAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      rolesActions.postRolesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* putRolesSaga({ payload }: TPutRolesReqAction) {
  try {
    const resp: TPutRolesResponse = yield call([rolesProvider, rolesProvider.putRoles], {
      data: payload,
    })
    if (!resp.success) throw new Error("Unknown error")
    yield put(rolesActions.putRolesAction.success(resp.data))
    toast.success("Успешно")
    yield put(rolesActions.getRolesAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      rolesActions.putRolesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* deleteRolesSaga({ payload }: TDeleteRolesReqAction) {
  try {
    const resp: TDeleteUserRolesResponse = yield call([rolesProvider, rolesProvider.deleteRoles], {
      params: payload,
    })
    if (!resp.success) throw new Error("Unknown error")
    yield put(rolesActions.deleteRolesAction.success(resp.data))
    toast.success("Успешно")
    yield put(rolesActions.getRolesAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      rolesActions.deleteRolesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* rolesWatcher() {
  yield takeLatest(rolesActions.getRolesAction.call.type, getRolesSaga)
  yield takeLatest(rolesActions.postRolesAction.call.type, postRolesSaga)
  yield takeLatest(rolesActions.putRolesAction.call.type, putRolesSaga)
  yield takeLatest(rolesActions.deleteRolesAction.call.type, deleteRolesSaga)
}
