import { modulesRoutes } from "./modulesRoutes"

const getAdminRoutes = (baseURL: string) => {
  const url = baseURL !== "" ? `${baseURL}` : ""
  return {
    root: `${url}`,
    title: "Account management",
    users: {
      root: `${url}/users`,
      title: "Users",
    },
    roles: {
      root: `${url}/roles`,
      title: "Roles",
    },
    platforms: {
      root: `${url}/platforms`,
      title: "Platforms",
    },
    "financial-asset-types": {
      root: `${url}/financial-asset-types`,
      title: "FinancialAssetTypes",
    },
    "financial-assets": {
      root: `${url}/financial-assets`,
      title: "FinancialAssets",
    },
  }
}

export const adminRoutes = getAdminRoutes(modulesRoutes.admin.root)
