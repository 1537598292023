import React from "react"
import { Route, Navigate } from "react-router-dom"

import { profileModuleRoutes } from "Base/constants/routes"
import { SettingsProfile } from "../pages/SettingsProfile"
import { DefaultLayout } from "Base/tsx/layouts/DefaultLayout"

export const getProfileRouters = () => (
  <>
    <Route
      path={profileModuleRoutes.profile.settings.root}
      element={
        <DefaultLayout>
          <SettingsProfile />
        </DefaultLayout>
      }
    />
    <Route
      path={`${profileModuleRoutes.root}/*`}
      element={<Navigate to={profileModuleRoutes.profile.root} replace={false} />}
    />
  </>
)
