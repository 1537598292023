import React, { useContext, useState } from "react"
import styles from "./ThemeSwitch.module.scss"
import { ThemeContext } from "Base/tsx/contexts"

export const ThemeSwitch = () => {
  // const [isDarkMode, setIsDarkMode] = useState(
  //   document.documentElement.getAttribute("data-theme") !== "light",
  // )
  const { theme, toggleTheme } = useContext(ThemeContext)
  const isDarkMode = theme === "dark"

  return (
    <div className={styles.themeSwitchWrapper}>
      <span className={styles.themeIcon}>{isDarkMode ? "🌙" : "☀️"}</span>
      <label className={styles.themeSwitch}>
        <input type='checkbox' onChange={toggleTheme} checked={isDarkMode} />
        <span className={styles.slider}></span>
      </label>
    </div>
  )
}
