import { createReducer } from "@reduxjs/toolkit"

import {
  TAddTransactionsInfoFailureAction,
  TAddTransactionsInfoSuccessAction,
  TUpdateTransactionsInfoFailureAction,
  TUpdateTransactionsInfoSuccessAction,
} from "./types"
import { TRequestInfoChildType } from "Base/types/store/reducer"
import { RequestInfoChildState } from "Base/constants/statesDefault"
import { transactionsInfoActions } from "Base/store/deprecated/transactionsInfo/actions"

export type TProfit = { [key: string]: number }
export type TCryptocurrenciesTurnover = { [key: string]: number }
export type TCurrenciesTurnover = { [key: string]: number }
export type TTransactionsInfoData = {
  cryptocurrenciesNames: { [key: string]: string }
  currenciesNames: { [key: string]: string }
  tradePlatformsNames: { [key: string]: string }
  profit: TProfit
  cryptocurrenciesTurnover: { [key: string]: number }
  currenciesTurnover: { [key: string]: number }
}

export type TTransactionsInfoState = {
  addTransactionsInfo: TRequestInfoChildType
  updateTransactionsInfo: TRequestInfoChildType
} & TTransactionsInfoData

const initialState: TTransactionsInfoState = {
  cryptocurrenciesNames: {},
  currenciesNames: {},
  tradePlatformsNames: {},
  profit: {},
  cryptocurrenciesTurnover: {},
  currenciesTurnover: {},
  addTransactionsInfo: {
    ...RequestInfoChildState,
  },
  updateTransactionsInfo: {
    ...RequestInfoChildState,
  },
}

export const transactionsInfoReducer = createReducer(initialState, (builder) => {
  builder
    //addTransactionsInfo
    .addCase(transactionsInfoActions.addTransactionsInfoAction.call.type, (state) => {
      console.log("addTransactionsInfoAction.call, state: ", state)
      state.addTransactionsInfo.isLoading = true
    })
    .addCase(
      transactionsInfoActions.addTransactionsInfoAction.success.type,
      (state, action: TAddTransactionsInfoSuccessAction) => {
        state.cryptocurrenciesNames = action.payload.cryptocurrenciesNames
        state.currenciesNames = action.payload.currenciesNames
        state.tradePlatformsNames = action.payload.tradePlatformsNames
        state.profit = action.payload.profit
        state.currenciesTurnover = action.payload.currenciesTurnover
        state.cryptocurrenciesTurnover = action.payload.cryptocurrenciesTurnover
        state.addTransactionsInfo.isLoading = false
      },
    )
    .addCase(
      transactionsInfoActions.addTransactionsInfoAction.failure.type,
      (state, action: TAddTransactionsInfoFailureAction) => {
        state.addTransactionsInfo.error = action.payload.error
        state.addTransactionsInfo.isLoading = false
      },
    )
    //updateTransactionsInfo
    .addCase(transactionsInfoActions.updateTransactionsInfoAction.call.type, (state) => {
      console.log("addTransactionsInfoAction.call, state: ", state)
      state.addTransactionsInfo.isLoading = true
    })
    .addCase(
      transactionsInfoActions.updateTransactionsInfoAction.success.type,
      (state, action: TUpdateTransactionsInfoSuccessAction) => {
        state.cryptocurrenciesNames = action.payload.cryptocurrenciesNames
        state.currenciesNames = action.payload.currenciesNames
        state.tradePlatformsNames = action.payload.tradePlatformsNames
        state.profit = action.payload.profit
        state.currenciesTurnover = action.payload.currenciesTurnover
        state.cryptocurrenciesTurnover = action.payload.cryptocurrenciesTurnover
        state.addTransactionsInfo.isLoading = false
      },
    )
    .addCase(
      transactionsInfoActions.updateTransactionsInfoAction.failure.type,
      (state, action: TUpdateTransactionsInfoFailureAction) => {
        state.addTransactionsInfo.error = action.payload.error
        state.addTransactionsInfo.isLoading = false
      },
    )
})
