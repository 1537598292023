import React from "react"
import { UserAssets } from "TraderV2/tsx/containers"
import { TraderV2Layout } from "TraderV2/tsx/layouts"
import { PageContainer } from "Base/tsx/containers/PageContainer"

export function UserAssetsControl() {
  return (
    <PageContainer>
      <UserAssets />
    </PageContainer>
  )
}
