import { createReducer } from "@reduxjs/toolkit"

import { TLanguage, TSetAppSettingsReqAction, TTheme } from "./types"
import { appSettingsActions } from "Base/store/user/appSettingsPersist/actions"

export type AppSettingsPersistStateType = {
  settings: {
    language: TLanguage
    theme: TTheme
  }
}

const initialState: AppSettingsPersistStateType = {
  settings: {
    language: "ru",
    theme: "dark",
  },
}

export const appSettingsPersistReducer = createReducer(initialState, {
  [appSettingsActions.setAppSettingsAction.call.type]: (
    state,
    action: TSetAppSettingsReqAction,
  ) => ({
    ...state,
    settings: {
      ...state.settings,
      ...action.payload,
    },
  }),
})
