import { all, fork } from "redux-saga/effects"

import { transactionsWatcher } from "Base/store/deprecated/transactions/sagas"
import { binanceWatcher } from "Base/store/deprecated/binance/sagas"
import { tonapiWatcher } from "Base/store/deprecated/tonapi/sagas"
import { transactionsInfoWatcher } from "Base/store/deprecated/transactionsInfo/sagas"
import { binanceWsWatcher } from "Base/store/deprecated/binanceWs/sagas"
import { exchangeRateWatcher } from "Base/store/deprecated/exchangeRate/saga"
import { authPersistWatcher } from "Base/store/user/authPersist/sagas"
import { profilePersistWatcher } from "Base/store/user/profilePersist/sagas"
import { rolesWatcher } from "Base/store/user/roles/sagas"
import { userRolesWatcher } from "Base/store/user/userRoles/sagas"
import { usersWatcher } from "Base/store/user/users/sagas"
import { platformsWatcher } from "Base/store/trade/platforms/sagas"
import { financialAssetTypesWatcher } from "Base/store/trade/financialAssetTypes/sagas"
import { financialAssetsWatcher } from "Base/store/trade/financialAssets/sagas"
import { tradesWatcher } from "Base/store/trade/trades/sagas"
import { marketOrdersWatcher } from "Base/store/trade/marketOrders/sagas"
import { financialAssetRatesWatcher } from "Base/store/trade/financialAssetRates/sagas"
import { userAssetsWatcher } from "Base/store/user/userAssets/sagas"

export function* baseRootSaga() {
  try {
    yield all([fork(transactionsInfoWatcher)])
    yield all([fork(transactionsWatcher)])
    yield all([fork(binanceWsWatcher)])
    yield all([fork(binanceWatcher)])
    yield all([fork(tonapiWatcher)])
    yield all([fork(exchangeRateWatcher)])
    yield all([fork(authPersistWatcher)])
    yield all([fork(profilePersistWatcher)])
    yield all([fork(usersWatcher)])
    yield all([fork(rolesWatcher)])
    yield all([fork(userRolesWatcher)])
    yield all([fork(platformsWatcher)])
    yield all([fork(financialAssetTypesWatcher)])
    yield all([fork(financialAssetsWatcher)])
    yield all([fork(tradesWatcher)])
    yield all([fork(marketOrdersWatcher)])
    yield all([fork(financialAssetRatesWatcher)])
    yield all([fork(userAssetsWatcher)])
  } finally {
    // always runs
  }
}
