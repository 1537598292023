import React from "react"
import { SidebarProvider, ThemeProvider } from "Base/tsx/providers"

export function CustomProvidersWrapper({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider>
      <SidebarProvider>{children}</SidebarProvider>
    </ThemeProvider>
  )
}
