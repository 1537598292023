// api/providers/UserRolesProvider.ts
import { ApiProviderBase } from "Base/api/rest/ApiProviderBase"
import {
  TDeleteUserRolesRequest,
  TDeleteUserRolesResponse,
  TDeleteUserRolesResponseData,
  TGetUserRolesResponse,
  TGetUserRolesResponseData,
  TPostUserRolesRequest,
  TPostUserRolesResponse,
  TPostUserRolesResponseData,
  TPutUserRolesRequest,
  TPutUserRolesResponse,
  TPutUserRolesResponseData,
} from "Base/api/rest/types/user/userRoles"

export class UserRolesProvider extends ApiProviderBase {
  async getUserRoles(): Promise<TGetUserRolesResponse> {
    return this.request<TGetUserRolesResponseData>({
      url: "/user-roles",
      method: "GET",
    })
  }

  async postUserRoles(params: TPostUserRolesRequest): Promise<TPostUserRolesResponse> {
    return this.request<TPostUserRolesResponseData>({
      url: "/user-roles",
      method: "POST",
      data: params.data,
    })
  }

  async putUserRoles(params: TPutUserRolesRequest): Promise<TPutUserRolesResponse> {
    return this.request<TPutUserRolesResponseData>({
      url: "/user-roles/" + params.data?.id,
      method: "PUT",
      data: params.data,
    })
  }

  async deleteUserRoles(params: TDeleteUserRolesRequest): Promise<TDeleteUserRolesResponse> {
    return this.request<TDeleteUserRolesResponseData>({
      url: "/user-roles/" + params.params?.id,
      method: "DELETE",
    })
  }
}
