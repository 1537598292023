import { transactionsActions } from "Base/store/deprecated/transactions/actions"
import { takeLatest, put, select, call } from "redux-saga/effects"

import {
  TArchiveTransactionReqAction,
  TDeleteTransactionReqAction,
  TGetTransactionsReqAction,
  TImportTransactionsFromFileReqAction,
  TSetTransactionReqAction,
  TUpdateTransactionReqAction,
} from "./types"
import {
  TFullTransaction,
  TransactionStatuses,
  TTransaction,
} from "Base/types/deprecated/trade/transactions"
import { format } from "date-fns"
import { transactionsInfoActions } from "Base/store/deprecated/transactionsInfo/actions"
import { dbTransaction } from "Base/store/indexedDB/indexedDBSaga"
import { SagaIterator } from "redux-saga"

function* clearAllTransactions(): SagaIterator<void> {
  yield call(dbTransaction, "transactions", "id", "readwrite", function* (store) {
    yield call([store, store.clear])
  })
}

// Сохранение транзакций
function* saveTransactions(transactions: TFullTransaction[]): SagaIterator<void> {
  yield call(dbTransaction, "transactions", "id", "readwrite", function* (store) {
    for (const transaction of transactions) {
      yield call([store, store.put], transaction)
    }
  })
}

function* deleteTransaction(transaction: TFullTransaction): SagaIterator<void> {
  yield call(dbTransaction, "transactions", "id", "readwrite", function* (store) {
    yield call([store, store.delete], transaction.id)
  })
}

function* getTransactionById(transactionId: string): SagaIterator<TFullTransaction | undefined> {
  let result: TFullTransaction | undefined
  yield call(dbTransaction, "transactions", "id", "readonly", function* (store) {
    result = yield call([store, store.get], transactionId)
  })
  return result
}

// Получение всех транзакций
function* getTransactions(): SagaIterator<TFullTransaction[] | undefined> {
  let result: TFullTransaction[] | undefined
  yield call(dbTransaction, "transactions", "id", "readonly", function* (store) {
    result = yield call([store, store.getAll])
  })
  return result
}

export function* getTransactionsSaga({ payload }: TGetTransactionsReqAction) {
  try {
    const res: TFullTransaction[] = yield getTransactions()
    yield put(transactionsActions.getTransactionsAction.success(res))
  } catch (e: unknown) {
    yield put(
      transactionsActions.getTransactionsAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* importTransactionsFromFileSaga({ payload }: TImportTransactionsFromFileReqAction) {
  try {
    let transactions: TFullTransaction[] = []
    if (payload.version === "1") {
      transactions = payload.data.map((transaction: TFullTransaction) => {
        return {
          ...transaction,
          status: TransactionStatuses[transaction.status] || TransactionStatuses.archive,
        }
      })
    }
    yield clearAllTransactions()
    yield saveTransactions(transactions)
    yield put(
      transactionsInfoActions.addTransactionsInfoAction.call({ transactions: transactions }),
    )
    yield put(transactionsActions.importTransactionsFromFileAction.success())
  } catch (e: unknown) {
    yield put(
      transactionsActions.importTransactionsFromFileAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* setTransactionSaga({ payload }: TSetTransactionReqAction) {
  try {
    yield saveTransactions([payload])
    yield put(transactionsActions.setTransactionAction.success())
    yield put(transactionsInfoActions.addTransactionsInfoAction.call({ transactions: [payload] }))
    yield reloadTransactionsSaga()
  } catch (e: unknown) {
    yield put(
      transactionsActions.setTransactionAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* updateTransactionSaga({ payload }: TUpdateTransactionReqAction) {
  try {
    const transaction: TFullTransaction | undefined = yield getTransactionById(payload.id)
    if (transaction) {
      const transactionNew = {
        ...payload,
        dateOfChange: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        history: [transaction as TTransaction, ...(transaction?.history || [])],
      }
      yield saveTransactions([transactionNew])
      yield put(
        transactionsInfoActions.updateTransactionsInfoAction.call({
          transactions: [
            {
              transactionUpdated: transactionNew,
              transactionOld: transaction,
            },
          ],
        }),
      )
    }
    yield put(transactionsActions.updateTransactionAction.success())
    yield reloadTransactionsSaga()
  } catch (e: unknown) {
    yield put(
      transactionsActions.updateTransactionAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* deleteTransactionSaga({ payload }: TDeleteTransactionReqAction) {
  try {
    yield deleteTransaction(payload)
    yield put(
      transactionsInfoActions.updateTransactionsInfoAction.call({
        transactions: [
          {
            transactionOld: payload,
          },
        ],
      }),
    )
    yield put(transactionsActions.deleteTransactionAction.success())
    yield reloadTransactionsSaga()
  } catch (e: unknown) {
    yield put(
      transactionsActions.deleteTransactionAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* archiveTransactionSaga({ payload }: TArchiveTransactionReqAction) {
  try {
    const transaction: TFullTransaction | undefined = yield getTransactionById(payload.id)
    if (transaction) {
      const transactionNew = {
        ...payload,
        status: TransactionStatuses.archive,
        dateOfChange: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        history: [transaction as TTransaction, ...(transaction?.history || [])],
      }
      yield saveTransactions([transactionNew])
    }
    yield put(transactionsActions.archiveTransactionAction.success())
    yield reloadTransactionsSaga()
  } catch (e: unknown) {
    yield put(
      transactionsActions.archiveTransactionAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* reloadTransactionsSaga() {
  try {
    // const { transactions } = yield select(({ base }: RootState) => base.transactionsPersist)
    yield put(transactionsActions.getTransactionsAction.call())
  } catch (e: unknown) {
    console.log(e)
  }
}

export function* transactionsWatcher() {
  yield takeLatest(transactionsActions.getTransactionsAction.call.type, getTransactionsSaga)
  yield takeLatest(transactionsActions.setTransactionAction.call.type, setTransactionSaga)
  yield takeLatest(transactionsActions.updateTransactionAction.call.type, updateTransactionSaga)
  yield takeLatest(transactionsActions.deleteTransactionAction.call.type, deleteTransactionSaga)
  yield takeLatest(transactionsActions.archiveTransactionAction.call.type, archiveTransactionSaga)
  yield takeLatest(
    transactionsActions.importTransactionsFromFileAction.call.type,
    importTransactionsFromFileSaga,
  )
}
