import { takeLatest, put, fork, call, take, cancelled } from "redux-saga/effects"
import { binanceWsActions } from "Base/store/deprecated/binanceWs/actions"
import { eventChannel, EventChannel, SagaIterator } from "redux-saga"
import { exchangeRateActions } from "Base/store/deprecated/exchangeRate/actions"
import { TExchangeRateBinance } from "Base/store/deprecated/exchangeRate/types"

interface RateData {
  p: number
  q: number
  c: number
  s: string
}

// Создание EventChannel для WebSocket
function createWebSocketChannel(webSocket: WebSocket): EventChannel<RateData> {
  return eventChannel((emitter) => {
    webSocket.onmessage = (event) => {
      emitter(JSON.parse(event.data))
    }
    webSocket.onclose = () => {
      // Закрыть channel если websocket закрыт
      emitter(new Error("WebSocket closed") as any)
    }
    return () => {
      webSocket.close()
    }
  })
}

interface IListEntry {
  [key: string]: number
}

const quoteCurrencies: string[] = ["USDT", "BTC", "ETH", "LTC"] // Добавьте все котируемые валюты, которые могут быть

function transformValue(list: IListEntry[]): TExchangeRateBinance {
  let transformedValue: TExchangeRateBinance = {}

  for (const item of list) {
    // Extract key-value pair from each object
    const [name, val] = Object.entries(item)[0]

    let baseCurrency = ""
    let quoteCurrency = ""

    // Находим соответствующую котируемую валюту и разделяем
    for (const quote of quoteCurrencies) {
      if (name.endsWith(quote)) {
        quoteCurrency = quote
        baseCurrency = name.slice(0, -quote.length)
        break
      }
    }

    if (!baseCurrency || !quoteCurrency) {
      console.error(`Couldn't parse currency pair ${name}`)
      continue
    }

    transformedValue = {
      ...transformedValue,
      [name]: {
        name,
        baseCurrency,
        quoteCurrency,
        rate: val,
      },
    }
  }

  return transformedValue
}

// Сага для обработки полученных данных о курсе
// eslint-disable-next-line require-yield
function* handleReceivedRate(data: RateData): SagaIterator {
  // Здесь вы можете, например, диспетчеризовать экшен с полученными данными
  try {
    const { c: quantity, s: name } = data
    // console.log(`handleReceivedRate, data: `, data)
    // console.log(`handleReceivedRate, quantity: `, quantity)
    // console.log(`handleReceivedRate, name: `, name)
    yield put(
      exchangeRateActions.setExchangeRateAction.call({
        binance: {
          ...transformValue([{ [name]: quantity }]),
        },
      }),
    )
    // console.log(`Price: ${price}, Quantity: ${quantity}, Name: ${name}`)
    // Здесь вы можете добавить дополнительную логику, например, обновление Redux store
  } catch (e) {
    // Заметьте использование any, потому что e может быть неизвестного типа
    yield put(
      binanceWsActions.wsExchangeRateAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

// Сага для слушания сообщений от WebSocket
export function* wsExchangeRateSaga(): SagaIterator {
  // const webSocket = new WebSocket("wss://stream.binance.com:9443/ws/btcusdt@trade")
  const webSocket = new WebSocket(
    "wss://stream.binance.com:9443/stream?streams=btcusdt@ticker/ethusdt@ticker/ltcusdt@ticker/ethbtc@ticker/ltceth@ticker/ltcbtc@ticker",
  )

  const webSocketChannel: EventChannel<RateData> = yield call(createWebSocketChannel, webSocket)

  try {
    while (true) {
      const rateData = yield take(webSocketChannel)
      yield fork(handleReceivedRate, rateData.data)
    }
  } catch (e) {
    yield put(
      binanceWsActions.wsExchangeRateAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  } finally {
    if (yield cancelled()) {
      webSocketChannel.close()
      webSocket.close()
    }
  }
}

export function* binanceWsWatcher(): SagaIterator {
  yield takeLatest(binanceWsActions.wsExchangeRateAction.call.type, wsExchangeRateSaga)
}
