import * as userAssetsActions from "Base/store/user/userAssets/actions"
import { takeLatest, call, put } from "redux-saga/effects"

import { getErrorMessage } from "Base/utils/exaptations"
import { toast } from "react-toastify"
import { userAssetsProvider } from "Base/api/rest/globalApiProviders"
import { TGetUserAssetsResponse } from "Base/api/rest/types/trade/userAssets"

export function* getUserAssetsSaga() {
  try {
    const resp: TGetUserAssetsResponse = yield call([
      userAssetsProvider,
      userAssetsProvider.getUserAssets,
    ])
    console.log("getUserAssetsSaga, resp: ", resp)

    if (!resp.success || !resp.data) throw new Error("Unknown error")
    yield put(userAssetsActions.getUserAssetsAction.success(resp.data))
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      userAssetsActions.getUserAssetsAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* userAssetsWatcher() {
  yield takeLatest(userAssetsActions.getUserAssetsAction.call.type, getUserAssetsSaga)
}
