import { baseCSF } from "Base/utils/store"
import {
  TWsExchangeRateFailurePayload,
  TWsExchangeRateReqPayload,
  TWsExchangeRateSuccessPayload,
} from "Base/store/deprecated/binanceWs/types"

const prefix = "binance-ws"

const wsExchangeRateAction = baseCSF<
  TWsExchangeRateReqPayload,
  TWsExchangeRateSuccessPayload,
  TWsExchangeRateFailurePayload,
  undefined
>(prefix, "ws-exchange-rate")

export const binanceWsActions = {
  wsExchangeRateAction,
}
