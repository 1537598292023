import React from "react"
import { Route, Navigate } from "react-router-dom"

import { traderV2ModuleRoutes } from "Base/constants/routes/traderV2ModuleRoutes"
import { Management, UserAssetsControl } from "TraderV2/tsx/pages"
import { TraderV2Layout } from "TraderV2/tsx/layouts"
import { BaseLayout } from "Base/tsx/layouts"

export const getTraderV2Routers = () => {
  return (
    <>
      <Route
        path={traderV2ModuleRoutes["user-assets-control"].root}
        element={
          <BaseLayout>
            <TraderV2Layout>
              <UserAssetsControl />
            </TraderV2Layout>
          </BaseLayout>
        }
      />
      <Route
        path={traderV2ModuleRoutes.management.root}
        element={
          <BaseLayout>
            <TraderV2Layout>
              <Management />
            </TraderV2Layout>
          </BaseLayout>
        }
      />
      <Route
        path={`${traderV2ModuleRoutes.root}/*`}
        element={<Navigate to={traderV2ModuleRoutes.management.root} replace={false} />}
      />
    </>
  )
}
