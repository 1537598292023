import { Button, InputCustom, Modal, SelectCustom } from "Base/tsx/components"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import styles from "./PlatformFormModal.module.scss"
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as platformsActions from "Base/store/trade/platforms/actions"
import { RootState } from "Starter/store/configureStore"
import { TPlatform } from "Base/api/rest/types/trade/platforms"

interface IFormInput {
  domain: string
}

export const PlatformFormModal = ({
  platformChange,
  isModalVisible,
  setIsModalVisible,
}: {
  platformChange?: TPlatform
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
}) => {
  const dispatch = useDispatch()
  const {
    financialAssetTypes: { getFinancialAssetTypes },
  } = useSelector((state: RootState) => state.base)

  const financialAssetTypeOptions = useMemo(() => {
    if (!getFinancialAssetTypes?.data) return []
    return getFinancialAssetTypes.data.map((financialAssetType) => {
      return {
        label: financialAssetType.symbol,
        value: financialAssetType,
      }
    })
  }, [getFinancialAssetTypes?.data])

  const defaultValues = {
    domain: "",
  }

  const methods = useForm<IFormInput>({
    defaultValues,
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    resolver: undefined,
  })

  const { handleSubmit, reset } = methods

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    console.log("onSubmit, data: ", data)
    console.log("onSubmit: platformChange: ", platformChange)
    if (platformChange) {
      dispatch(
        platformsActions.putPlatformsAction.call({
          ...platformChange,
          domain: data.domain,
        }),
      )
    } else {
      dispatch(
        platformsActions.postPlatformsAction.call({
          domain: data.domain,
        }),
      )
    }
    reset(defaultValues)
    setIsModalVisible(false)
  }
  useEffect(() => {
    console.log("platformChange: platformChange: ", platformChange)
    if (!platformChange) {
      reset(defaultValues)
      return
    }
    reset({
      ...platformChange,
    })
  }, [platformChange, reset, financialAssetTypeOptions])

  return (
    <Modal
      title='Adding platform'
      visible={isModalVisible}
      onClose={() => setIsModalVisible(false)}
    >
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.block}>
            <InputCustom name='domain' label='Symbol' required />
          </div>
          <div className={styles.buttons}>
            <Button type='submit'>Submit</Button>
            <Button
              theme='secondary'
              onClick={() => {
                reset(defaultValues)
                setIsModalVisible(false)
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}
