import {
  Button,
  SelectCustom,
  InputCustom,
  InputDate,
  Modal,
  ChangeInPurchaseRateItem,
} from "Base/tsx/components"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import styles from "./BuyFormModal.module.scss"
import React, { useEffect, useState } from "react"
import {
  cryptocurrencyOptions,
  cryptocurrencyOptionsObj,
} from "Base/constants/cryptocurrencyOptions"
import { TOption } from "Base/tsx/components/inputs/SelectCustom"
import { currencyOptions, currencyOptionsObj } from "Base/constants/currencyOptions"
import { transactionsActions } from "Base/store/deprecated/transactions/actions"
import { TFullTransaction, TransactionStatuses } from "Base/types/deprecated/trade/transactions"
import { format } from "date-fns"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "Starter/store/configureStore"

interface IFormInput {
  cryptocurrencyName: TOption<string>
  cryptocurrencyValue: string
  currencyName: TOption<string>
  currencyValue: string
  dateOfPurchase: string
  tradePlatformName: TOption<string>
}

export const BuyFormModal = ({
  transactionChange,
  isModalVisible,
  setIsModalVisible,
}: {
  transactionChange?: TFullTransaction
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
}) => {
  const dispatch = useDispatch()
  const {
    transactionsInfo: { tradePlatformsNames },
    exchangeRate: {
      providers: { tonapi },
    },
  } = useSelector((state: RootState) => state.base)
  const [optionsTradePlatformsNames, setOptionsTradePlatformsNames] = useState<TOption<string>[]>(
    [],
  )

  useEffect(() => {
    console.log("tradePlatformsNames: ", tradePlatformsNames)
    setOptionsTradePlatformsNames([
      ...Object.keys(tradePlatformsNames).map((key) => {
        return {
          label: key,
          value: key,
        }
      }),
    ])
  }, [tradePlatformsNames])

  const defaultValues = {
    cryptocurrencyValue: "",
    cryptocurrencyRate: "",
    currencyValue: "",
    dateOfPurchase: "",
  }

  const methods = useForm<IFormInput>({
    defaultValues,
  })
  const { handleSubmit, reset, setValue, watch } = methods

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (transactionChange) {
      dispatch(
        transactionsActions.updateTransactionAction.call({
          ...transactionChange,
          ...data,
          tradePlatformName: data.tradePlatformName.label.trim(),
          cryptocurrencyName: data.cryptocurrencyName.value,
          currencyName: data.currencyName.value,
          buy: {
            ...transactionChange.buy,
            cryptocurrencyValue: parseFloat(data.cryptocurrencyValue),
            cryptocurrencyRate:
              parseFloat(data.currencyValue) / parseFloat(data.cryptocurrencyValue),
            currencyValue: parseFloat(data.currencyValue),
            dateOfPurchase: format(new Date(data.dateOfPurchase), "yyyy-MM-dd HH:mm:ss"),
          },
        }),
      )
    } else {
      dispatch(
        transactionsActions.setTransactionAction.call({
          ...data,
          id: new Date().getTime().toString(),
          tradePlatformName: data.tradePlatformName.label.trim(),
          cryptocurrencyName: data.cryptocurrencyName.value,
          currencyName: data.currencyName.value,
          status: TransactionStatuses.purchased,
          dateOfChange: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          buy: {
            dateOfPurchase: format(new Date(data.dateOfPurchase), "yyyy-MM-dd HH:mm:ss"),
            cryptocurrencyValue: parseFloat(data.cryptocurrencyValue),
            cryptocurrencyRate:
              parseFloat(data.currencyValue) / parseFloat(data.cryptocurrencyValue),
            currencyValue: parseFloat(data.currencyValue),
          },
        }),
      )
    }
    reset(defaultValues)
    setIsModalVisible(false)
  }
  useEffect(() => {
    if (!transactionChange) {
      reset(defaultValues)
      return
    }
    reset({
      ...transactionChange,
      tradePlatformName: {
        label: transactionChange.tradePlatformName,
        value: transactionChange.tradePlatformName,
      },
      cryptocurrencyName: cryptocurrencyOptionsObj[transactionChange.cryptocurrencyName],
      currencyName: currencyOptionsObj[transactionChange.currencyName],
      cryptocurrencyValue: transactionChange.buy.cryptocurrencyValue?.toString(),
      currencyValue: transactionChange.buy.currencyValue?.toString(),
    })
  }, [transactionChange, reset])

  const handleBlurTradePlatformName = (event: React.FocusEvent) => {
    // @ts-ignore
    const inputValue = event?.target?.value?.trim()
    if (inputValue && !optionsTradePlatformsNames.some((option) => option.label === inputValue)) {
      const newOption = { value: inputValue, label: inputValue }
      setOptionsTradePlatformsNames((prevOptions) => [newOption, ...prevOptions])
      setValue("tradePlatformName", newOption) // обновляем значение в react-hook-form
    }
  }

  const currencyValue = watch("currencyValue")
  const cryptocurrencyValue = watch("cryptocurrencyValue")
  const cryptocurrencyName = watch("cryptocurrencyName")
  const currencyName = watch("currencyName")

  return (
    <Modal
      title='Adding a transaction'
      visible={isModalVisible}
      onClose={() => setIsModalVisible(false)}
    >
      {currencyValue && cryptocurrencyValue && cryptocurrencyName && currencyName && (
        <div className={styles.change_in_purchase_rate}>
          <span>Change in purchase rate: </span>
          <ChangeInPurchaseRateItem
            tonapi={tonapi}
            cryptocurrencyRate={parseFloat(currencyValue) / parseFloat(cryptocurrencyValue)}
            cryptocurrencyName={cryptocurrencyName.value}
            currencyName={currencyName.value}
          />
        </div>
      )}
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.block}>
            <SelectCustom
              label='Trade platform name'
              name='tradePlatformName'
              required
              options={optionsTradePlatformsNames}
              onBlur={handleBlurTradePlatformName}
            />
            <div className={styles.item}>
              <SelectCustom
                label='Cryptocurrency name'
                name='cryptocurrencyName'
                required
                options={cryptocurrencyOptions.map((cryptocurrencyOption) => ({
                  ...cryptocurrencyOption,
                  label: cryptocurrencyOption.label + " (" + cryptocurrencyOption.value + ")",
                }))}
              />
              <InputCustom
                name='cryptocurrencyValue'
                label='Cryptocurrency value'
                type='number'
                required
              />
            </div>
            <div className={styles.item}>
              <SelectCustom
                label='Currency name'
                name='currencyName'
                required
                options={currencyOptions.map((currencyOption) => ({
                  ...currencyOption,
                  label: currencyOption.label + " (" + currencyOption.value + ")",
                }))}
              />
              <InputCustom name='currencyValue' label='Currency value' type='number' required />
            </div>
            <div className={styles.item}>
              <InputDate
                type={"datetime-local"}
                name='dateOfPurchase'
                label='Date of purchase'
                required
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <Button type='submit'>Submit</Button>
            <Button
              theme='secondary'
              onClick={() => {
                reset(defaultValues)
                setIsModalVisible(false)
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}
