import { baseCSF } from "Base/utils/store"

import { TGetUsersFailurePayload, TGetUsersReqPayload, TGetUsersSuccessPayload } from "./types"

const prefix = "users"

export const getUsersAction = baseCSF<
  TGetUsersReqPayload,
  TGetUsersSuccessPayload,
  TGetUsersFailurePayload,
  undefined
>(prefix, "get")
//
// export const getUsersByIdAction = baseCSF<
//   TGetUsersByIdReqPayload,
//   TGetUsersByIdSuccessPayload,
//   TGetUsersByIdFailurePayload,
//   undefined
// >(prefix, "get-by-id")
//
// export const postUsersAction = baseCSF<
//   TPostUsersReqPayload,
//   TPostUsersSuccessPayload,
//   TPostUsersFailurePayload,
//   undefined
// >(prefix, "post")
//
// export const putUsersAction = baseCSF<
//   TPutUsersReqPayload,
//   TPutUsersSuccessPayload,
//   TPutUsersFailurePayload,
//   undefined
// >(prefix, "put")
