import * as tradesActions from "Base/store/trade/trades/actions"
import { takeLatest, call, put } from "redux-saga/effects"

import {
  TPostTradesCallAction,
  TPutTradesCallAction,
  TDeleteTradesCallAction,
  TGetTradesUserCallAction,
  TPatchTradesStatusCallAction,
  TGetTradesCallAction,
} from "./types"
import { getErrorMessage } from "Base/utils/exaptations"
import { toast } from "react-toastify"
import { tradesProvider } from "Base/api/rest/globalApiProviders"
import {
  TDeleteTradesResponse,
  TGetTradesResponse,
  TGetTradesUserResponse,
  TPatchTradesStatusResponse,
  TPostTradesResponse,
  TPutTradesResponse,
} from "Base/api/rest/types/trade/trades"

export function* getTradesSaga({ payload }: TGetTradesCallAction) {
  try {
    const params = new URLSearchParams(window.location.search)
    const status = params.get("tradeStatus") // Получаем статус из URL
    const resp: TGetTradesResponse = yield call([tradesProvider, tradesProvider.getTrades], {
      params: payload || { status },
    })
    console.log("getTradesSaga, resp: ", resp)

    if (!resp.data) throw new Error("Unknown error")
    yield put(tradesActions.getTradesAction.success(resp.data))
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      tradesActions.getTradesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* getTradesUserSaga({ payload }: TGetTradesUserCallAction) {
  try {
    const params = new URLSearchParams(window.location.search)
    const status = params.get("tradeStatus") // Получаем статус из URL
    const resp: TGetTradesUserResponse = yield call(
      [tradesProvider, tradesProvider.getTradesUser],
      {
        params: payload || { status },
      },
    )
    if (!resp.data) throw new Error("Unknown error")
    yield put(tradesActions.getTradesUserAction.success(resp.data))
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      tradesActions.getTradesUserAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* postTradesSaga({ payload }: TPostTradesCallAction) {
  try {
    console.log("postTradesSaga, payload: ", payload)
    const resp: TPostTradesResponse = yield call([tradesProvider, tradesProvider.postTrades], {
      data: payload,
    })
    if (!resp.data) throw new Error("Unknown error")
    console.log("postTradesSaga, resp: ", resp)

    yield put(tradesActions.postTradesAction.success(resp.data))
    toast.success("Успешно")
    yield put(tradesActions.getTradesUserAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      tradesActions.postTradesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* patchTradesStatusSaga({ payload }: TPatchTradesStatusCallAction) {
  try {
    console.log("patchTradesStatusSaga, payload: ", payload)
    const resp: TPatchTradesStatusResponse = yield call(
      [tradesProvider, tradesProvider.patchTradesStatus],
      {
        params: {
          id: payload.id,
        },
        data: {
          status: payload.status,
        },
      },
    )
    console.log("patchTradesStatusSaga, resp: ", resp)
    if (!resp.data) throw new Error("Unknown error")
    yield put(tradesActions.patchTradesStatusAction.success(resp.data))
    toast.success("Успешно")
    yield put(tradesActions.getTradesUserAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      tradesActions.patchTradesStatusAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* putTradesSaga({ payload }: TPutTradesCallAction) {
  try {
    const resp: TPutTradesResponse = yield call([tradesProvider, tradesProvider.putTrades], {
      data: payload,
    })
    if (!resp.data) throw new Error("Unknown error")
    yield put(tradesActions.putTradesAction.success(resp.data))
    toast.success("Успешно")
    yield put(tradesActions.getTradesUserAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      tradesActions.putTradesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* deleteTradesSaga({ payload }: TDeleteTradesCallAction) {
  try {
    const resp: TDeleteTradesResponse = yield call([tradesProvider, tradesProvider.deleteTrades], {
      params: payload,
    })
    if (!resp.data) throw new Error("Unknown error")
    yield put(tradesActions.deleteTradesAction.success(resp.data))
    toast.success("Успешно")
    yield put(tradesActions.getTradesUserAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      tradesActions.deleteTradesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* tradesWatcher() {
  yield takeLatest(tradesActions.getTradesAction.call.type, getTradesSaga)
  yield takeLatest(tradesActions.getTradesUserAction.call.type, getTradesUserSaga)
  yield takeLatest(tradesActions.postTradesAction.call.type, postTradesSaga)
  yield takeLatest(tradesActions.patchTradesStatusAction.call.type, patchTradesStatusSaga)
  yield takeLatest(tradesActions.putTradesAction.call.type, putTradesSaga)
  yield takeLatest(tradesActions.deleteTradesAction.call.type, deleteTradesSaga)
}
