import { modulesRoutes } from "./modulesRoutes"

const getTraderRoutes = (baseURL: string): any => {
  const url = baseURL !== "" ? `${baseURL}` : ""
  return {
    root: `${url}`,
    title: "Trader",
    management: {
      root: `${url}/management`,
      title: "Management",
    },
  }
}

export const traderModuleRoutes: any = getTraderRoutes(modulesRoutes.trader.root)
