import React, { useEffect } from "react"
// import { Footer, Header, Sidebar } from "Base/tsx/containers"
import { BaseLayout } from "Base/tsx/layouts"
import { useTranslation } from "react-i18next"
import { adminRoutes } from "Base/constants/routes/adminRoutes"
import { UsersIcon } from "Base/tsx/components/icons/UsersIcon"
import { RolesIcon } from "Base/tsx/components/icons/RolesIcon"
import * as rolesActions from "Base/store/user/roles/actions"
import * as financialAssetTypesActions from "Base/store/trade/financialAssetTypes/actions"
import { useDispatch } from "react-redux"
import { FinancialAssetTypesIcon } from "Base/tsx/components/icons/FinancialAssetTypesIcon"
import { FinancialAssetsIcon } from "Base/tsx/components/icons/FinancialAssetsIcon"
import { Platforms } from "Admin/tsx/pages/Platforms"
import { PlatformsIcon } from "Base/tsx/components/icons/PlatformsIcon"

export function AdminLayout({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch()
  useEffect(() => {
    console.log("getAdminRouters, useEffect")
    dispatch(rolesActions.getRolesAction.call())
    dispatch(financialAssetTypesActions.getFinancialAssetTypesAction.call())
  }, [])
  const { t } = useTranslation()

  return <>{children}</>
}
