import React from "react"
import { NavLink, NavLinkProps } from "react-router-dom"
import styles from "./CustomLink.module.scss"
import cn from "classnames"

type CustomLinkProps = NavLinkProps & {
  label: string | React.ReactNode
  className?: string
}

export const CustomLink: React.FC<CustomLinkProps> = ({ label, className, ...props }) => {
  return (
    <NavLink
      {...props}
      className={({ isActive }) => cn(styles.link, { [styles.active]: isActive }, className)}
    >
      {label}
    </NavLink>
  )
}
