import React from "react"
import ReactDOM from "react-dom"
import styles from "./Modal.module.scss"

interface ModalProps {
  title: string
  visible: boolean
  children: React.ReactNode
  onClose: () => void
}

export const Modal: React.FC<ModalProps> = ({ title, visible, children, onClose }) => {
  if (!visible) return null

  const modalContent = (
    <div className={styles.modal_overlay} onClick={onClose}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modal_header}>
          <h2>{title}</h2>
        </div>
        <div className={styles.modal_body}>{children}</div>
      </div>
    </div>
  )

  return ReactDOM.createPortal(modalContent, document.getElementById("modal-root") as HTMLElement)
}
