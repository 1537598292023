import { Button, DropdownButton, Table } from "Base/tsx/components"
import { Column, Row } from "react-table"
import styles from "./FinancialAssets.module.scss"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "Starter/store/configureStore"
import { AdminLayout } from "Admin/tsx/layouts/AdminLayout"
import { PageHeader } from "Base/tsx/containers/PageHeader"
import { TFinancialAsset } from "Base/api/rest/types/trade/financialAssets"
import * as financialAssetsActions from "Base/store/trade/financialAssets/actions"
import { FinancialAssetFormModal } from "Admin/tsx/modals/financialAssets/FinancialAssetFormModal"
import { DeleteFinancialAssetFormModal } from "Admin/tsx/modals/financialAssets/DeleteFinancialAssetFormModal"
import { getIconFinancialAssetsURL, getIconPlatformURL } from "Base/utils/url"
import { PageContainer } from "Base/tsx/containers/PageContainer"
import { TableWindow } from "Base/tsx/components/other/TableWindow"
import { EditingPenIcon } from "Base/tsx/components/icons/EditingPenIcon"

type TData = TFinancialAsset

export const FinancialAssets = () => {
  const dispatch = useDispatch()
  const { getFinancialAssets } = useSelector((state: RootState) => state.base.financialAssets)
  // const { openSidebar } = useSidebar()
  const [isModalVisibleFinancialAssets, setIsModalVisibleFinancialAssets] = useState(false)
  const [isModalVisibleDeleteFinancialAssets, setIsModalVisibleDeleteFinancialAssets] =
    useState(false)
  const [financialAssetChange, setFinancialAssetsChange] = useState<TFinancialAsset | undefined>()

  useEffect(() => {
    dispatch(financialAssetsActions.getFinancialAssetsAction.call())
  }, [])

  const columns: Column<TData>[] = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        width: 38,
      },
      {
        Header: "Financial Asset",
        width: 200,
        Cell: ({ row }: { row: Row<TData> }) => {
          const { name, symbol, id } = row.original
          return (
            <div className={styles.item_icon}>
              <img src={getIconFinancialAssetsURL(id)} alt='' height={20} width={20} />
              <span>
                {symbol} ({name})
              </span>
            </div>
          )
        },
      },
      {
        Header: "Symbol",
        accessor: "symbol",
        width: 200,
      },

      {
        Header: "Name",
        accessor: "name",
        width: 200,
      },
      {
        Header: "Desc",
        accessor: "desc",
        width: 500,
      },
      {
        id: "actions",
        Header: "Actions",
        sticky: "right",
        width: 76,
        Cell: ({ row }: { row: Row<TData> }) => {
          return (
            <div className={styles.actions}>
              <DropdownButton
                options={[
                  {
                    label: "edit",
                    onClick: () => {
                      setFinancialAssetsChange(row.original)
                      setIsModalVisibleFinancialAssets(true)
                    },
                  },
                  {
                    label: "delete",
                    onClick: () => {
                      setFinancialAssetsChange(row.original)
                      setIsModalVisibleDeleteFinancialAssets(true)
                    },
                  },
                ]}
              >
                <EditingPenIcon />
              </DropdownButton>
            </div>
          )
        },
      },
    ],
    [],
  )

  const tableData: TData[] = useMemo(
    () => getFinancialAssets?.data || [],
    [getFinancialAssets?.data],
  )
  console.log("getFinancialAssets: ", getFinancialAssets)
  console.log("tableData: ", tableData)
  return (
    <PageContainer>
      <PageHeader>
        <Button onClick={() => setIsModalVisibleFinancialAssets(true)}>
          Create Financial Asset
        </Button>
      </PageHeader>
      <div className={styles.content}>
        <TableWindow<TData>
          fixedSizeListClassName={styles.fixed_size_list}
          columns={columns}
          data={tableData}
          update={() => undefined}
          hasNextPage={false}
          totalItems={1000}
        />
      </div>
      <DeleteFinancialAssetFormModal
        isModalVisible={isModalVisibleDeleteFinancialAssets}
        setIsModalVisible={(value) => {
          setFinancialAssetsChange(undefined)
          setIsModalVisibleDeleteFinancialAssets(value)
        }}
        financialAssetChange={financialAssetChange}
      />
      <FinancialAssetFormModal
        isModalVisible={isModalVisibleFinancialAssets}
        setIsModalVisible={(value) => {
          setFinancialAssetsChange(undefined)
          setIsModalVisibleFinancialAssets(value)
        }}
        financialAssetChange={financialAssetChange}
      />
    </PageContainer>
  )
}
