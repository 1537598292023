import { createReducer } from "@reduxjs/toolkit"

import { TWsExchangeRateFailureAction, TWsExchangeRateSuccessAction } from "./types"
import { TRequestInfoChildType } from "Base/types/store/reducer"
import { RequestInfoChildState } from "Base/constants/statesDefault"
import { binanceWsActions } from "Base/store/deprecated/binanceWs/actions"

export type BinanceStateType = {
  wsExchangeRate: TRequestInfoChildType
}

const initialState: BinanceStateType = {
  wsExchangeRate: {
    ...RequestInfoChildState,
  },
}

export const binanceWsReducer = createReducer(initialState, (builder) => {
  builder
    //wsExchangeRateAction
    .addCase(binanceWsActions.wsExchangeRateAction.call.type, (state) => {
      state.wsExchangeRate.isLoading = true
    })
    .addCase(
      binanceWsActions.wsExchangeRateAction.success.type,
      (state, action: TWsExchangeRateSuccessAction) => {
        state.wsExchangeRate.isLoading = false
      },
    )
    .addCase(
      binanceWsActions.wsExchangeRateAction.failure.type,
      (state, action: TWsExchangeRateFailureAction) => {
        state.wsExchangeRate.error = action.payload.error
        state.wsExchangeRate.isLoading = false
      },
    )
})
