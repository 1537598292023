import { createReducer } from "@reduxjs/toolkit"

import { TRequestInfoChildType } from "Base/types/store/reducer"
import { RequestInfoChildState } from "Base/constants/statesDefault"
import { tonapiActions } from "Base/store/deprecated/tonapi/actions"
import { TGetTonapiRatesFailureAction } from "Base/store/deprecated/tonapi/types"

export type BinanceStateType = {
  getRates: TRequestInfoChildType
}

const initialState: BinanceStateType = {
  getRates: {
    ...RequestInfoChildState,
  },
}

export const tonapiReducer = createReducer(initialState, (builder) => {
  builder
    //wsExchangeRateAction
    .addCase(tonapiActions.getRatesAction.call.type, (state) => {
      state.getRates.isLoading = true
    })
    .addCase(tonapiActions.getRatesAction.success.type, (state) => {
      state.getRates.isLoading = false
    })
    .addCase(
      tonapiActions.getRatesAction.failure.type,
      (state, action: TGetTonapiRatesFailureAction) => {
        state.getRates.error = action.payload.error
        state.getRates.isLoading = false
      },
    )
})
