export const getModulesRoutes = (baseURL: string) => {
  const url = baseURL !== "" ? `${baseURL}` : ""
  return {
    root: `${url}`,
    title: "Main",
    main: {
      root: `${url}/`,
      title: "MainModule",
    },
    trader: {
      root: `${url}/trader`,
      title: "Trader",
    },
    traderV2: {
      root: `${url}/traderV2`,
      title: "TraderV2",
    },
    landing: {
      root: `${url}/landing`,
      title: "Landing",
    },
    admin: {
      root: `${url}/admin`,
      title: "Admin",
    },
    auth: {
      root: `${url}/auth`,
      title: "Auth",
    },
    profile: {
      root: `${url}/profile`,
      title: "Profile",
    },
  }
}

export const modulesRoutes = getModulesRoutes("")
