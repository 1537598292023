import React, { FC } from "react"
import { handleDownload } from "Base/utils/file"

interface FileDownloadProps {
  data: any
}

export const FileDownload: FC<FileDownloadProps> = ({ data }) => {
  return (
    <div>
      <button onClick={() => handleDownload(data)}>Скачать JSON</button>
    </div>
  )
}
