import { baseCSF } from "Base/utils/store"
import {
  TGetTonapiRatesFailurePayload,
  TGetTonapiRatesReqPayload,
  TGetTonapiRatesSuccessPayload,
} from "Base/store/deprecated/tonapi/types"

const prefix = "tonapi"

const getRatesAction = baseCSF<
  TGetTonapiRatesReqPayload,
  TGetTonapiRatesSuccessPayload,
  TGetTonapiRatesFailurePayload,
  undefined
>(prefix, "get-rates")

export const tonapiActions = {
  getRatesAction,
}
