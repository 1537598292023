import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as usersActions from "Base/store/user/users/actions"
import { TRequestInfoChildType } from "Base/types/store/reducer"

import { TGetUsersFailureAction, TGetUsersSuccessAction } from "./types"
import { TGetUsersResponseData } from "Base/api/rest/types/user/users"

export type UsersStateType = {
  getUsers: {
    data?: TGetUsersResponseData
  } & TRequestInfoChildType
  // getUsersById: {
  //   data?: TGetUsersByIdData
  // } & TRequestInfoChildType
  // postUsers: {
  //   data?: TPostUsersData
  // } & TRequestInfoChildType
  // putUsers: {
  //   data?: TPutUsersData
  // } & TRequestInfoChildType
}

const initialState: UsersStateType = {
  getUsers: {
    ...RequestInfoChildState,
  },
  // getUsersById: {
  //   ...RequestInfoChildState,
  // },
  // postUsers: {
  //   ...RequestInfoChildState,
  // },
  // putUsers: {
  //   ...RequestInfoChildState,
  // },
}

export const usersReducer = createReducer(initialState, {
  [usersActions.getUsersAction.call.type]: (state) => ({
    ...state,
    getUsers: {
      ...state.getUsers,
      isLoading: true,
    },
  }),
  [usersActions.getUsersAction.success.type]: (state, action: TGetUsersSuccessAction) => ({
    ...state,
    getUsers: {
      ...state.getUsers,
      data: action.payload,
      isLoading: false,
    },
  }),
  [usersActions.getUsersAction.failure.type]: (state, action: TGetUsersFailureAction) => ({
    ...state,
    getUsers: {
      ...state.getUsers,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  // //------------------------------------------------------------------
  //
  // [usersActions.getUsersByIdAction.call.type]: (state) => ({
  //   ...state,
  //   getUsersById: {
  //     ...state.getUsersById,
  //     isLoading: true,
  //   },
  // }),
  // [usersActions.getUsersByIdAction.success.type]: (state, action) => ({
  //   ...state,
  //   getUsersById: {
  //     ...state.getUsersById,
  //     data: action.payload,
  //     isLoading: false,
  //   },
  // }),
  // [usersActions.getUsersByIdAction.failure.type]: (state, action: TGetUsersByIdFailureAction) => ({
  //   ...state,
  //   getUsersById: {
  //     ...state.getUsersById,
  //     error: action.payload.error,
  //     isLoading: false,
  //   },
  // }),
  // [usersActions.getUsersByIdAction.initial.type]: (state) => ({
  //   ...state,
  //   getUsersById: {
  //     data: undefined,
  //     error: undefined,
  //     isLoading: false,
  //   },
  // }),
  // //------------------------------------------------------------------
  //
  // //------------------------------------------------------------------
  //
  // [usersActions.postUsersAction.call.type]: (state) => ({
  //   ...state,
  //   postUsers: {
  //     ...state.postUsers,
  //     isLoading: true,
  //   },
  // }),
  // [usersActions.postUsersAction.success.type]: (state, action: TPostUsersSuccessAction) => ({
  //   ...state,
  //   postUsers: {
  //     ...state.postUsers,
  //     data: action.payload,
  //     isLoading: false,
  //   },
  // }),
  // [usersActions.postUsersAction.failure.type]: (state, action: TPostUsersFailureAction) => ({
  //   ...state,
  //   postUsers: {
  //     ...state.postUsers,
  //     error: action.payload.error,
  //     isLoading: false,
  //   },
  // }),
  // //------------------------------------------------------------------
  //
  // //------------------------------------------------------------------
  //
  // [usersActions.putUsersAction.call.type]: (state) => ({
  //   ...state,
  //   putUsers: {
  //     ...state.putUsers,
  //     isLoading: true,
  //   },
  // }),
  // [usersActions.putUsersAction.success.type]: (state, action: TPutUsersSuccessAction) => ({
  //   ...state,
  //   putUsers: {
  //     ...state.putUsers,
  //     data: action.payload,
  //     isLoading: false,
  //   },
  // }),
  // [usersActions.putUsersAction.failure.type]: (state, action: TPutUsersFailureAction) => ({
  //   ...state,
  //   putUsers: {
  //     ...state.putUsers,
  //     error: action.payload.error,
  //     isLoading: false,
  //   },
  // }),
  // //------------------------------------------------------------------
})
