import { baseCSF } from "Base/utils/store"

import {
  TGetProfileCallPayload,
  TGetProfileFailurePayload,
  TGetProfileSuccessPayload,
} from "./types"

const prefix = "profile"

export const getProfileAction = baseCSF<
  TGetProfileCallPayload,
  TGetProfileSuccessPayload,
  TGetProfileFailurePayload,
  undefined
>(prefix, "get")
