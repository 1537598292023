export const TransactionStatuses: {
  purchased: "purchased"
  sold: "sold"
  archive: "archive"
} = {
  purchased: "purchased",
  sold: "sold",
  archive: "archive",
}

export type TTransactionStatuses =
  | typeof TransactionStatuses.purchased
  | typeof TransactionStatuses.sold
  | typeof TransactionStatuses.archive

export type TTransactionBuy = {
  cryptocurrencyValue: number
  cryptocurrencyRate: number
  currencyValue: number
  dateOfPurchase: string
}

export type TTransactionSell = {
  currencyValue: number
  dateOfSale: string
}

export type TTransaction = {
  id: string
  cryptocurrencyName: string
  currencyName: string
  tradePlatformName: string
  status: TTransactionStatuses
  dateOfChange: string
  buy: TTransactionBuy
  sell?: TTransactionSell
}

export type TFullTransaction = {
  history?: Array<Omit<TTransaction, "id">>
} & TTransaction

//-------------------------------------------------
export type TGetTransactionsData = TFullTransaction[]
//-------------------------------------------------
