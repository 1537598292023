import React, { useEffect, useRef, useState } from "react"
import styles from "./AccordionMenu.module.scss"
import cn from "classnames"
import { NavLink } from "react-router-dom"

interface AccordionMenuProps {
  title: { label: string; icon: React.ReactNode }
  content: { label: string; icon: React.ReactNode; to: string }[]
  isSqueeze?: boolean
  labelGroupActive: string
}

export const AccordionMenu: React.FC<AccordionMenuProps> = ({
  title,
  content,
  isSqueeze,
  labelGroupActive,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [contentHeight, setContentHeight] = useState<string | number>("0")

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(isOpen ? contentRef.current.scrollHeight : 0)
    }
  }, [isOpen])

  const Icon: React.FC<{ icon: React.ReactNode }> = ({ icon }) => (
    <div
      className={cn(styles.icon, {
        [styles.icon__squeeze]: isSqueeze,
      })}
    >
      {icon}
    </div>
  )

  const Text: React.FC<{ label: string }> = ({ label }) => (
    <span
      className={cn(styles.text, {
        [styles.text__visible]: isSqueeze,
        [styles.text__hidden]: !isSqueeze,
      })}
    >
      {label}
    </span>
  )

  return (
    <div className={styles.accordion}>
      <div className={styles.accordionHeader} onClick={() => setIsOpen((prev) => !prev)}>
        <div
          className={cn(styles.item_group, {
            [styles.item_group__squeeze]: isSqueeze,
            [styles.item_group__active]: isOpen,
            [styles.background_label_group_active]: labelGroupActive === title.label,
          })}
        >
          <Icon icon={title.icon} />
          <Text label={title.label} />
          <div className={styles.arrow_wrap}>
            <div
              className={cn(styles.arrow, {
                [styles.arrow__open]: isOpen,
                [styles.arrow__active]: labelGroupActive === title.label,
              })}
            ></div>
          </div>
        </div>
      </div>
      <div
        className={styles.accordionContent}
        ref={contentRef}
        style={{ maxHeight: `${contentHeight}px` }}
      >
        {content.map(({ label, to, icon }) => (
          <NavLink
            key={to}
            to={to}
            className={({ isActive }) => {
              return cn(styles.item_menu, styles.item_menu, {
                [styles.item_menu__squeeze]: isSqueeze,
                [styles.item_menu__active]: isActive,
                [styles.background_label_group_active]: labelGroupActive === title.label,
              })
            }}
          >
            <Icon icon={icon} />
            <Text label={label} />
          </NavLink>
        ))}
      </div>
    </div>
  )
}
