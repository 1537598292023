import { Button, DropdownButton, Table } from "Base/tsx/components"
import { Column, Row } from "react-table"
import styles from "./FinancialAssetTypes.module.scss"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "Starter/store/configureStore"
import { AdminLayout } from "Admin/tsx/layouts/AdminLayout"
import { PageHeader } from "Base/tsx/containers/PageHeader"
import { FinancialAssetTypeFormModal } from "Admin/tsx/modals/financialAssetTypes/FinancialAssetTypeFormModal"
import { DeleteFinancialAssetTypeFormModal } from "Admin/tsx/modals/financialAssetTypes/DeleteFinancialAssetTypeFormModal"
import * as financialAssetTypesActions from "Base/store/trade/financialAssetTypes/actions"
import { TFinancialAssetType } from "Base/api/rest/types/trade/financialAssetTypes"
import { PageContainer } from "Base/tsx/containers/PageContainer"
import { EditingPenIcon } from "Base/tsx/components/icons/EditingPenIcon"
import { TableWindow } from "Base/tsx/components/other/TableWindow"

type TData = TFinancialAssetType

export const FinancialAssetTypes = () => {
  const dispatch = useDispatch()
  const getFinancialAssetTypes = useSelector(
    (state: RootState) => state.base.financialAssetTypes.getFinancialAssetTypes,
  )
  // const { openSidebar } = useSidebar()
  const [isModalVisibleFinancialAssetType, setIsModalVisibleFinancialAssetType] = useState(false)
  const [isModalVisibleDeleteFinancialAssetType, setIsModalVisibleDeleteFinancialAssetType] =
    useState(false)
  const [financialAssetTypeChange, setFinancialAssetTypeChange] = useState<
    TFinancialAssetType | undefined
  >()

  useEffect(() => {
    dispatch(financialAssetTypesActions.getFinancialAssetTypesAction.call())
  }, [])

  const columns: Column<TData>[] = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        width: 38,
      },
      {
        Header: "Value",
        accessor: "symbol",
        width: 200,
      },
      {
        Header: "Desc",
        accessor: "desc",
        width: 500,
      },
      {
        id: "actions",
        Header: "Actions",
        sticky: "right",
        width: 76,
        Cell: ({ row }: { row: Row<TData> }) => {
          return (
            <div className={styles.actions}>
              <DropdownButton
                options={[
                  {
                    label: "edit",
                    onClick: () => {
                      setFinancialAssetTypeChange(row.original)
                      setIsModalVisibleFinancialAssetType(true)
                    },
                  },
                  {
                    label: "delete",
                    onClick: () => {
                      setFinancialAssetTypeChange(row.original)
                      setIsModalVisibleDeleteFinancialAssetType(true)
                    },
                  },
                ]}
              >
                <EditingPenIcon />
              </DropdownButton>
            </div>
          )
        },
      },
    ],
    [],
  )

  const tableData: TData[] = useMemo(
    () => getFinancialAssetTypes?.data || [],
    [getFinancialAssetTypes?.data],
  )
  console.log("getFinancialAssetTypes: ", getFinancialAssetTypes)
  console.log("tableData: ", tableData)
  return (
    <PageContainer>
      <PageHeader>
        <Button onClick={() => setIsModalVisibleFinancialAssetType(true)}>
          Create Financial Asset Type
        </Button>
      </PageHeader>
      <div className={styles.content}>
        <TableWindow<TData>
          fixedSizeListClassName={styles.fixed_size_list}
          columns={columns}
          data={tableData}
          update={() => undefined}
          hasNextPage={false}
          totalItems={1000}
        />
      </div>
      <DeleteFinancialAssetTypeFormModal
        isModalVisible={isModalVisibleDeleteFinancialAssetType}
        setIsModalVisible={(value) => {
          setFinancialAssetTypeChange(undefined)
          setIsModalVisibleDeleteFinancialAssetType(value)
        }}
        financialAssetTypeChange={financialAssetTypeChange}
      />
      <FinancialAssetTypeFormModal
        isModalVisible={isModalVisibleFinancialAssetType}
        setIsModalVisible={(value) => {
          setFinancialAssetTypeChange(undefined)
          setIsModalVisibleFinancialAssetType(value)
        }}
        financialAssetTypeChange={financialAssetTypeChange}
      />
    </PageContainer>
  )
}
