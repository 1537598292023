import React from "react"
import { ThemeContext } from "Base/tsx/contexts"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "Starter/store/configureStore"
import { appSettingsActions } from "Base/store/user/appSettingsPersist/actions"

type ThemeProviderProps = {
  children: React.ReactNode
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const dispatch = useDispatch()
  const { theme } = useSelector((state: RootState) => state.base.appSettingsPersist.settings)
  const toggleTheme = () => {
    dispatch(
      appSettingsActions.setAppSettingsAction.call({ theme: theme === "light" ? "dark" : "light" }),
    )
  }

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>
}
