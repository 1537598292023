// api/UsersProvider.ts
import { ApiProviderBase } from "../../ApiProviderBase"
import {
  TGetUsersRequest,
  TGetUsersResponse,
  TGetUsersResponseData,
} from "Base/api/rest/types/user/users"

export class UsersProvider extends ApiProviderBase {
  async getUsers(params?: TGetUsersRequest): Promise<TGetUsersResponse> {
    return this.request<TGetUsersResponseData>({ url: "/users", method: "GET", params })
  }
}
