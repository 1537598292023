import * as userRolesActions from "Base/store/user/userRoles/actions"
import * as profileActions from "Base/store/user/profilePersist/actions"
import * as usersActions from "Base/store/user/users/actions"
import { takeLatest, call, put } from "redux-saga/effects"

import { TPostUserRolesReqAction, TPutUserRolesReqAction, TDeleteUserRolesReqAction } from "./types"
import { getErrorMessage } from "Base/utils/exaptations"
import { toast } from "react-toastify"
import { userRolesProvider } from "Base/api/rest/globalApiProviders"
import {
  TDeleteUserRolesResponse,
  TGetUserRolesResponse,
  TPostUserRolesResponse,
  TPutUserRolesResponse,
} from "Base/api/rest/types/user/userRoles"

export function* getUserRolesSaga() {
  try {
    const resp: TGetUserRolesResponse = yield call([
      userRolesProvider,
      userRolesProvider.getUserRoles,
    ])

    if (!resp.data) throw new Error("Unknown error")
    yield put(userRolesActions.getUserRolesAction.success(resp.data))
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      userRolesActions.getUserRolesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* postUserRolesSaga({ payload }: TPostUserRolesReqAction) {
  try {
    const resp: TPostUserRolesResponse = yield call(
      [userRolesProvider, userRolesProvider.postUserRoles],
      { data: payload },
    )
    if (!resp.success) throw new Error("Unknown error")
    yield put(userRolesActions.postUserRolesAction.success(resp.data))
    toast.success("Успешно")
    yield put(profileActions.getProfileAction.call())
    yield put(usersActions.getUsersAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      userRolesActions.postUserRolesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* putUserRolesSaga({ payload }: TPutUserRolesReqAction) {
  try {
    const resp: TPutUserRolesResponse = yield call(
      [userRolesProvider, userRolesProvider.putUserRoles],
      { data: payload },
    )
    if (!resp.success) throw new Error("Unknown error")
    yield put(userRolesActions.putUserRolesAction.success(resp.data))
    toast.success("Успешно")
    yield put(userRolesActions.getUserRolesAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      userRolesActions.putUserRolesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* deleteUserRolesSaga({ payload }: TDeleteUserRolesReqAction) {
  try {
    const resp: TDeleteUserRolesResponse = yield call(
      [userRolesProvider, userRolesProvider.deleteUserRoles],
      { params: payload },
    )
    if (!resp.success) throw new Error("Unknown error")
    yield put(userRolesActions.deleteUserRolesAction.success(resp.data))
    toast.success("Успешно")
    yield put(userRolesActions.getUserRolesAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      userRolesActions.deleteUserRolesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* userRolesWatcher() {
  yield takeLatest(userRolesActions.getUserRolesAction.call.type, getUserRolesSaga)
  yield takeLatest(userRolesActions.postUserRolesAction.call.type, postUserRolesSaga)
  yield takeLatest(userRolesActions.putUserRolesAction.call.type, putUserRolesSaga)
  yield takeLatest(userRolesActions.deleteUserRolesAction.call.type, deleteUserRolesSaga)
}
