// utils/axiosInterceptors.ts
import { store } from "Starter/store/configureStore"
import * as authPersistActions from "Base/store/user/authPersist/actions"
import { ErrorHandler } from "Base/api/rest/utils/ErrorHandler"
import { axiosInstance } from "Base/api/rest/utils/axiosInstance"

// Интерцептор запросов
axiosInstance.interceptors.request.use(
  (config) => {
    // Добавление токена авторизации в заголовки, если он доступен
    // const token = localStorage.getItem("authToken")
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`
    // }

    // Логирование исходящих запросов (опционально)
    console.log("Making request to ", config.url)

    return config
  },
  (error) => {
    // Обработка ошибок при формировании запроса
    console.error("Error in request interceptor: ", error)
    return Promise.reject(error)
  },
)

// Интерцептор ответов
axiosInstance.interceptors.response.use(
  (response) => {
    // Обработка успешного ответа
    return response
  },
  (error) => {
    // Использование ErrorHandler для логирования и обработки ошибок
    ErrorHandler.logError(error)
    ErrorHandler.handleError(error)

    // console.error("Error in response interceptor: ", error)
    return Promise.reject(error)
  },
)
