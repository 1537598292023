import { createReducer } from "@reduxjs/toolkit"
import { exchangeRateActions } from "Base/store/deprecated/exchangeRate/actions"
import {
  TExchangeRateBinance,
  TExchangeRateTonapi,
  TSetExchangeRateReqAction,
} from "Base/store/deprecated/exchangeRate/types"

export type ExchangeRateStateType = {
  providers: {
    binance: TExchangeRateBinance
    tonapi: TExchangeRateTonapi
  }
}

const initialState: ExchangeRateStateType = {
  providers: {
    binance: {},
    tonapi: {},
  },
}

export const exchangeRateReducer = createReducer(initialState, (builder) => {
  builder
    //setExchangeRate
    .addCase(
      exchangeRateActions.setExchangeRateAction.call.type,
      (state, action: TSetExchangeRateReqAction) => {
        state.providers = {
          binance: {
            ...state.providers.binance,
            ...action.payload?.binance,
          },
          tonapi: {
            ...state.providers.tonapi,
            ...action.payload?.tonapi,
          },
        }
      },
    )
})
