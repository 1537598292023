import { baseCSF } from "Base/utils/store"

import {
  TGetRolesFailurePayload,
  TGetRolesReqPayload,
  TGetRolesSuccessPayload,
  TPostRolesFailurePayload,
  TPostRolesReqPayload,
  TPostRolesSuccessPayload,
  TPutRolesSuccessPayload,
  TPutRolesFailurePayload,
  TPutRolesReqPayload,
  TDeleteRolesReqPayload,
  TDeleteRolesSuccessPayload,
  TDeleteRolesFailurePayload,
} from "./types"

const prefix = "roles"

export const getRolesAction = baseCSF<
  TGetRolesReqPayload,
  TGetRolesSuccessPayload,
  TGetRolesFailurePayload,
  undefined
>(prefix, "get")

export const postRolesAction = baseCSF<
  TPostRolesReqPayload,
  TPostRolesSuccessPayload,
  TPostRolesFailurePayload,
  undefined
>(prefix, "post")

export const putRolesAction = baseCSF<
  TPutRolesReqPayload,
  TPutRolesSuccessPayload,
  TPutRolesFailurePayload,
  undefined
>(prefix, "put")

export const deleteRolesAction = baseCSF<
  TDeleteRolesReqPayload,
  TDeleteRolesSuccessPayload,
  TDeleteRolesFailurePayload,
  undefined
>(prefix, "delete")
