import { useSelector } from "react-redux"
import { RootState } from "Starter/store/configureStore"
import { Table } from "Base/tsx/components"
import React, { useMemo, useState } from "react"
import styles from "./ExchangeRateTableWrapper.module.scss"

type TData = {
  [key: string]: {
    prices: { [key: string]: number }
    diff_24h: { [key: string]: string }
    diff_7d: { [key: string]: string }
    diff_30d: { [key: string]: string }
  }
}

const getColor = (value: string) => {
  if (value.startsWith("-")) {
    return styles.red
  } else {
    return styles.green
  }
}

const columns = [
  {
    Header: "Cryptocurrency name",
    accessor: "cryptocurrencyName",
  },
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: "24h Diff",
    accessor: "diff_24h",
    // Cell: ({ value }: { value: string }) => {
    //   return <span className={getColor(value)}>{value}</span>
    // },
  },
  {
    Header: "7d Diff",
    accessor: "diff_7d",
    // Cell: ({ value }: { value: string }) => {
    //   return <span className={getColor(value)}>{value}</span>
    // },
  },
  {
    Header: "30d Diff",
    accessor: "diff_30d",
    // Cell: ({ value }: { value: string }) => {
    //   return <span className={getColor(value)}>{value}</span>
    // },
  },
]

export const ExchangeRateTableWrapper = () => {
  const {
    exchangeRate: {
      providers: { tonapi },
    },
  } = useSelector((state: RootState) => state.base)
  // const [currentPage, setCurrentPage] = useState(0)
  // const handlePageChange = (newPage: number) => {
  //   setCurrentPage(newPage)
  // }
  // Преобразуем данные в формат, который может быть использован компонентом Table
  // @ts-ignore
  const tableData: TData[] = useMemo(
    () =>
      tonapi
        ? Object.keys(tonapi).map((key) => ({
            cryptocurrencyName: key,
            price: Object.keys(tonapi[key].prices).map(
              (pricesKey) => pricesKey + ": " + tonapi[key].prices[pricesKey].toFixed(4) + " | ",
            ),
            diff_24h: Object.keys(tonapi[key].diff_24h).map(
              (pricesKey) => pricesKey + ": " + tonapi[key].diff_24h[pricesKey] + " | ",
            ),
            diff_7d: Object.keys(tonapi[key].diff_7d).map(
              (pricesKey) => pricesKey + ": " + tonapi[key].diff_7d[pricesKey] + " | ",
            ),
            diff_30d: Object.keys(tonapi[key].diff_30d).map(
              (pricesKey) => pricesKey + ": " + tonapi[key].diff_30d[pricesKey] + " | ",
            ),
          }))
        : [],
    [tonapi],
  )

  return (
    <div className={styles.exchange_rate_table_wrapper}>
      <Table<TData>
        data={tableData}
        columns={columns}
        // currentPage={currentPage}
        // onPageChange={handlePageChange}
      />
    </div>
  )
}
