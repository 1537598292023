import { SagaIterator } from "redux-saga"
import { DBSchema, IDBPDatabase, IDBPTransaction, openDB } from "idb"
import { call } from "redux-saga/effects"
import { TExchangeInfoSymbol } from "Base/store/deprecated/binance/types"
import { TFullTransaction } from "Base/types/deprecated/trade/transactions"

export interface TDatabase extends DBSchema {
  exchangeInfoSymbols: {
    key: string
    value: TExchangeInfoSymbol
  }
  transactions: {
    key: string
    value: TFullTransaction
  }
}

export function* initDB(storeName: string, keyPath: string): SagaIterator<IDBPDatabase<TDatabase>> {
  return yield call(openDB, "MyDatabase", 2, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath })
      }
    },
  })
}

// Обобщенная функция для транзакций
export function* dbTransaction(
  storeName: "exchangeInfoSymbols" | "transactions",
  keyPath: string,
  mode: IDBTransactionMode,
  operation: (store: IDBObjectStore, ...args: any[]) => any,
  ...args: any[]
): SagaIterator {
  try {
    const db: IDBPDatabase<TDatabase> = yield call(initDB, storeName, keyPath)
    const tx = db.transaction(storeName, mode) as IDBPTransaction<
      TDatabase,
      ["exchangeInfoSymbols" | "transactions"]
    >
    const store = tx.objectStore(storeName)
    // @ts-ignore
    yield call(operation, store, ...args)
    // @ts-ignore
    yield tx.done
  } catch (error) {
    // Обработка ошибок
    console.error("DB transaction failed:", error)
  }
}
