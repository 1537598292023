// api/providers/TradesProvider.ts
import { ApiProviderBase } from "Base/api/rest/ApiProviderBase"
import {
  TDeleteTradesRequest,
  TDeleteTradesResponse,
  TDeleteTradesResponseData,
  TGetTradesRequest,
  TGetTradesResponse,
  TGetTradesResponseData,
  TGetTradesUserRequest,
  TGetTradesUserResponse,
  TGetTradesUserResponseData,
  TPatchTradesStatusRequest,
  TPatchTradesStatusResponse,
  TPatchTradesStatusResponseData,
  TPostTradesRequest,
  TPostTradesResponse,
  TPostTradesResponseData,
  TPutTradesRequest,
  TPutTradesResponse,
  TPutTradesResponseData,
} from "Base/api/rest/types/trade/trades"

export class TradesProvider extends ApiProviderBase {
  async getTrades(params: TGetTradesRequest): Promise<TGetTradesResponse> {
    return this.request<TGetTradesResponseData>({
      url: "/trades",
      method: "GET",
      params: params.params,
    })
  }

  async getTradesUser(params: TGetTradesUserRequest): Promise<TGetTradesUserResponse> {
    return this.request<TGetTradesUserResponseData>({
      url: "/trades/user",
      method: "GET",
      params: params.params,
    })
  }

  async postTrades(params: TPostTradesRequest): Promise<TPostTradesResponse> {
    return this.request<TPostTradesResponseData>({
      url: "/trades",
      method: "POST",
      data: params.data,
    })
  }

  async putTrades(params: TPutTradesRequest): Promise<TPutTradesResponse> {
    return this.request<TPutTradesResponseData>({
      url: "/trades",
      method: "PUT",
      data: params.data,
    })
  }

  async patchTradesStatus(params: TPatchTradesStatusRequest): Promise<TPatchTradesStatusResponse> {
    return this.request<TPatchTradesStatusResponseData>({
      url: "/trades/" + params.params?.id + "/status",
      method: "PATCH",
      data: params.data,
    })
  }

  async deleteTrades(params: TDeleteTradesRequest): Promise<TDeleteTradesResponse> {
    return this.request<TDeleteTradesResponseData>({
      url: "/trades/" + params.params?.id,
      method: "DELETE",
      data: params.data,
    })
  }
}
