import { modulesRoutes } from "./modulesRoutes"
import { Management } from "TraderV2/tsx/pages/Management"

const getTraderV2Routes = (baseURL: string) => {
  const url = baseURL !== "" ? `${baseURL}` : ""
  return {
    root: `${url}`,
    title: "Trader",
    "user-assets-control": {
      root: `${url}/user-assets-control`,
      title: "User Assets Control",
    },
    management: {
      root: `${url}/management`,
      title: "Management",
    },
  }
}

export const traderV2ModuleRoutes = getTraderV2Routes(modulesRoutes.traderV2.root)
