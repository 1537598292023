import { baseCSF } from "Base/utils/store"
import {
  TUpdateTransactionsInfoFailurePayload,
  TUpdateTransactionsInfoReqPayload,
  TUpdateTransactionsInfoSuccessPayload,
  TAddTransactionsInfoFailurePayload,
  TAddTransactionsInfoReqPayload,
  TAddTransactionsInfoSuccessPayload,
} from "Base/store/deprecated/transactionsInfo/types"

const prefix = "currencies"

const addTransactionsInfoAction = baseCSF<
  TAddTransactionsInfoReqPayload,
  TAddTransactionsInfoSuccessPayload,
  TAddTransactionsInfoFailurePayload,
  undefined
>(prefix, "update-transactions-info")

const updateTransactionsInfoAction = baseCSF<
  TUpdateTransactionsInfoReqPayload,
  TUpdateTransactionsInfoSuccessPayload,
  TUpdateTransactionsInfoFailurePayload,
  undefined
>(prefix, "update-profit")

export const transactionsInfoActions = {
  addTransactionsInfoAction,
  updateTransactionsInfoAction,
}
