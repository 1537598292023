import React from "react"
import styles from "./BaseLayout.module.scss"
import { Footer, Header, SidebarMenu } from "Base/tsx/containers"

export function BaseLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className={styles.layout}>
      <Header />
      <SidebarMenu />
      <div className={styles.main_and_footer_wrapper}>
        {children}
        <Footer />
      </div>
    </div>
  )
}
