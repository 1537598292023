import { baseCSF } from "Base/utils/store"

import {
  TGetTradesFailurePayload,
  TGetTradesCallPayload,
  TGetTradesSuccessPayload,
  TPostTradesFailurePayload,
  TPostTradesCallPayload,
  TPostTradesSuccessPayload,
  TPutTradesSuccessPayload,
  TPutTradesFailurePayload,
  TPutTradesCallPayload,
  TDeleteTradesCallPayload,
  TDeleteTradesSuccessPayload,
  TDeleteTradesFailurePayload,
  TGetTradesUserCallPayload,
  TGetTradesUserSuccessPayload,
  TGetTradesUserFailurePayload,
  TPatchTradesStatusFailurePayload,
  TPatchTradesStatusSuccessPayload,
  TPatchTradesStatusCallPayload,
} from "./types"

const prefix = "trades"

export const getTradesAction = baseCSF<
  TGetTradesCallPayload,
  TGetTradesSuccessPayload,
  TGetTradesFailurePayload,
  undefined
>(prefix, "get")

export const getTradesUserAction = baseCSF<
  TGetTradesUserCallPayload,
  TGetTradesUserSuccessPayload,
  TGetTradesUserFailurePayload,
  undefined
>(prefix, "get-user")

export const postTradesAction = baseCSF<
  TPostTradesCallPayload,
  TPostTradesSuccessPayload,
  TPostTradesFailurePayload,
  undefined
>(prefix, "post")

export const patchTradesStatusAction = baseCSF<
  TPatchTradesStatusCallPayload,
  TPatchTradesStatusSuccessPayload,
  TPatchTradesStatusFailurePayload,
  undefined
>(prefix, "patch-status")

export const putTradesAction = baseCSF<
  TPutTradesCallPayload,
  TPutTradesSuccessPayload,
  TPutTradesFailurePayload,
  undefined
>(prefix, "put")

export const deleteTradesAction = baseCSF<
  TDeleteTradesCallPayload,
  TDeleteTradesSuccessPayload,
  TDeleteTradesFailurePayload,
  undefined
>(prefix, "delete")
