import { SVGProps } from "react"

export function TopologyMapIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' {...props}>
      {/* Линии соединяющие узлы */}
      <path d='M6 8l4 8l4-8l4 8' stroke='currentColor' strokeWidth='1.5' fill='none' />

      {/* Узлы */}
      <circle cx='6' cy='8' r='2' fill='currentColor' />
      <circle cx='10' cy='16' r='2' fill='currentColor' />
      <circle cx='14' cy='8' r='2' fill='currentColor' />
      <circle cx='18' cy='16' r='2' fill='currentColor' />
    </svg>
  )
}
