import { DropdownButton, Table } from "Base/tsx/components"
import { Column, Row } from "react-table"
import styles from "./Users.module.scss"
import React, { useEffect, useMemo, useState } from "react"
import * as usersActions from "Base/store/user/users/actions"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "Starter/store/configureStore"
import { AdminLayout } from "Admin/tsx/layouts/AdminLayout"
import { UserEditFormModal } from "Admin/tsx/modals/UserEditFormModal"
import { TUser } from "Base/api/rest/types/user/users"
import { PageContainer } from "Base/tsx/containers/PageContainer"
import { TableWindow } from "Base/tsx/components/other/TableWindow"
import { EditingPenIcon } from "Base/tsx/components/icons/EditingPenIcon"

type TData = TUser

export const Users = () => {
  const dispatch = useDispatch()
  const { getUsers } = useSelector((state: RootState) => state.base.users)
  // const { openSidebar } = useSidebar()
  const [isModalVisibleUserEdit, setIsModalVisibleRoleUserEdit] = useState(false)
  const [userChange, setUserChange] = useState<TUser | undefined>()
  useEffect(() => {
    dispatch(usersActions.getUsersAction.call())
  }, [])

  const columns: Column<TData>[] = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        width: 38,
      },
      {
        Header: "Email",
        accessor: "email",
        width: 300,
      },
      {
        Header: "Given Name",
        width: 200,
        Cell: ({ row }: { row: Row<TData> }) => {
          return <span>{row.original.userProfile?.givenName}</span>
        },
        // accessor: "givenName",
      },
      {
        Header: "Family Name",
        width: 200,
        Cell: ({ row }: { row: Row<TData> }) => {
          return <span>{row.original.userProfile?.familyName}</span>
        },
        // accessor: "familyName",
      },
      {
        Header: "Role",
        width: 200,
        Cell: ({ row }: { row: Row<TData> }) => {
          return (
            <span>
              {row.original.userRoles?.length && row.original.userRoles?.[0].role.roleName}
            </span>
          )
        },
      },
      // {
      //   Header: " ",
      //   width: 200,
      //   Cell: ({ row }: { row: Row<TData> }) => {
      //     return (
      //       <DropdownButton
      //         options={[
      //           {
      //             label: "edit",
      //             onClick: () => {
      //               setUserChange(row.original)
      //               setIsModalVisibleRoleUserEdit(true)
      //             },
      //           },
      //         ]}
      //       >
      //         select
      //       </DropdownButton>
      //     )
      //   },
      // },
      {
        id: "actions",
        Header: "Actions",
        sticky: "right",
        width: 76,
        Cell: ({ row }: { row: Row<TData> }) => {
          return (
            <div className={styles.actions}>
              <DropdownButton
                options={[
                  {
                    label: "edit",
                    onClick: () => {
                      setUserChange(row.original)
                      setIsModalVisibleRoleUserEdit(true)
                    },
                  },
                ]}
              >
                <EditingPenIcon />
              </DropdownButton>
            </div>
          )
        },
      },
    ],
    [],
  )

  const tableData: TData[] = useMemo(() => getUsers?.data || [], [getUsers?.data])
  // console.log("getUsers: ", getUsers)
  // console.log("tableData: ", tableData)
  return (
    <PageContainer>
      <div className={styles.content}>
        <TableWindow<TData>
          fixedSizeListClassName={styles.fixed_size_list}
          columns={columns}
          data={tableData}
          update={() => undefined}
          hasNextPage={false}
          totalItems={1000}
        />
      </div>
      {userChange && (
        <UserEditFormModal
          isModalVisible={isModalVisibleUserEdit}
          setIsModalVisible={(value) => {
            setUserChange(undefined)
            setIsModalVisibleRoleUserEdit(value)
          }}
          userChange={userChange}
        />
      )}
    </PageContainer>
  )
}
