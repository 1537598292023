// api/FinancialAssetRatesProvider.ts
import { ApiProviderBase } from "../../ApiProviderBase"
import {
  TGetFinancialAssetRatesResponse,
  TGetFinancialAssetRatesResponseData,
} from "Base/api/rest/types/trade/financialAssetRates"

export class FinancialAssetRatesProvider extends ApiProviderBase {
  async getFinancialAssetRates(): Promise<TGetFinancialAssetRatesResponse> {
    return this.request<TGetFinancialAssetRatesResponseData>({
      url: "/financial-asset-rates",
      method: "GET",
    })
  }
}
