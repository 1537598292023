import React from "react"
// import { Footer, Header, Sidebar } from "Base/tsx/containers"
import { BaseLayout } from "Base/tsx/layouts"
// import { modulesRoutes, servicesModuleRoutes, traderModuleRoutes } from "Base/constants/routes"
// import { ServicesListIcon } from "Base/assets/icons/ServicesListIcon"
// import { InvestmentsIcon } from "Base/assets/icons/InvestmentsIcon"
import { useTranslation } from "react-i18next"

// import { HomeIcon } from "Base/assets/icons/HomeIcon"

export function DefaultLayout({ children }: { children: React.ReactNode }) {
  // const { t } = useTranslation()
  // const menuLinks = [
  //   // { to: modulesRoutes.main.root, label: t("t_header_nav_bar_home"), icon: <HomeIcon /> },
  //   {
  //     to: servicesModuleRoutes.personal.root,
  //     label: t("t_header_nav_bar_services"),
  //     icon: <ServicesListIcon />,
  //   },
  //   {
  //     to: traderModuleRoutes.management.root,
  //     label: t("t_header_nav_bar_trader"),
  //     icon: <InvestmentsIcon />,
  //   },
  // ]
  return (
    // <BaseLayout header={<Header />} sidebar={<Sidebar menuLinks={menuLinks} />} footer={<Footer />}>
    <BaseLayout>{children}</BaseLayout>
  )
}
