import React, { CSSProperties } from "react"
import Select, { components, OptionProps, SingleValueProps } from "react-select"
import { Controller, RegisterOptions, useFormContext } from "react-hook-form"
import styles from "./SelectCustom.module.scss"
import { InputWrapper } from "Base/tsx/components/inputs/InputWrapper"
import cn from "classnames"

export interface TOption<T> {
  value: T
  disable?: boolean
  label: string
  icon?: string
}

export interface TOptions<T> {
  label: string
  options: TOption<T>[]
}

const ComponentsOption = ({ icon, label }: { icon?: string; label: string }) => (
  <div className={styles.option}>
    {icon && <img src={icon} alt='' height={20} width={20} />}
    {label}
  </div>
)

interface SelectWrapperProps<T> {
  name: string
  label?: string
  disabled?: boolean
  options: TOption<T>[] | TOptions<T>[]
  required?: boolean
  isMulti?: boolean
  hideSelectedOptions?: boolean
  defaultValue?: TOption<T> | TOption<T>[]
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void
  onChange?: (value: TOption<T> | readonly TOption<T>[] | null) => void
  inputWrapperStyle?: CSSProperties
}

interface SelectWrapperProps<T> {
  name: string
  label?: string
  disabled?: boolean
  backgroundOn?: boolean
  options: TOption<T>[] | TOptions<T>[]
  required?: boolean
  isMulti?: boolean
  hideSelectedOptions?: boolean
  defaultValue?: TOption<T> | TOption<T>[]
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void
  onChange?: (value: TOption<T> | readonly TOption<T>[] | null) => void
  inputWrapperStyle?: CSSProperties
}

export const SelectCustom = <T,>({
  name,
  label,
  options,
  required,
  isMulti,
  hideSelectedOptions,
  defaultValue,
  onBlur,
  onChange,
  inputWrapperStyle,
  disabled,
  backgroundOn = true,
}: SelectWrapperProps<T> & { isMulti?: boolean }) => {
  const Option = (props: OptionProps<TOption<T>, false>) => {
    return (
      <components.Option {...props}>
        <ComponentsOption icon={props.data.icon} label={props.data.label} />
      </components.Option>
    )
  }
  // Компонент для отображения выбранного значения
  const SingleValue = (props: SingleValueProps<TOption<T>>) => {
    return (
      <components.SingleValue {...props}>
        <ComponentsOption icon={props.data.icon} label={props.data.label} />
      </components.SingleValue>
    )
  }

  const {
    control,
    formState: { errors },
  } = useFormContext()

  const selectStyles = {
    input: (base: any) => ({
      ...base,
      color: "var(--input-color)",
      // width: "100px",
    }),
    control: (base: any) => ({
      ...base,
      backgroundColor: backgroundOn ? "var(--input-background-color)" : 0,
      color: "var(--input-color)",
      borderColor: "var(--input-border-color)",
      minWidth: "max-content",
      height: "var(--input-border-color-hover)",
      "&:hover": {
        borderColor: "var(--input-border-color-hover)",
      },
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    menu: (base: any) => ({
      ...base,
      // backgroundColor: backgroundOn ? "var(--input-background-color)" : 0,
      backgroundColor: "var(--input-background-color)",
      color: "var(--input-color)",
    }),
    option: (base: any, state: any) => ({
      ...base,
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      backgroundColor: state.isDisabled
        ? "var(--option-background-color-disable)"
        : state.isFocused
        ? "var(--option-background-color-hover)"
        : "var(--option-background-color)",
      color: state.isDisabled
        ? "var(--option-color-disabled)"
        : state.isFocused
        ? "var(--option-color-hover)"
        : "var(--input-color)",
      "&:hover": {
        backgroundColor: state.isDisabled
          ? "var(--option-background-color-disable)"
          : "var(--option-background-color-hover)",
        color: state.isDisabled ? "var(--option-color-disabled)" : "var(--option-color-hover)",
      },
    }),
    singleValue: (base: any) => ({
      ...base,
      // backgroundColor: backgroundOn ? "var(--selected-input-background-color)" : 0,
      color: "var(--selected-input-text-color)",
    }),
    valueContainer: (base: any) => ({
      ...base,
      width: "min-content",
    }),
  }

  const rules: RegisterOptions = {
    required: required ? "Это поле обязательно" : undefined,
  }

  const getOptionDisabled = (option: TOption<T>): boolean => {
    console.log("getOptionDisabled, option: ", option)
    return !!option.disable
  }

  return (
    <InputWrapper
      style={inputWrapperStyle}
      label={label}
      name={name}
      required={required}
      errors={errors}
      disabled={disabled}
    >
      <Controller
        name={name}
        control={control}
        rules={rules}
        // @ts-ignore
        disabled={disabled}
        render={({ field, fieldState }) => (
          <>
            <Select
              {...field}
              menuPortalTarget={document.body} // Рендеринг выпадающего списка в body
              styles={selectStyles}
              hideSelectedOptions={hideSelectedOptions}
              defaultValue={defaultValue}
              options={options}
              isMulti={isMulti}
              isSearchable
              components={{ Option, SingleValue }}
              placeholder='Select...'
              onChange={(value) => {
                field.onChange(value)
                onChange && onChange(value)
              }}
              onBlur={(event) => {
                console.log("event: ", event)
                field.onBlur()
                onBlur && onBlur(event)
              }}
              className={cn({ [styles.error]: fieldState.invalid })}
              isOptionDisabled={getOptionDisabled}
            />
          </>
        )}
      />
    </InputWrapper>
  )
}
