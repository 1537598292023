// api/utils/ErrorHandler.ts
import { TApiResponseError } from "Base/api/rest/types/api"
import { store } from "Starter/store/configureStore"
import * as authPersistActions from "Base/store/user/authPersist/actions"
import { toast } from "react-toastify"
import { AxiosError } from "axios"

export class ErrorHandler {
  static handleError(error: AxiosError): void {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      store.dispatch(authPersistActions.getAuthLogoutAction.call())
    }
    toast.error(error?.message)
    // Логика обработки ошибок
    console.log("handleError, error: ", error)
  }

  static logError(error: TApiResponseError): void {
    // Логика логирования ошибок
    console.log("logError, error: ", error)
  }
}
