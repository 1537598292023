import * as financialAssetsActions from "Base/store/trade/financialAssets/actions"
import { takeLatest, call, put } from "redux-saga/effects"

import {
  TPostFinancialAssetsReqAction,
  TPutFinancialAssetsReqAction,
  TDeleteFinancialAssetsReqAction,
} from "./types"
import { getErrorMessage } from "Base/utils/exaptations"
import { toast } from "react-toastify"

import { financialAssetsProvider } from "Base/api/rest/globalApiProviders"
import {
  TDeleteFinancialAssetsResponse,
  TGetFinancialAssetsResponse,
  TPostFinancialAssetsResponse,
  TPutFinancialAssetsResponse,
} from "Base/api/rest/types/trade/financialAssets"

export function* getFinancialAssetsSaga() {
  try {
    const resp: TGetFinancialAssetsResponse = yield call([
      financialAssetsProvider,
      financialAssetsProvider.getFinancialAssets,
    ])

    if (!resp.data) throw new Error("Unknown error")
    yield put(financialAssetsActions.getFinancialAssetsAction.success(resp.data))
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      financialAssetsActions.getFinancialAssetsAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* postFinancialAssetsSaga({ payload }: TPostFinancialAssetsReqAction) {
  try {
    console.log("postFinancialAssetsSaga, payload: ", payload)
    const resp: TPostFinancialAssetsResponse = yield call(
      [financialAssetsProvider, financialAssetsProvider.postFinancialAssets],
      {
        data: payload,
      },
    )
    if (!resp.data) throw new Error("Unknown error")
    yield put(financialAssetsActions.postFinancialAssetsAction.success(resp.data))
    toast.success("Успешно")
    yield put(financialAssetsActions.getFinancialAssetsAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      financialAssetsActions.postFinancialAssetsAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* putFinancialAssetsSaga({ payload }: TPutFinancialAssetsReqAction) {
  try {
    const resp: TPutFinancialAssetsResponse = yield call(
      [financialAssetsProvider, financialAssetsProvider.putFinancialAssets],
      {
        data: payload,
      },
    )
    if (!resp.data) throw new Error("Unknown error")
    yield put(financialAssetsActions.putFinancialAssetsAction.success(resp.data))
    toast.success("Успешно")
    yield put(financialAssetsActions.getFinancialAssetsAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      financialAssetsActions.putFinancialAssetsAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* deleteFinancialAssetsSaga({ payload }: TDeleteFinancialAssetsReqAction) {
  try {
    const resp: TDeleteFinancialAssetsResponse = yield call(
      [financialAssetsProvider, financialAssetsProvider.deleteFinancialAssets],
      {
        params: payload,
      },
    )
    if (!resp.data) throw new Error("Unknown error")
    yield put(financialAssetsActions.deleteFinancialAssetsAction.success(resp.data))
    toast.success("Успешно")
    yield put(financialAssetsActions.getFinancialAssetsAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      financialAssetsActions.deleteFinancialAssetsAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* financialAssetsWatcher() {
  yield takeLatest(
    financialAssetsActions.getFinancialAssetsAction.call.type,
    getFinancialAssetsSaga,
  )
  yield takeLatest(
    financialAssetsActions.postFinancialAssetsAction.call.type,
    postFinancialAssetsSaga,
  )
  yield takeLatest(
    financialAssetsActions.putFinancialAssetsAction.call.type,
    putFinancialAssetsSaga,
  )
  yield takeLatest(
    financialAssetsActions.deleteFinancialAssetsAction.call.type,
    deleteFinancialAssetsSaga,
  )
}
