import React from "react"
import { useFormContext, Controller, RegisterOptions } from "react-hook-form"
import styles from "./InputDate.module.scss"
import { InputWrapper } from "Base/tsx/components/inputs/InputWrapper"

interface InputDateProps {
  name: string
  label: string
  required?: boolean
  disabled?: boolean
  min?: string
  max?: string
  type?: "date" | "datetime-local" // добавлен новый параметр для выбора времени
}

export const InputDate: React.FC<InputDateProps> = ({
  name,
  label,
  required = false,
  min,
  max,
  type = "date",
  disabled,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const rules: RegisterOptions = {}

  if (required) {
    rules.required = "Это поле обязательно"
  }

  return (
    <InputWrapper label={label} name={name} required={required} errors={errors}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <input
            disabled={disabled}
            className={styles.input_date}
            type={type}
            min={min}
            max={max}
            {...field}
          />
        )}
      />
    </InputWrapper>
  )
}
