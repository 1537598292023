import { SVGProps } from "react"

export function ReviewIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill='currentColor'
      width='16'
      height='12'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path d='M3,9A2,2,0,1,0,5,7,2,2,0,0,0,3,9ZM6,9A1,1,0,1,1,5,8,1,1,0,0,1,6,9Zm1.5,3h-5A2.5,2.5,0,0,0,0,14.5,1.5,1.5,0,0,0,1.5,16h7A1.5,1.5,0,0,0,10,14.5,2.5,2.5,0,0,0,7.5,12Zm1,3h-7a.5.5,0,0,1-.5-.5A1.5,1.5,0,0,1,2.5,13h5A1.5,1.5,0,0,1,9,14.5.5.5,0,0,1,8.5,15ZM16,.5v8a.5.5,0,0,1-.5.5H10v1h2.5a.5.5,0,0,1,0,1h-4a.5.5,0,0,1,0-1H9V9H8.5a.5.5,0,0,1,0-1H15V1H4V5.5a.5.5,0,0,1-1,0V.5A.5.5,0,0,1,3.5,0h12A.5.5,0,0,1,16,.5ZM6.5,6a.47.47,0,0,1-.35-.15.48.48,0,0,1,0-.7l2-2a.48.48,0,0,1,.7,0L10.5,4.79l2.65-2.64a.49.49,0,0,1,.7.7l-3,3a.48.48,0,0,1-.7,0L8.5,4.21,6.85,5.85A.47.47,0,0,1,6.5,6Z' />
        </g>
      </g>
    </svg>
  )
}
