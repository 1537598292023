import { Button, Modal } from "Base/tsx/components"
import styles from "./DeleteRoleFormModal.module.scss"
import React from "react"
import { useDispatch } from "react-redux"
import * as rolesActions from "Base/store/user/roles/actions"
import { TRole } from "Base/api/rest/types/user/roles"

export const DeleteRoleFormModal = ({
  roleChange,
  isModalVisible,
  setIsModalVisible,
}: {
  roleChange?: TRole
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
}) => {
  const dispatch = useDispatch()

  return (
    <Modal
      title='Role removal confirmation'
      visible={isModalVisible}
      onClose={() => setIsModalVisible(false)}
    >
      <h3>Are you sure you want to delete the role?</h3>
      <div className={styles.main}>
        <Button
          onClick={() => {
            roleChange && dispatch(rolesActions.deleteRolesAction.call(roleChange))
            setIsModalVisible(false)
          }}
        >
          Delete
        </Button>
        <Button
          theme='secondary'
          onClick={() => {
            setIsModalVisible(false)
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}
