import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"

export const useScrollHide = (value: boolean) => {
  const [isHidden, setIsHidden] = useState(value)
  const [lastScrollY, setLastScrollY] = useState<number | undefined>()

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      const headerHeight: number =
        Number(document.documentElement.style.getPropertyValue("--header-height").slice(0, -2)) || 0
      if (lastScrollY !== undefined) {
        if (currentScrollY + 10 < headerHeight) {
          setIsHidden(false)
        } else if (currentScrollY - 4 > lastScrollY && !isHidden) {
          // пользователь прокручивает вниз
          setIsHidden(true)
        } else if (currentScrollY + 10 < lastScrollY && isHidden) {
          // пользователь прокручивает вверх
          setIsHidden(false)
        }
      }
      setLastScrollY(currentScrollY)
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [lastScrollY, isHidden])

  const location = useLocation()

  useEffect(() => {
    setIsHidden(false)
    setLastScrollY(undefined)
  }, [location.pathname])

  return isHidden
}
