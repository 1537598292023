import { modulesRoutes } from "./modulesRoutes"

const getAuthRoutes = (baseURL: string) => {
  const url = baseURL !== "" ? `${baseURL}` : ""
  return {
    root: `${url}`,
    title: "Auth",
    auth: {
      root: `${url}`,
      title: "Auth",
      login: {
        root: `${url}/login`,
        title: "Вход",
      },
    },
  }
}

export const authModuleRoutes = getAuthRoutes(modulesRoutes.auth.root)
