import { baseCSF } from "Base/utils/store"

import {
  TGetPlatformsFailurePayload,
  TGetPlatformsReqPayload,
  TGetPlatformsSuccessPayload,
  TPostPlatformsFailurePayload,
  TPostPlatformsReqPayload,
  TPostPlatformsSuccessPayload,
  TPutPlatformsSuccessPayload,
  TPutPlatformsFailurePayload,
  TPutPlatformsReqPayload,
  TDeletePlatformsSuccessPayload,
  TDeletePlatformsFailurePayload,
  TDeletePlatformsReqPayload,
} from "./types"

const prefix = "platforms"

export const getPlatformsAction = baseCSF<
  TGetPlatformsReqPayload,
  TGetPlatformsSuccessPayload,
  TGetPlatformsFailurePayload,
  undefined
>(prefix, "get")

export const postPlatformsAction = baseCSF<
  TPostPlatformsReqPayload,
  TPostPlatformsSuccessPayload,
  TPostPlatformsFailurePayload,
  undefined
>(prefix, "post")

export const putPlatformsAction = baseCSF<
  TPutPlatformsReqPayload,
  TPutPlatformsSuccessPayload,
  TPutPlatformsFailurePayload,
  undefined
>(prefix, "put")

export const deletePlatformsAction = baseCSF<
  TDeletePlatformsReqPayload,
  TDeletePlatformsSuccessPayload,
  TDeletePlatformsFailurePayload,
  undefined
>(prefix, "delete")
