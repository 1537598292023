import * as financialAssetRatesActions from "Base/store/trade/financialAssetRates/actions"
import { takeLatest, call, put } from "redux-saga/effects"

import { getErrorMessage } from "Base/utils/exaptations"
import { toast } from "react-toastify"
import { financialAssetRatesProvider, userAssetsProvider } from "Base/api/rest/globalApiProviders"
import { TGetFinancialAssetRatesResponse } from "Base/api/rest/types/trade/financialAssetRates"

export function* getFinancialAssetRatesSaga() {
  try {
    const resp: TGetFinancialAssetRatesResponse = yield call([
      financialAssetRatesProvider,
      financialAssetRatesProvider.getFinancialAssetRates,
    ])
    if (!resp.data) throw new Error("Unknown error")
    yield put(financialAssetRatesActions.getFinancialAssetRatesAction.success(resp.data))
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      financialAssetRatesActions.getFinancialAssetRatesAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* financialAssetRatesWatcher() {
  yield takeLatest(
    financialAssetRatesActions.getFinancialAssetRatesAction.call.type,
    getFinancialAssetRatesSaga,
  )
}
