import BTC from "./BTC.png"
import ETH from "./ETH.png"
import TON from "./TON.png"
import USDT from "./USDT.png"
import LTC from "./LTC.png"
import XRP from "./XRP.png"

export const cryptoIcons = {
  BTC,
  ETH,
  TON,
  USDT,
  XRP,
  LTC,
}
