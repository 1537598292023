import { Button, Modal, SelectCustom } from "Base/tsx/components"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import styles from "./UserEditFormModal.module.scss"
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as userRolesActions from "Base/store/user/userRoles/actions"
import { InputWrapper } from "Base/tsx/components/inputs/InputWrapper"
import { TOption } from "Base/tsx/components/inputs/SelectCustom"
import { RootState } from "Starter/store/configureStore"
import { TUser } from "Base/api/rest/types/user/users"
import { TRole } from "Base/api/rest/types/user/roles"

interface IFormInput {
  role: TOption<TRole>
}

export const UserEditFormModal = ({
  userChange,
  isModalVisible,
  setIsModalVisible,
}: {
  userChange: TUser
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
}) => {
  const dispatch = useDispatch()
  const { getRoles } = useSelector((state: RootState) => state.base.roles)
  // const [optionsTradePlatformsNames, setOptionsTradePlatformsNames] = useState<TOption[]>([])

  // useEffect(() => {
  //   console.log("tradePlatformsNames: ", tradePlatformsNames)
  //   setOptionsTradePlatformsNames([
  //     ...Object.keys(tradePlatformsNames).map((key) => {
  //       return {
  //         label: key,
  //         value: key,
  //       }
  //     }),
  //   ])
  // }, [tradePlatformsNames])

  const methods = useForm<IFormInput>()
  const { handleSubmit, reset, setValue, watch } = methods

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    // dispatch(usersActions.putUsersAction.call({ ...userChange }))
    dispatch(
      userRolesActions.postUserRolesAction.call({
        role: data.role.value,
        user: userChange,
      }),
    )
    setIsModalVisible(false)
  }

  const roleOptions: Array<TOption<TRole>> = useMemo(() => {
    return getRoles.data
      ? getRoles.data.map((role) => ({
          value: role,
          label: role.roleName,
        }))
      : []
  }, [getRoles.data])

  useEffect(() => {
    if (!userChange.userRoles?.length) return
    const roleOption = roleOptions.filter((option) => {
      // console.log("useEffect, filter, option: ", option)
      return userChange.userRoles[0].role.id === option.value.id
    })[0]
    // console.log("useEffect, roleOption: ", roleOption)
    // console.log("useEffect, userChange: ", userChange)
    reset({
      ...userChange,
      role: roleOption,
    })
  }, [userChange, roleOptions, reset])

  return (
    <Modal title='Adding role' visible={isModalVisible} onClose={() => setIsModalVisible(false)}>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.block}>
            {userChange && (
              <>
                <InputWrapper label='Id' name='id'>
                  <span className={styles.text}>{userChange.id}</span>
                </InputWrapper>
                <InputWrapper label='Email' name='email'>
                  <span className={styles.text}>{userChange.email}</span>
                </InputWrapper>
              </>
            )}
            {userChange.userRoles.length ? (
              <>
                {userChange.userRoles.map((userRole) => (
                  <SelectCustom
                    key={userRole.id}
                    label='Роль'
                    name='role'
                    required
                    options={roleOptions}
                  />
                ))}
              </>
            ) : (
              <SelectCustom label='Роль' name='role' required options={roleOptions} />
            )}
          </div>
          <div className={styles.buttons}>
            <Button type='submit'>Save</Button>
            <Button
              theme='secondary'
              onClick={() => {
                // reset(defaultValues)
                setIsModalVisible(false)
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}
