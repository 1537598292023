import { combineReducers } from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import { transactionsReducer } from "./deprecated/transactions/reducer"
import { binanceReducer } from "Base/store/deprecated/binance/reducer"
import { exchangeRateReducer } from "Base/store/deprecated/exchangeRate/reducer"
import { tonapiReducer } from "Base/store/deprecated/tonapi/reducer"
import { transactionsInfoReducer } from "Base/store/deprecated/transactionsInfo/reducer"
import { binanceWsReducer } from "Base/store/deprecated/binanceWs/reducer"
import { appSettingsPersistReducer } from "Base/store/user/appSettingsPersist/reducer"
import { authPersistReducer } from "Base/store/user/authPersist/reducer"
import { profilePersistReducer } from "Base/store/user/profilePersist/reducer"
import { rolesReducer } from "Base/store/user/roles/reducer"
import { userRolesReducer } from "Base/store/user/userRoles/reducer"
import { usersReducer } from "Base/store/user/users/reducer"
import { platformsReducer } from "Base/store/trade/platforms/reducer"
import { financialAssetTypesReducer } from "Base/store/trade/financialAssetTypes/reducer"
import { financialAssetsReducer } from "Base/store/trade/financialAssets/reducer"
import { tradesReducer } from "Base/store/trade/trades/reducer"
import { marketOrdersReducer } from "Base/store/trade/marketOrders/reducer"
import { financialAssetRatesReducer } from "Base/store/trade/financialAssetRates/reducer"
import { userAssetsReducer } from "Base/store/user/userAssets/reducer"

const currenciesPersistConfig = {
  key: "transactions-info-key",
  storage,
  whitelist: [
    "tradePlatformsNames",
    "currenciesNames",
    "cryptocurrenciesNames",
    "profit",
    "currenciesTurnover",
    "cryptocurrenciesTurnover",
  ],
}

const appSettingsPersistConfig = {
  key: "app-settings-key",
  storage,
  whitelist: ["settings"],
}

const profilePersistConfig = {
  key: "profile-key",
  storage,
  whitelist: ["profile"],
}

export const baseRootReducer = combineReducers({
  transactionsInfo: persistReducer(currenciesPersistConfig, transactionsInfoReducer),
  appSettingsPersist: persistReducer(appSettingsPersistConfig, appSettingsPersistReducer),
  transactions: transactionsReducer,
  binance: binanceReducer,
  binanceWs: binanceWsReducer,
  exchangeRate: exchangeRateReducer,
  tonapi: tonapiReducer,
  authPersist: authPersistReducer,
  profilePersist: persistReducer(profilePersistConfig, profilePersistReducer),
  users: usersReducer,
  roles: rolesReducer,
  userRoles: userRolesReducer,
  platforms: platformsReducer,
  financialAssetTypes: financialAssetTypesReducer,
  financialAssets: financialAssetsReducer,
  trades: tradesReducer,
  marketOrders: marketOrdersReducer,
  financialAssetRates: financialAssetRatesReducer,
  userAssets: userAssetsReducer,
})
