// types/trades.ts
import { TApiRequest, TApiResponse } from "Base/api/rest/types/api"
import { TUser } from "Base/api/rest/types/user/users"
import { TPlatform } from "Base/api/rest/types/trade/platforms"
import { TFinancialAsset } from "Base/api/rest/types/trade/financialAssets"
import { TMarketOrder } from "Base/api/rest/types/trade/marketOrders"

export enum TTradeStatus {
  PURCHASED = "purchased",
  SOLD = "sold",
  ARCHIVED = "archived",
  DELETED = "deleted",
}

export type TTrade = {
  id: number
  status: TTradeStatus
  rate: number
  baseFinancialAssetRemainingQuantity: number
  quoteFinancialAssetQuantity: number
  baseFinancialAssetQuantity: number
  quoteFinancialAsset: TFinancialAsset
  baseFinancialAsset: TFinancialAsset
  user: TUser
  marketOrders: TMarketOrder[]
  purchaseDate: Date
  createdAt: Date
  updatedAt: Date
  platform: TPlatform
}

//-------------------------------------------------
export type TGetTradesRequestParams = { status?: string | null } | undefined
export type TGetTradesRequest = TApiRequest<undefined, TGetTradesRequestParams>
export type TGetTradesResponseData = TTrade[]
export type TGetTradesResponse = TApiResponse<TGetTradesResponseData>
//-------------------------------------------------
//-------------------------------------------------
export type TGetTradesUserRequestParams = { status?: string | null } | undefined
export type TGetTradesUserRequest = TApiRequest<undefined, TGetTradesUserRequestParams>
export type TGetTradesUserResponseData = TTrade[]
export type TGetTradesUserResponse = TApiResponse<TGetTradesUserResponseData>
//-------------------------------------------------
//-------------------------------------------------
export type TPostTradesRequestData = {
  quoteFinancialAssetQuantity: number
  baseFinancialAssetQuantity: number
  quoteFinancialAsset: TFinancialAsset
  baseFinancialAsset: TFinancialAsset
  purchaseDate: Date | string
  platform: TPlatform
}
export type TPostTradesRequest = TApiRequest<TPostTradesRequestData, undefined>
export type TPostTradesResponseData = any
export type TPostTradesResponse = TApiResponse<TPostTradesResponseData>
//-------------------------------------------------

//-------------------------------------------------
export type TPutTradesRequestData = TTrade
export type TPutTradesRequest = TApiRequest<TPutTradesRequestData, undefined>
export type TPutTradesResponseData = any
export type TPutTradesResponse = TApiResponse<TPutTradesResponseData>
//-------------------------------------------------

//-------------------------------------------------
export type TPatchTradesStatusRequestParams = {
  id: number
}
export type TPatchTradesStatusRequestData = {
  status: string
}
export type TPatchTradesStatusRequest = TApiRequest<
  TPatchTradesStatusRequestData,
  TPatchTradesStatusRequestParams
>
export type TPatchTradesStatusResponseData = any
export type TPatchTradesStatusResponse = TApiResponse<TPatchTradesStatusResponseData>
//-------------------------------------------------

//-------------------------------------------------
export type TDeleteTradesRequestParams = {
  id: number
}
export type TDeleteTradesRequestData = {
  status: string
}
export type TDeleteTradesRequest = TApiRequest<TDeleteTradesRequestData, TDeleteTradesRequestParams>
export type TDeleteTradesResponseData = any
export type TDeleteTradesResponse = TApiResponse<TDeleteTradesResponseData>
//-------------------------------------------------
