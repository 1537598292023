import { takeLatest, put, select } from "redux-saga/effects"

import { TUpdateTransactionsInfoCallAction, TAddTransactionsInfoCallAction } from "./types"
import { transactionsInfoActions } from "Base/store/deprecated/transactionsInfo/actions"
import { RootState } from "Starter/store/configureStore"
import { add } from "Base/utils/formulas"
import { calculateProfitMinus, calculateProfitPlus } from "Base/utils/profit"
import {
  calculateCurrenciesTurnoverMinus,
  calculateCurrenciesTurnoverPlus,
} from "Base/utils/currenciesTurnover"
import {
  calculateCryptocurrencyTurnoverMinus,
  calculateCryptocurrencyTurnoverPlus,
} from "Base/utils/cryptocurrenciesTurnover"

export function* addTransactionsInfoSaga({ payload }: TAddTransactionsInfoCallAction) {
  try {
    const {
      cryptocurrenciesNames,
      currenciesNames,
      tradePlatformsNames,
      profit,
      currenciesTurnover,
      cryptocurrenciesTurnover,
    } = yield select(({ base }: RootState) => base.transactionsInfo)
    let cryptocurrenciesNamesNew = {
      ...cryptocurrenciesNames,
    }
    let currenciesNamesNew = {
      ...currenciesNames,
    }
    let tradePlatformsNamesNew = {
      ...tradePlatformsNames,
    }
    let currenciesTurnoverNew = {
      ...currenciesTurnover,
    }
    let cryptocurrenciesTurnoverNew = {
      ...cryptocurrenciesTurnover,
    }
    let profitNew = {
      ...profit,
    }

    payload.transactions?.forEach((transaction) => {
      cryptocurrenciesNamesNew = {
        ...cryptocurrenciesNamesNew,
        [transaction.cryptocurrencyName]: transaction.cryptocurrencyName,
      }
      currenciesNamesNew = {
        ...currenciesNamesNew,
        [transaction.currencyName]: transaction.currencyName,
      }
      tradePlatformsNamesNew = {
        ...tradePlatformsNamesNew,
        [transaction.tradePlatformName.trim()]: transaction.tradePlatformName.trim(),
      }

      currenciesTurnoverNew = calculateCurrenciesTurnoverPlus(currenciesTurnoverNew, transaction)
      cryptocurrenciesTurnoverNew = calculateCryptocurrencyTurnoverPlus(
        cryptocurrenciesTurnoverNew,
        transaction,
      )

      if (transaction.sell) {
        profitNew = calculateProfitPlus(profitNew, transaction)
      }
    })
    yield put(
      transactionsInfoActions.addTransactionsInfoAction.success({
        cryptocurrenciesNames: cryptocurrenciesNamesNew,
        currenciesNames: currenciesNamesNew,
        tradePlatformsNames: tradePlatformsNamesNew,
        profit: profitNew,
        currenciesTurnover: currenciesTurnoverNew,
        cryptocurrenciesTurnover: cryptocurrenciesTurnoverNew,
      }),
    )
  } catch (e: unknown) {
    yield put(
      transactionsInfoActions.addTransactionsInfoAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* updateTransactionsInfoSagaSaga({
  payload: { transactions },
}: TUpdateTransactionsInfoCallAction) {
  try {
    const {
      cryptocurrenciesNames,
      currenciesNames,
      tradePlatformsNames,
      profit,
      currenciesTurnover,
      cryptocurrenciesTurnover,
    } = yield select(({ base }: RootState) => base.transactionsInfo)

    let currenciesTurnoverNew = {
      ...currenciesTurnover,
    }
    let cryptocurrenciesTurnoverNew = {
      ...cryptocurrenciesTurnover,
    }
    let profitNew = {
      ...profit,
    }

    transactions.forEach(({ transactionOld, transactionUpdated }) => {
      if (!transactionUpdated) {
        profitNew = calculateProfitMinus(profitNew, transactionOld)
        currenciesTurnoverNew = calculateCurrenciesTurnoverMinus(
          currenciesTurnoverNew,
          transactionOld,
        )
        cryptocurrenciesTurnoverNew = calculateCryptocurrencyTurnoverMinus(
          cryptocurrenciesTurnoverNew,
          transactionOld,
        )
        return
      }
      if (transactionOld?.sell?.currencyValue !== transactionUpdated.sell?.currencyValue) {
        if (transactionOld?.sell?.currencyValue) {
          profitNew = calculateProfitMinus(profitNew, transactionOld)
          currenciesTurnoverNew = calculateCurrenciesTurnoverMinus(
            currenciesTurnoverNew,
            transactionOld,
          )
          cryptocurrenciesTurnoverNew = calculateCryptocurrencyTurnoverMinus(
            cryptocurrenciesTurnoverNew,
            transactionOld,
          )
        }
        if (transactionUpdated?.sell?.currencyValue) {
          profitNew = calculateProfitPlus(profitNew, transactionUpdated)
          currenciesTurnoverNew = calculateCurrenciesTurnoverPlus(
            currenciesTurnoverNew,
            transactionUpdated,
          )
          cryptocurrenciesTurnoverNew = calculateCryptocurrencyTurnoverPlus(
            cryptocurrenciesTurnoverNew,
            transactionUpdated,
          )
        }
      }
    })

    yield put(
      transactionsInfoActions.updateTransactionsInfoAction.success({
        cryptocurrenciesNames,
        currenciesNames,
        tradePlatformsNames,
        profit: profitNew,
        currenciesTurnover: currenciesTurnoverNew,
        cryptocurrenciesTurnover: cryptocurrenciesTurnoverNew,
      }),
    )
  } catch (e: unknown) {
    yield put(
      transactionsInfoActions.updateTransactionsInfoAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* transactionsInfoWatcher() {
  yield takeLatest(
    transactionsInfoActions.addTransactionsInfoAction.call.type,
    addTransactionsInfoSaga,
  )
  yield takeLatest(
    transactionsInfoActions.updateTransactionsInfoAction.call.type,
    updateTransactionsInfoSagaSaga,
  )
}
