import * as profileActions from "Base/store/user/profilePersist/actions"
import { takeLatest, put, call } from "redux-saga/effects"

import { getErrorMessage } from "Base/utils/exaptations"
import { toast } from "react-toastify"
import { profileProvider } from "Base/api/rest/globalApiProviders"
import { TGetProfileResponse } from "Base/api/rest/types/user/profile"

export function* getProfileSaga() {
  try {
    const resp: TGetProfileResponse = yield call([profileProvider, profileProvider.getProfile])
    console.log("getProfileSaga, resp: ", resp)
    if (!resp.success || !resp.data) throw new Error("Unknown error")
    yield put(profileActions.getProfileAction.success(resp.data))
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    console.log("getProfileSaga, message: ", message)
    yield put(
      profileActions.getProfileAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* profilePersistWatcher() {
  yield takeLatest(profileActions.getProfileAction.call.type, getProfileSaga)
}
