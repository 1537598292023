import { Table } from "Base/tsx/components/other/Table"
import { Column, Row } from "react-table"
import styles from "./UserAssets.module.scss"
import React, { useEffect, useMemo } from "react"
import { RootState } from "Starter/store/configureStore"
import { useDispatch, useSelector } from "react-redux"
import * as userAssetsActions from "Base/store/user/userAssets/actions"
import { TUserAsset } from "Base/api/rest/types/trade/userAssets"
import { getIconFinancialAssetsURL } from "Base/utils/url"
import Decimal from "decimal.js"
import cn from "classnames"
import { TableWindow } from "Base/tsx/components/other/TableWindow"

type TData = TUserAsset & { balance: string; balanceUSDT?: string }

export const UserAssets = () => {
  const dispatch = useDispatch()
  const { getUserAssets } = useSelector((state: RootState) => state.base.userAssets)
  const { getFinancialAssetRates } = useSelector(
    (state: RootState) => state.base.financialAssetRates,
  )

  useEffect(() => {
    dispatch(userAssetsActions.getUserAssetsAction.call())
  }, [])

  // @ts-ignore
  const tableData: TData[] = useMemo(() => {
    if (!getUserAssets?.data) return []
    // @ts-ignore
    return getUserAssets.data.map((userAssets) => {
      const { amountOfAssetsPurchased, amountOfAssetsSold } = userAssets
      const value = new Decimal(amountOfAssetsPurchased).minus(amountOfAssetsSold).toString()
      const financialAssetRate = getFinancialAssetRates.data?.find(
        // @ts-ignore
        (financialAssetRate) =>
          financialAssetRate.baseFinancialAsset.symbol === userAssets.financialAsset.symbol &&
          financialAssetRate.quoteFinancialAsset.symbol === "USDT",
      )
      const priceUSDT =
        financialAssetRate?.rate &&
        value &&
        new Decimal(value).mul(financialAssetRate?.rate).toFixed(2)

      return {
        ...userAssets,
        balance: value,
        balanceUSDT: priceUSDT,
      }
    })
  }, [getUserAssets.data, getFinancialAssetRates.data])

  const columns: Column<TData>[] = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        width: 100,
      },
      {
        Header: "Financial Asset",
        accessor: "financialAsset",
        width: 250,
        Cell: ({ row }) => {
          const { name, symbol, id } = row.original.financialAsset
          return (
            <div className={styles.item_icon}>
              <img src={getIconFinancialAssetsURL(id)} alt='' height={20} width={20} />
              <span>
                {symbol} ({name})
              </span>
            </div>
          )
        },
      },
      {
        Header: "Balance",
        width: 240,
        Cell: ({ row }: { row: Row<TData> }) => {
          return (
            <span
              className={cn({
                [styles.balance]: new Decimal(row.original.balance).gt(0),
              })}
            >
              {row.original.balance} ({row.original.balanceUSDT?.toString()} USDT)
            </span>
          )
        },
      },
      {
        Header: "Profit Amount",
        accessor: "profitAmount",
        width: 200,
        Cell: ({ row }: { row: Row<TData> }) => {
          const { profitAmount } = row.original
          return (
            <span
              className={cn({
                [styles.profit_amount__green]: new Decimal(profitAmount).gt(0),
                [styles.profit_amount__red]: new Decimal(profitAmount).lt(0),
              })}
            >
              {profitAmount}
            </span>
          )
        },
      },
      {
        Header: "Amount Of Assets Purchased",
        accessor: "amountOfAssetsPurchased",
        width: 200,
      },
      {
        Header: "Amount Of Assets Sold",
        accessor: "amountOfAssetsSold",
        width: 200,
      },
      {
        Header: "Number Of Asset Purchase Trade",
        accessor: "numberOfAssetPurchaseTrade",
        width: 200,
      },
      {
        Header: "Number Of Asset Sold Trade",
        accessor: "numberOfAssetSoldTrade",
        width: 200,
      },
      {
        Header: "Amount Purchased For The Asset",
        accessor: "amountPurchasedForTheAsset",
        width: 200,
      },
      {
        Header: "Amount Sold For The Asset",
        accessor: "amountSoldForTheAsset",
        width: 200,
      },
      {
        Header: "Number Of Purchase Trade Per Asset",
        accessor: "numberOfPurchaseTradePerAsset",
        width: 200,
      },
      {
        Header: "Number Of Sales Trade Per Asset",
        accessor: "numberOfSalesTradePerAsset",
        width: 200,
      },
    ],
    [],
  )

  return (
    <div className={styles.content}>
      <TableWindow<TData>
        fixedSizeListClassName={styles.fixed_size_list}
        columns={columns}
        data={tableData}
        update={() => undefined}
        hasNextPage={false}
        totalItems={1000}
      />
    </div>
  )
}
