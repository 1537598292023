import React, { useState, useRef, useEffect } from "react"
import styles from "./DropdownButton.module.scss"
import cn from "classnames"
import ReactDOM from "react-dom"

type TOption = { label: string | React.ReactNode; onClick?: () => void }

interface Props {
  options: Array<TOption>
  children?: React.ReactNode
}

export const DropdownButton: React.FC<Props> = ({ options, children }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
  })

  const updateDropdownPosition = () => {
    if (containerRef.current && dropdownRef.current) {
      const buttonRect = containerRef.current.getBoundingClientRect()
      const dropdownRect = dropdownRef.current.getBoundingClientRect()

      const position = {
        top: buttonRect.bottom + window.scrollY, // По умолчанию под кнопкой
        left: buttonRect.left + window.scrollX, // Выравниваем по левому краю кнопки
      }

      // Проверяем, помещается ли меню снизу под кнопкой
      const isEnoughSpaceBelow =
        buttonRect.bottom + dropdownRect.height <= window.innerHeight + window.scrollY

      if (!isEnoughSpaceBelow) {
        // Если снизу места недостаточно, размещаем меню сверху над кнопкой
        position.top = buttonRect.top + window.scrollY - dropdownRect.height
      }

      // Проверяем, не выходит ли меню за правую границу окна
      const isOverflowingRight =
        position.left + dropdownRect.width > window.innerWidth + window.scrollX

      if (isOverflowingRight) {
        // Сдвигаем меню влево так, чтобы оно поместилось
        position.left = window.innerWidth + window.scrollX - dropdownRect.width
      }

      // Проверяем, не выходит ли меню за левую границу окна
      if (position.left < window.scrollX) {
        position.left = window.scrollX
      }

      setDropdownPosition(position)
    }
  }

  useEffect(() => {
    if (showDropdown) {
      updateDropdownPosition()
      window.addEventListener("resize", updateDropdownPosition)
      window.addEventListener("scroll", updateDropdownPosition)
    } else {
      window.removeEventListener("resize", updateDropdownPosition)
      window.removeEventListener("scroll", updateDropdownPosition)
    }

    return () => {
      window.removeEventListener("resize", updateDropdownPosition)
      window.removeEventListener("scroll", updateDropdownPosition)
    }
  }, [showDropdown])

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false)
    }
  }

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [showDropdown])

  return (
    <div className={styles.dropdown_container} ref={containerRef}>
      <button className={styles.button} onClick={() => setShowDropdown(!showDropdown)}>
        {children}
      </button>
      {showDropdown &&
        ReactDOM.createPortal(
          <div
            ref={dropdownRef}
            className={cn(styles.dropdown_content)}
            style={{
              position: "absolute",
              top: dropdownPosition.top,
              left: dropdownPosition.left,
              zIndex: 1000,
            }}
          >
            {options.map((option, index) => (
              <button
                key={index}
                onClick={() => {
                  option.onClick && option.onClick()
                  setShowDropdown(false)
                }}
              >
                {option.label}
              </button>
            ))}
          </div>,
          document.getElementById("react-select-root")!,
        )}
    </div>
  )
}
