import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as tradesActions from "Base/store/trade/trades/actions"
import { TRequestInfoChildType } from "Base/types/store/reducer"

import {
  TGetTradesFailureAction,
  TGetTradesSuccessAction,
  TPostTradesFailureAction,
  TPostTradesSuccessAction,
  TPutTradesFailureAction,
  TPutTradesSuccessAction,
  TDeleteTradesSuccessAction,
  TDeleteTradesFailureAction,
  TGetTradesUserFailureAction,
  TPatchTradesStatusFailureAction,
  TPatchTradesStatusSuccessAction,
} from "./types"
import {
  TDeleteTradesResponseData,
  TGetTradesResponseData,
  TGetTradesUserResponseData,
  TPatchTradesStatusResponseData,
  TPostTradesResponseData,
  TPutTradesResponseData,
} from "Base/api/rest/types/trade/trades"

export type TradesStateType = {
  getTrades: {
    data?: TGetTradesResponseData
  } & TRequestInfoChildType
  getTradesUser: {
    data?: TGetTradesUserResponseData
  } & TRequestInfoChildType
  postTrades: {
    data?: TPostTradesResponseData
  } & TRequestInfoChildType
  patchTradesStatus: {
    data?: TPatchTradesStatusResponseData
  } & TRequestInfoChildType
  putTrades: {
    data?: TPutTradesResponseData
  } & TRequestInfoChildType
  deleteTrades: {
    data?: TDeleteTradesResponseData
  } & TRequestInfoChildType
}

const initialState: TradesStateType = {
  getTrades: {
    ...RequestInfoChildState,
  },
  getTradesUser: {
    ...RequestInfoChildState,
  },
  postTrades: {
    ...RequestInfoChildState,
  },
  patchTradesStatus: {
    ...RequestInfoChildState,
  },
  putTrades: {
    ...RequestInfoChildState,
  },
  deleteTrades: {
    ...RequestInfoChildState,
  },
}

export const tradesReducer = createReducer(initialState, {
  [tradesActions.getTradesAction.call.type]: (state) => ({
    ...state,
    getTrades: {
      ...state.getTrades,
      isLoading: true,
    },
  }),
  [tradesActions.getTradesAction.success.type]: (state, action: TGetTradesSuccessAction) => ({
    ...state,
    getTrades: {
      ...state.getTrades,
      data: action.payload,
      isLoading: false,
    },
  }),
  [tradesActions.getTradesAction.failure.type]: (state, action: TGetTradesFailureAction) => ({
    ...state,
    getTrades: {
      ...state.getTrades,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
  //------------------------------------------------------------------
  [tradesActions.getTradesUserAction.call.type]: (state) => ({
    ...state,
    getTradesUser: {
      ...state.getTradesUser,
      isLoading: true,
    },
  }),
  [tradesActions.getTradesUserAction.success.type]: (state, action) => ({
    ...state,
    getTradesUser: {
      ...state.getTradesUser,
      data: action.payload,
      isLoading: false,
    },
  }),
  [tradesActions.getTradesUserAction.failure.type]: (
    state,
    action: TGetTradesUserFailureAction,
  ) => ({
    ...state,
    getTradesUser: {
      ...state.getTradesUser,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [tradesActions.getTradesUserAction.initial.type]: (state) => ({
    ...state,
    getTradesUser: {
      data: undefined,
      error: undefined,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
  [tradesActions.postTradesAction.call.type]: (state) => ({
    ...state,
    postTrades: {
      ...state.postTrades,
      isLoading: true,
    },
  }),
  [tradesActions.postTradesAction.success.type]: (state, action: TPostTradesSuccessAction) => ({
    ...state,
    postTrades: {
      ...state.postTrades,
      data: action.payload,
      isLoading: false,
    },
  }),
  [tradesActions.postTradesAction.failure.type]: (state, action: TPostTradesFailureAction) => ({
    ...state,
    postTrades: {
      ...state.postTrades,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
  [tradesActions.patchTradesStatusAction.call.type]: (state) => ({
    ...state,
    patchTradesStatus: {
      ...state.patchTradesStatus,
      isLoading: true,
    },
  }),
  [tradesActions.patchTradesStatusAction.success.type]: (
    state,
    action: TPatchTradesStatusSuccessAction,
  ) => ({
    ...state,
    patchTradesStatus: {
      ...state.patchTradesStatus,
      data: action.payload,
      isLoading: false,
    },
  }),
  [tradesActions.patchTradesStatusAction.failure.type]: (
    state,
    action: TPatchTradesStatusFailureAction,
  ) => ({
    ...state,
    patchTradesStatus: {
      ...state.patchTradesStatus,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
  [tradesActions.putTradesAction.call.type]: (state) => ({
    ...state,
    putTrades: {
      ...state.putTrades,
      isLoading: true,
    },
  }),
  [tradesActions.putTradesAction.success.type]: (state, action: TPutTradesSuccessAction) => ({
    ...state,
    putTrades: {
      ...state.putTrades,
      data: action.payload,
      isLoading: false,
    },
  }),
  [tradesActions.putTradesAction.failure.type]: (state, action: TPutTradesFailureAction) => ({
    ...state,
    putTrades: {
      ...state.putTrades,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
  [tradesActions.deleteTradesAction.call.type]: (state) => ({
    ...state,
    deleteTrades: {
      ...state.deleteTrades,
      isLoading: true,
    },
  }),
  [tradesActions.deleteTradesAction.success.type]: (state, action: TDeleteTradesSuccessAction) => ({
    ...state,
    deleteTrades: {
      ...state.deleteTrades,
      data: action.payload,
      isLoading: false,
    },
  }),
  [tradesActions.deleteTradesAction.failure.type]: (state, action: TDeleteTradesFailureAction) => ({
    ...state,
    deleteTrades: {
      ...state.deleteTrades,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
})
