import React, { FC, ChangeEvent } from "react"

interface FileUploadProps {
  onFileLoaded: (data: any) => void
}

export const FileUpload: FC<FileUploadProps> = ({ onFileLoaded }) => {
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return

    const reader = new FileReader()

    reader.onload = (event) => {
      const target = event.target as FileReader
      try {
        const json = JSON.parse(target.result as string)
        onFileLoaded(json)
      } catch (error) {
        alert("Ошибка при чтении JSON файла")
      }
    }

    reader.readAsText(file)
  }

  return (
    <div>
      <input type='file' accept='.json' onChange={handleFileChange} />
    </div>
  )
}
