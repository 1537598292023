import { baseCSF } from "Base/utils/store"

import {
  TGetUserRolesFailurePayload,
  TGetUserRolesReqPayload,
  TGetUserRolesSuccessPayload,
  TPostUserRolesFailurePayload,
  TPostUserRolesReqPayload,
  TPostUserRolesSuccessPayload,
  TPutUserRolesSuccessPayload,
  TPutUserRolesFailurePayload,
  TPutUserRolesReqPayload,
  TDeleteUserRolesReqPayload,
  TDeleteUserRolesSuccessPayload,
  TDeleteUserRolesFailurePayload,
} from "./types"

const prefix = "user-roles"

export const getUserRolesAction = baseCSF<
  TGetUserRolesReqPayload,
  TGetUserRolesSuccessPayload,
  TGetUserRolesFailurePayload,
  undefined
>(prefix, "get")

export const postUserRolesAction = baseCSF<
  TPostUserRolesReqPayload,
  TPostUserRolesSuccessPayload,
  TPostUserRolesFailurePayload,
  undefined
>(prefix, "post")

export const putUserRolesAction = baseCSF<
  TPutUserRolesReqPayload,
  TPutUserRolesSuccessPayload,
  TPutUserRolesFailurePayload,
  undefined
>(prefix, "put")

export const deleteUserRolesAction = baseCSF<
  TDeleteUserRolesReqPayload,
  TDeleteUserRolesSuccessPayload,
  TDeleteUserRolesFailurePayload,
  undefined
>(prefix, "delete")
