import React, { useContext, useEffect } from "react"
import { BaseRouter } from "Starter/tsx/routes"
import { ThemeContext } from "Base/tsx/contexts"
import { useDispatch } from "react-redux"
import * as profileActions from "Base/store/user/profilePersist/actions"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export function App() {
  const dispatch = useDispatch()
  const { theme } = useContext(ThemeContext)

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme)
  }, [theme])

  useEffect(() => {
    dispatch(profileActions.getProfileAction.call())
  }, [])

  return (
    <>
      <BaseRouter />
      <ToastContainer />
    </>
  )
}
