import * as platformsActions from "Base/store/trade/platforms/actions"
import { takeLatest, call, put } from "redux-saga/effects"

import { TPostPlatformsReqAction, TPutPlatformsReqAction } from "./types"
import { getErrorMessage } from "Base/utils/exaptations"
import { toast } from "react-toastify"
import { platformsProvider } from "Base/api/rest/globalApiProviders"
import {
  TDeletePlatformsResponse,
  TGetPlatformsResponse,
  TPostPlatformsResponse,
  TPutPlatformsResponse,
} from "Base/api/rest/types/trade/platforms"

export function* getPlatformsSaga() {
  try {
    const resp: TGetPlatformsResponse = yield call([
      platformsProvider,
      platformsProvider.getPlatforms,
    ])
    console.log("getPlatformsSaga, resp: ", resp)

    if (!resp.success || !resp.data) throw new Error("Unknown error")
    yield put(platformsActions.getPlatformsAction.success(resp.data))
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      platformsActions.getPlatformsAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* postPlatformsSaga({ payload }: TPostPlatformsReqAction) {
  try {
    const resp: TPostPlatformsResponse = yield call(
      [platformsProvider, platformsProvider.postPlatforms],
      { data: payload },
    )
    console.log("getPlatformsSaga, resp: ", resp)

    if (!resp.success) throw new Error("Unknown error")
    yield put(platformsActions.postPlatformsAction.success())
    yield put(platformsActions.getPlatformsAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      platformsActions.postPlatformsAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* putPlatformsSaga({ payload }: TPutPlatformsReqAction) {
  try {
    const resp: TPutPlatformsResponse = yield call(
      [platformsProvider, platformsProvider.putPlatforms],
      { data: payload },
    )
    console.log("getPlatformsSaga, resp: ", resp)

    if (!resp.success) throw new Error("Unknown error")
    yield put(platformsActions.putPlatformsAction.success())
    yield put(platformsActions.getPlatformsAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    // toast.error(message)
    yield put(
      platformsActions.putPlatformsAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* deletePlatformsSaga({ payload }: TPutPlatformsReqAction) {
  try {
    const resp: TDeletePlatformsResponse = yield call(
      [platformsProvider, platformsProvider.deletePlatforms],
      { params: payload },
    )
    console.log("getPlatformsSaga, resp: ", resp)

    if (!resp.success) throw new Error("Unknown error")
    yield put(platformsActions.deletePlatformsAction.success())
    yield put(platformsActions.getPlatformsAction.call())
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    toast.error(message)
    yield put(
      platformsActions.deletePlatformsAction.failure({
        error: message,
      }),
    )
    console.log(e)
  }
}

export function* platformsWatcher() {
  yield takeLatest(platformsActions.getPlatformsAction.call.type, getPlatformsSaga)
  yield takeLatest(platformsActions.postPlatformsAction.call.type, postPlatformsSaga)
  yield takeLatest(platformsActions.putPlatformsAction.call.type, putPlatformsSaga)
  yield takeLatest(platformsActions.deletePlatformsAction.call.type, deletePlatformsSaga)
}
