import { Button, InputCustom, Modal } from "Base/tsx/components"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import styles from "./FinancialAssetTypeFormModal.module.scss"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import * as financialAssetTypesActions from "Base/store/trade/financialAssetTypes/actions"
import { TFinancialAssetType } from "Base/api/rest/types/trade/financialAssetTypes"

interface IFormInput {
  symbol: string
  desc: string
}

export const FinancialAssetTypeFormModal = ({
  financialAssetTypeChange,
  isModalVisible,
  setIsModalVisible,
}: {
  financialAssetTypeChange?: TFinancialAssetType
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
}) => {
  const dispatch = useDispatch()
  // const {
  //   transactionsInfo: { tradePlatformsNames },
  //   exchangeRate: {
  //     providers: { tonapi },
  //   },
  // } = useSelector((state: RootState) => state.base)
  // const [optionsTradePlatformsNames, setOptionsTradePlatformsNames] = useState<TOption[]>([])

  // useEffect(() => {
  //   console.log("tradePlatformsNames: ", tradePlatformsNames)
  //   setOptionsTradePlatformsNames([
  //     ...Object.keys(tradePlatformsNames).map((key) => {
  //       return {
  //         label: key,
  //         value: key,
  //       }
  //     }),
  //   ])
  // }, [tradePlatformsNames])

  const defaultValues = {
    value: "",
    desc: "",
  }

  const methods = useForm<IFormInput>({
    defaultValues,
  })
  const { handleSubmit, reset, setValue, watch } = methods

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (financialAssetTypeChange) {
      dispatch(
        financialAssetTypesActions.putFinancialAssetTypesAction.call({
          ...financialAssetTypeChange,
          ...data,
        }),
      )
    } else {
      dispatch(
        financialAssetTypesActions.postFinancialAssetTypesAction.call({
          symbol: data.symbol,
          desc: data.desc,
        }),
      )
    }
    reset(defaultValues)
    setIsModalVisible(false)
  }
  useEffect(() => {
    if (!financialAssetTypeChange) {
      reset(defaultValues)
      return
    }
    reset({
      ...financialAssetTypeChange,
    })
  }, [financialAssetTypeChange, reset])

  return (
    <Modal
      title='Adding financialAssetType'
      visible={isModalVisible}
      onClose={() => setIsModalVisible(false)}
    >
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.block}>
            <InputCustom name='symbol' label='FinancialAssetType value' required />
            <InputCustom name='desc' label='FinancialAssetType desc' required />
          </div>
          <div className={styles.buttons}>
            <Button type='submit'>Submit</Button>
            <Button
              theme='secondary'
              onClick={() => {
                reset(defaultValues)
                setIsModalVisible(false)
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}
