import { Button, InputCustom, Modal } from "Base/tsx/components"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import styles from "./RoleFormModal.module.scss"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import * as rolesActions from "Base/store/user/roles/actions"
import { InputWrapper } from "Base/tsx/components/inputs/InputWrapper"
import { TRole } from "Base/api/rest/types/user/roles"

interface IFormInput {
  roleName: string
}

export const RoleFormModal = ({
  roleChange,
  isModalVisible,
  setIsModalVisible,
}: {
  roleChange?: TRole
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
}) => {
  const dispatch = useDispatch()
  // const {
  //   transactionsInfo: { tradePlatformsNames },
  //   exchangeRate: {
  //     providers: { tonapi },
  //   },
  // } = useSelector((state: RootState) => state.base)
  // const [optionsTradePlatformsNames, setOptionsTradePlatformsNames] = useState<TOption[]>([])

  // useEffect(() => {
  //   console.log("tradePlatformsNames: ", tradePlatformsNames)
  //   setOptionsTradePlatformsNames([
  //     ...Object.keys(tradePlatformsNames).map((key) => {
  //       return {
  //         label: key,
  //         value: key,
  //       }
  //     }),
  //   ])
  // }, [tradePlatformsNames])

  const defaultValues = {
    roleName: "",
  }

  const methods = useForm<IFormInput>({
    defaultValues,
  })
  const { handleSubmit, reset, setValue, watch } = methods

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (roleChange) {
      // @ts-ignore
      dispatch(rolesActions.putRolesAction.call({ ...roleChange, ...data }))
    } else {
      // @ts-ignore
      dispatch(rolesActions.postRolesAction.call({ roleName: data.roleName }))
    }
    reset(defaultValues)
    setIsModalVisible(false)
  }
  useEffect(() => {
    if (!roleChange) {
      reset(defaultValues)
      return
    }
    reset({
      ...roleChange,
    })
  }, [roleChange, reset])

  return (
    <Modal title='Adding role' visible={isModalVisible} onClose={() => setIsModalVisible(false)}>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.block}>
            {roleChange && (
              <InputWrapper label='Id' name='id'>
                <span className={styles.text}>{roleChange.id}</span>
              </InputWrapper>
            )}
            <InputCustom name='roleName' label='Role name' required />
          </div>
          <div className={styles.buttons}>
            <Button type='submit'>Submit</Button>
            <Button
              theme='secondary'
              onClick={() => {
                reset(defaultValues)
                setIsModalVisible(false)
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}
