import styles from "./Header.module.scss"
import React, { useContext, useEffect, useRef } from "react"
import { Checkbox, DropdownButton, SelectCustom } from "Base/tsx/components"
import { ThemeContext } from "Base/tsx/contexts"
import { FormProvider, useForm } from "react-hook-form"
import { TOption } from "Base/tsx/components/inputs/SelectCustom"
import { useTranslation } from "react-i18next"
import { RootState } from "Starter/store/configureStore"
import { useDispatch, useSelector } from "react-redux"
import * as authPersistActions from "Base/store/user/authPersist/actions"
import { UserIcon } from "Base/tsx/components/icons/UserIcon"
import { useScrollHide } from "Base/tsx/hooks/useScrollHide"

interface IFormInput {
  language: TOption<string>
}

const languageOptions = [
  { label: "EN", value: "en" },
  { label: "RU", value: "ru" },
]

export const Header = () => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const { profile } = useSelector((state: RootState) => state.base.profilePersist)
  const methods = useForm<IFormInput>({ defaultValues: { language: languageOptions[0] } })
  const { watch } = methods
  const headerRef = useRef<HTMLDivElement>(null)
  const { theme, toggleTheme } = useContext(ThemeContext)
  const isHidden = useScrollHide(false)

  useEffect(() => {
    const handleResize = () => {
      const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0
      document.documentElement.style.setProperty("--header-height", `${headerHeight - 1}px`)
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      const headerHeight =
        document.documentElement.style.getPropertyValue("--header-height") || "0px"
      document.documentElement.style.setProperty(
        "--header-translateY",
        isHidden ? `calc(-1 * ${headerHeight} + (-1px))` : "0",
      )
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [isHidden])

  const language = watch("language")

  useEffect(() => {
    if (!language) return
    console.log("useEffect, language: ", language)
    i18n.changeLanguage(language.value)
  }, [language])

  return (
    <header ref={headerRef} className={styles.header_wrap}>
      <div className={styles.header}>
        <h3>Asset control system</h3>
        <div className={styles.control}>
          {/*<FormProvider {...methods}>*/}
          {/*  <SelectCustom*/}
          {/*    inputWrapperStyle={{ margin: 0 }}*/}
          {/*    hideSelectedOptions*/}
          {/*    onChange={onChangeLanguage}*/}
          {/*    name='language'*/}
          {/*    required*/}
          {/*    options={languageOptions}*/}
          {/*  />*/}
          {/*</FormProvider>*/}
          {/*<FormProvider {...methods}>*/}
          {/*  <SelectCustom<string>*/}
          {/*    inputWrapperStyle={{ margin: 0 }}*/}
          {/*    name='language'*/}
          {/*    hideSelectedOptions*/}
          {/*    options={languageOptions}*/}
          {/*  />*/}
          {/*</FormProvider>*/}|<h4 className={styles.profile}>{profile?.email}</h4>|
          <h5 className={styles.profile}>{theme} </h5>
          <Checkbox
            checked={theme === "light"}
            onChange={(event) => {
              console.log("onChange, event: ", event)
              toggleTheme()
            }}
            id='theme'
          />
          |
          <DropdownButton
            options={[
              {
                label: "Выход",
                onClick: () => {
                  dispatch(authPersistActions.getAuthLogoutAction.call())
                },
              },
            ]}
          >
            <UserIcon />
          </DropdownButton>
        </div>
      </div>
      <div className={styles.header_border} />
    </header>
  )
}
