import { baseCSF } from "Base/utils/store"

import {
  TGetMarketOrdersFailurePayload,
  TGetMarketOrdersReqPayload,
  TGetMarketOrdersSuccessPayload,
  TPostMarketOrdersFailurePayload,
  TPostMarketOrdersReqPayload,
  TPostMarketOrdersSuccessPayload,
  TPutMarketOrdersSuccessPayload,
  TPutMarketOrdersFailurePayload,
  TPutMarketOrdersReqPayload,
  TDeleteMarketOrdersReqPayload,
  TDeleteMarketOrdersSuccessPayload,
  TDeleteMarketOrdersFailurePayload,
  TGetMarketOrdersUserReqPayload,
  TGetMarketOrdersUserSuccessPayload,
  TGetMarketOrdersUserFailurePayload,
} from "./types"

const prefix = "market-orders"

export const getMarketOrdersAction = baseCSF<
  TGetMarketOrdersReqPayload,
  TGetMarketOrdersSuccessPayload,
  TGetMarketOrdersFailurePayload,
  undefined
>(prefix, "get")

export const getMarketOrdersUserAction = baseCSF<
  TGetMarketOrdersUserReqPayload,
  TGetMarketOrdersUserSuccessPayload,
  TGetMarketOrdersUserFailurePayload,
  undefined
>(prefix, "get-user")

export const postMarketOrdersAction = baseCSF<
  TPostMarketOrdersReqPayload,
  TPostMarketOrdersSuccessPayload,
  TPostMarketOrdersFailurePayload,
  undefined
>(prefix, "post")

export const putMarketOrdersAction = baseCSF<
  TPutMarketOrdersReqPayload,
  TPutMarketOrdersSuccessPayload,
  TPutMarketOrdersFailurePayload,
  undefined
>(prefix, "put")

export const deleteMarketOrdersAction = baseCSF<
  TDeleteMarketOrdersReqPayload,
  TDeleteMarketOrdersSuccessPayload,
  TDeleteMarketOrdersFailurePayload,
  undefined
>(prefix, "delete")
