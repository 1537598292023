// api/ApiProviderBase.ts
import { axiosInstance } from "./utils/axiosInstance"
import { TApiResponse } from "Base/api/rest/types/api"
import { AxiosRequestConfig } from "axios"

export abstract class ApiProviderBase {
  protected axiosInstance = axiosInstance

  protected async request<T>(config: AxiosRequestConfig): Promise<TApiResponse<T>> {
    try {
      const response = await this.axiosInstance(config)
      return { data: response.data, success: true }
    } catch (error: any) {
      return { success: false, error: { message: error.message, details: error.response?.data } }
    }
  }
}
