// api/providers/FinancialAssetTypesProvider.ts
import { ApiProviderBase } from "Base/api/rest/ApiProviderBase"
import {
  TDeleteFinancialAssetTypesRequest,
  TDeleteFinancialAssetTypesResponse,
  TDeleteFinancialAssetTypesResponseData,
  TGetFinancialAssetTypesResponse,
  TGetFinancialAssetTypesResponseData,
  TPostFinancialAssetTypesRequest,
  TPostFinancialAssetTypesResponse,
  TPostFinancialAssetTypesResponseData,
  TPutFinancialAssetTypesRequest,
  TPutFinancialAssetTypesResponse,
  TPutFinancialAssetTypesResponseData,
} from "Base/api/rest/types/trade/financialAssetTypes"

export class FinancialAssetTypesProvider extends ApiProviderBase {
  async getFinancialAssetTypes(): Promise<TGetFinancialAssetTypesResponse> {
    return this.request<TGetFinancialAssetTypesResponseData>({
      url: "/financial-asset-types",
      method: "GET",
    })
  }

  async postFinancialAssetTypes(
    params: TPostFinancialAssetTypesRequest,
  ): Promise<TPostFinancialAssetTypesResponse> {
    return this.request<TPostFinancialAssetTypesResponseData>({
      url: "/financial-asset-types",
      method: "POST",
      data: params.data,
    })
  }

  async putFinancialAssetTypes(
    params: TPutFinancialAssetTypesRequest,
  ): Promise<TPutFinancialAssetTypesResponse> {
    return this.request<TPutFinancialAssetTypesResponseData>({
      url: "/financial-asset-types/" + params.data?.id,
      method: "PUT",
      data: params.data,
    })
  }

  async deleteFinancialAssetTypes(
    params: TDeleteFinancialAssetTypesRequest,
  ): Promise<TDeleteFinancialAssetTypesResponse> {
    return this.request<TDeleteFinancialAssetTypesResponseData>({
      url: "/financial-asset-types/" + params.params?.id,
      method: "DELETE",
    })
  }
}
