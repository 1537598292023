import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as financialAssetTypesActions from "Base/store/trade/financialAssetTypes/actions"
import { TRequestInfoChildType } from "Base/types/store/reducer"

import {
  TGetFinancialAssetTypesFailureAction,
  TGetFinancialAssetTypesSuccessAction,
  TPostFinancialAssetTypesFailureAction,
  TPostFinancialAssetTypesSuccessAction,
  TPutFinancialAssetTypesFailureAction,
  TPutFinancialAssetTypesSuccessAction,
  TDeleteFinancialAssetTypesSuccessAction,
  TDeleteFinancialAssetTypesFailureAction,
} from "./types"
import {
  TDeleteFinancialAssetTypesResponseData,
  TGetFinancialAssetTypesResponseData,
  TPostFinancialAssetTypesResponseData,
  TPutFinancialAssetTypesResponseData,
} from "Base/api/rest/types/trade/financialAssetTypes"

export type FinancialAssetTypesStateType = {
  getFinancialAssetTypes: {
    data?: TGetFinancialAssetTypesResponseData
  } & TRequestInfoChildType
  postFinancialAssetTypes: {
    data?: TPostFinancialAssetTypesResponseData
  } & TRequestInfoChildType
  putFinancialAssetTypes: {
    data?: TPutFinancialAssetTypesResponseData
  } & TRequestInfoChildType
  deleteFinancialAssetTypes: {
    data?: TDeleteFinancialAssetTypesResponseData
  } & TRequestInfoChildType
}

const initialState: FinancialAssetTypesStateType = {
  getFinancialAssetTypes: {
    ...RequestInfoChildState,
  },
  postFinancialAssetTypes: {
    ...RequestInfoChildState,
  },
  putFinancialAssetTypes: {
    ...RequestInfoChildState,
  },
  deleteFinancialAssetTypes: {
    ...RequestInfoChildState,
  },
}

export const financialAssetTypesReducer = createReducer(initialState, {
  [financialAssetTypesActions.getFinancialAssetTypesAction.call.type]: (state) => ({
    ...state,
    getFinancialAssetTypes: {
      ...state.getFinancialAssetTypes,
      isLoading: true,
    },
  }),
  [financialAssetTypesActions.getFinancialAssetTypesAction.success.type]: (
    state,
    action: TGetFinancialAssetTypesSuccessAction,
  ) => ({
    ...state,
    getFinancialAssetTypes: {
      ...state.getFinancialAssetTypes,
      data: action.payload,
      isLoading: false,
    },
  }),
  [financialAssetTypesActions.getFinancialAssetTypesAction.failure.type]: (
    state,
    action: TGetFinancialAssetTypesFailureAction,
  ) => ({
    ...state,
    getFinancialAssetTypes: {
      ...state.getFinancialAssetTypes,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
  //------------------------------------------------------------------
  [financialAssetTypesActions.postFinancialAssetTypesAction.call.type]: (state) => ({
    ...state,
    postFinancialAssetTypes: {
      ...state.postFinancialAssetTypes,
      isLoading: true,
    },
  }),
  [financialAssetTypesActions.postFinancialAssetTypesAction.success.type]: (
    state,
    action: TPostFinancialAssetTypesSuccessAction,
  ) => ({
    ...state,
    postFinancialAssetTypes: {
      ...state.postFinancialAssetTypes,
      data: action.payload,
      isLoading: false,
    },
  }),
  [financialAssetTypesActions.postFinancialAssetTypesAction.failure.type]: (
    state,
    action: TPostFinancialAssetTypesFailureAction,
  ) => ({
    ...state,
    postFinancialAssetTypes: {
      ...state.postFinancialAssetTypes,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
  [financialAssetTypesActions.putFinancialAssetTypesAction.call.type]: (state) => ({
    ...state,
    putFinancialAssetTypes: {
      ...state.putFinancialAssetTypes,
      isLoading: true,
    },
  }),
  [financialAssetTypesActions.putFinancialAssetTypesAction.success.type]: (
    state,
    action: TPutFinancialAssetTypesSuccessAction,
  ) => ({
    ...state,
    putFinancialAssetTypes: {
      ...state.putFinancialAssetTypes,
      data: action.payload,
      isLoading: false,
    },
  }),
  [financialAssetTypesActions.putFinancialAssetTypesAction.failure.type]: (
    state,
    action: TPutFinancialAssetTypesFailureAction,
  ) => ({
    ...state,
    putFinancialAssetTypes: {
      ...state.putFinancialAssetTypes,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
  [financialAssetTypesActions.deleteFinancialAssetTypesAction.call.type]: (state) => ({
    ...state,
    deleteFinancialAssetTypes: {
      ...state.deleteFinancialAssetTypes,
      isLoading: true,
    },
  }),
  [financialAssetTypesActions.deleteFinancialAssetTypesAction.success.type]: (
    state,
    action: TDeleteFinancialAssetTypesSuccessAction,
  ) => ({
    ...state,
    deleteFinancialAssetTypes: {
      ...state.deleteFinancialAssetTypes,
      data: action.payload,
      isLoading: false,
    },
  }),
  [financialAssetTypesActions.deleteFinancialAssetTypesAction.failure.type]: (
    state,
    action: TDeleteFinancialAssetTypesFailureAction,
  ) => ({
    ...state,
    deleteFinancialAssetTypes: {
      ...state.deleteFinancialAssetTypes,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
})
