import { Button, DropdownButton, Table } from "Base/tsx/components"
import { Column, Row } from "react-table"
import styles from "./Platforms.module.scss"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "Starter/store/configureStore"
import { AdminLayout } from "Admin/tsx/layouts/AdminLayout"
import { PageHeader } from "Base/tsx/containers/PageHeader"
import * as platformsActions from "Base/store/trade/platforms/actions"
import { TPlatform } from "Base/api/rest/types/trade/platforms"
import { DeletePlatformFormModal } from "Admin/tsx/modals/platforms/DeletePlatformFormModal"
import { PlatformFormModal } from "Admin/tsx/modals/platforms/PlatformFormModal"
import { getIconPlatformURL } from "Base/utils/url"
import { PageContainer } from "Base/tsx/containers/PageContainer"
import { TableWindow } from "Base/tsx/components/other/TableWindow"
import { EditingPenIcon } from "Base/tsx/components/icons/EditingPenIcon"

type TData = TPlatform

export const Platforms = () => {
  const dispatch = useDispatch()
  const { getPlatforms } = useSelector((state: RootState) => state.base.platforms)
  const [isModalVisiblePlatforms, setIsModalVisiblePlatforms] = useState(false)
  const [isModalVisibleDeletePlatforms, setIsModalVisibleDeletePlatforms] = useState(false)
  const [platformChange, setPlatformsChange] = useState<TPlatform | undefined>()

  useEffect(() => {
    dispatch(platformsActions.getPlatformsAction.call())
  }, [])

  const columns: Column<TData>[] = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        width: 38,
      },
      {
        Header: "Platform",
        width: 200,
        Cell: ({ row }: { row: Row<TData> }) => {
          const { domain, id } = row.original
          return (
            <div className={styles.item_icon}>
              <img src={getIconPlatformURL(id)} alt='' height={20} width={20} />
              <span>{domain}</span>
            </div>
          )
        },
      },
      {
        id: "actions",
        Header: "Actions",
        sticky: "right",
        width: 76,
        Cell: ({ row }: { row: Row<TData> }) => {
          return (
            <div className={styles.actions}>
              <DropdownButton
                options={[
                  {
                    label: "edit",
                    onClick: () => {
                      setPlatformsChange(row.original)
                      setIsModalVisiblePlatforms(true)
                    },
                  },
                  {
                    label: "delete",
                    onClick: () => {
                      setPlatformsChange(row.original)
                      setIsModalVisibleDeletePlatforms(true)
                    },
                  },
                ]}
              >
                <EditingPenIcon />
              </DropdownButton>
            </div>
          )
        },
      },
    ],
    [],
  )

  const tableData: TData[] = useMemo(() => getPlatforms?.data || [], [getPlatforms?.data])
  console.log("getPlatforms: ", getPlatforms)
  console.log("tableData: ", tableData)
  return (
    <PageContainer>
      <PageHeader>
        <Button onClick={() => setIsModalVisiblePlatforms(true)}>Create Platform</Button>
      </PageHeader>
      <div className={styles.content}>
        <TableWindow<TData>
          fixedSizeListClassName={styles.fixed_size_list}
          columns={columns}
          data={tableData}
          update={() => undefined}
          hasNextPage={false}
          totalItems={1000}
        />
      </div>
      <DeletePlatformFormModal
        isModalVisible={isModalVisibleDeletePlatforms}
        setIsModalVisible={(value) => {
          setPlatformsChange(undefined)
          setIsModalVisibleDeletePlatforms(value)
        }}
        platformChange={platformChange}
      />
      <PlatformFormModal
        isModalVisible={isModalVisiblePlatforms}
        setIsModalVisible={(value) => {
          setPlatformsChange(undefined)
          setIsModalVisiblePlatforms(value)
        }}
        platformChange={platformChange}
      />
    </PageContainer>
  )
}
