import { baseCSF } from "Base/utils/store"

import { TSetAppSettingsReqPayload } from "./types"

const prefix = "app-settings-persist-"

export const setAppSettingsAction = baseCSF<
  TSetAppSettingsReqPayload,
  undefined,
  undefined,
  undefined
>(prefix, "set")

export const appSettingsActions = {
  setAppSettingsAction,
}
