import { TCryptocurrenciesTurnover } from "Base/store/deprecated/transactionsInfo/reducer"
import { TFullTransaction } from "Base/types/deprecated/trade/transactions"

export const calculateCryptocurrencyTurnoverPlus = (
  cryptocurrenciesTurnover: TCryptocurrenciesTurnover,
  transaction: TFullTransaction,
) => {
  const value =
    (cryptocurrenciesTurnover[transaction.cryptocurrencyName] || 0) +
    (transaction.sell ? transaction.buy.cryptocurrencyValue : 0)
  return {
    ...cryptocurrenciesTurnover,
    [transaction.cryptocurrencyName]: value,
  }
}

export const calculateCryptocurrencyTurnoverMinus = (
  cryptocurrenciesTurnover: TCryptocurrenciesTurnover,
  transaction: TFullTransaction,
) => {
  const value =
    (cryptocurrenciesTurnover[transaction.cryptocurrencyName] || 0) -
    (transaction.sell ? transaction.buy.cryptocurrencyValue : 0)
  return {
    ...cryptocurrenciesTurnover,
    [transaction.cryptocurrencyName]: value,
  }
}
