import { baseCSF } from "Base/utils/store"

import {
  TGetFinancialAssetsFailurePayload,
  TGetFinancialAssetsReqPayload,
  TGetFinancialAssetsSuccessPayload,
  TPostFinancialAssetsFailurePayload,
  TPostFinancialAssetsReqPayload,
  TPostFinancialAssetsSuccessPayload,
  TPutFinancialAssetsSuccessPayload,
  TPutFinancialAssetsFailurePayload,
  TPutFinancialAssetsReqPayload,
  TDeleteFinancialAssetsReqPayload,
  TDeleteFinancialAssetsSuccessPayload,
  TDeleteFinancialAssetsFailurePayload,
} from "./types"

const prefix = "financialAssets"

export const getFinancialAssetsAction = baseCSF<
  TGetFinancialAssetsReqPayload,
  TGetFinancialAssetsSuccessPayload,
  TGetFinancialAssetsFailurePayload,
  undefined
>(prefix, "get")

export const postFinancialAssetsAction = baseCSF<
  TPostFinancialAssetsReqPayload,
  TPostFinancialAssetsSuccessPayload,
  TPostFinancialAssetsFailurePayload,
  undefined
>(prefix, "post")

export const putFinancialAssetsAction = baseCSF<
  TPutFinancialAssetsReqPayload,
  TPutFinancialAssetsSuccessPayload,
  TPutFinancialAssetsFailurePayload,
  undefined
>(prefix, "put")

export const deleteFinancialAssetsAction = baseCSF<
  TDeleteFinancialAssetsReqPayload,
  TDeleteFinancialAssetsSuccessPayload,
  TDeleteFinancialAssetsFailurePayload,
  undefined
>(prefix, "delete")
