import React, { useEffect } from "react"
// import { Footer, Header, Sidebar } from "Base/tsx/containers"
import { BaseLayout } from "Base/tsx/layouts"
import { useTranslation } from "react-i18next"
// import { traderV2ModuleRoutes } from "Base/constants/routes/traderV2ModuleRoutes"
// import { PlatformsWithAssetsIcon } from "Base/assets/icons/PlatformsWithAssetsIcon"
// import { InvestmentsIcon } from "Base/assets/icons/InvestmentsIcon"
import * as financialAssetRatesActions from "Base/store/trade/financialAssetRates/actions"
import { useDispatch } from "react-redux"

// import { tonapiActions } from "Base/store/deprecated/tonapi/actions"

export function TraderV2Layout({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(financialAssetRatesActions.getFinancialAssetRatesAction.call())
  //
  // }, [])

  useEffect(() => {
    dispatch(financialAssetRatesActions.getFinancialAssetRatesAction.call())
    const interval = setInterval(() => {
      dispatch(financialAssetRatesActions.getFinancialAssetRatesAction.call())
    }, 100000)
    return () => clearInterval(interval)
  }, [])

  // const menuLinks = [
  //   {
  //     to: traderV2ModuleRoutes["user-assets-control"].root,
  //     label: traderV2ModuleRoutes["user-assets-control"].title,
  //     icon: <PlatformsWithAssetsIcon />,
  //   },
  //   {
  //     to: traderV2ModuleRoutes.management.root,
  //     label: t("t_header_nav_bar_trader"),
  //     icon: <InvestmentsIcon />,
  //   },
  // ]

  return <>{children}</>
}
