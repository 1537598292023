import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as platformsActions from "Base/store/trade/platforms/actions"

import { TRequestInfoChildType } from "Base/types/store/reducer"

import {
  TDeletePlatformsSuccessAction,
  TGetPlatformsFailureAction,
  TGetPlatformsSuccessAction,
  TPostPlatformsFailureAction,
  TPostPlatformsSuccessAction,
  TPutPlatformsFailureAction,
  TPutPlatformsSuccessAction,
} from "./types"
import {
  TDeletePlatformsResponseData,
  TGetPlatformsResponseData,
  TPostPlatformsResponseData,
  TPutPlatformsResponseData,
} from "Base/api/rest/types/trade/platforms"

export type TPlatformsState = {
  getPlatforms: {
    data?: TGetPlatformsResponseData
  } & TRequestInfoChildType
  postPlatforms: {
    data?: TPostPlatformsResponseData
  } & TRequestInfoChildType
  putPlatforms: {
    data?: TPutPlatformsResponseData
  } & TRequestInfoChildType
  deletePlatforms: {
    data?: TDeletePlatformsResponseData
  } & TRequestInfoChildType
}

const initialState: TPlatformsState = {
  getPlatforms: RequestInfoChildState,
  postPlatforms: RequestInfoChildState,
  putPlatforms: RequestInfoChildState,
  deletePlatforms: RequestInfoChildState,
}

export const platformsReducer = createReducer(initialState, {
  [platformsActions.getPlatformsAction.call.type]: (state) => ({
    ...state,
    getPlatforms: {
      ...state.getPlatforms,
      isLoading: true,
    },
  }),
  [platformsActions.getPlatformsAction.success.type]: (
    state,
    action: TGetPlatformsSuccessAction,
  ) => ({
    ...state,
    getPlatforms: {
      ...state.getPlatforms,
      data: action.payload,
      isLoading: false,
    },
  }),
  [platformsActions.getPlatformsAction.failure.type]: (
    state,
    action: TGetPlatformsFailureAction,
  ) => ({
    ...state,
    getPlatforms: {
      ...state.getPlatforms,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
  //------------------------------------------------------------------
  [platformsActions.postPlatformsAction.call.type]: (state) => ({
    ...state,
    postPlatforms: {
      ...state.postPlatforms,
      isLoading: true,
    },
  }),
  [platformsActions.postPlatformsAction.success.type]: (
    state,
    action: TPostPlatformsSuccessAction,
  ) => ({
    ...state,
    postPlatforms: {
      ...state.postPlatforms,
      data: action.payload,
      isLoading: false,
    },
  }),
  [platformsActions.postPlatformsAction.failure.type]: (
    state,
    action: TPostPlatformsFailureAction,
  ) => ({
    ...state,
    postPlatforms: {
      ...state.postPlatforms,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
  //------------------------------------------------------------------
  [platformsActions.putPlatformsAction.call.type]: (state) => ({
    ...state,
    putPlatforms: {
      ...state.putPlatforms,
      isLoading: true,
    },
  }),
  [platformsActions.putPlatformsAction.success.type]: (
    state,
    action: TPutPlatformsSuccessAction,
  ) => ({
    ...state,
    putPlatforms: {
      ...state.putPlatforms,
      data: action.payload,
      isLoading: false,
    },
  }),
  [platformsActions.putPlatformsAction.failure.type]: (
    state,
    action: TPutPlatformsFailureAction,
  ) => ({
    ...state,
    putPlatforms: {
      ...state.putPlatforms,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
  // -----------------------------------------------------------------
  [platformsActions.deletePlatformsAction.call.type]: (state) => ({
    ...state,
    deletePlatforms: {
      ...state.deletePlatforms,
      isLoading: true,
    },
  }),
  [platformsActions.deletePlatformsAction.success.type]: (
    state,
    action: TDeletePlatformsSuccessAction,
  ) => ({
    ...state,
    deletePlatforms: {
      ...state.deletePlatforms,
      data: action.payload,
      isLoading: false,
    },
  }),
  [platformsActions.deletePlatformsAction.failure.type]: (
    state,
    action: TPutPlatformsFailureAction,
  ) => ({
    ...state,
    deletePlatforms: {
      ...state.deletePlatforms,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
})
