import React, { ChangeEvent } from "react"
import { useFormContext, Controller, RegisterOptions } from "react-hook-form"
import styles from "./InputCustom.module.scss"
import { InputWrapper } from "Base/tsx/components/inputs/InputWrapper"

interface InputProps {
  name: string
  label: string
  type?: "text" | "number" | "password"
  placeholder?: string
  max?: number
  min?: number
  precision?: number
  required?: boolean
  onBlur?: (event: any) => void
  disabled?: boolean
  validateDomain?: boolean
}

export const InputCustom: React.FC<InputProps> = ({
  name,
  label,
  type = "text",
  placeholder,
  max,
  min,
  precision,
  required,
  onBlur,
  disabled,
  validateDomain,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (precision && type === "number") {
      const value = parseFloat(e.target.value).toFixed(precision)
      e.target.value = value.toString()
    }
  }

  const domainValidation = {
    pattern: {
      value: /^https?:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9.-]+)*\/?$/,
      message: "Invalid domain format",
    },
  }

  let validationRules = {}
  if (validateDomain) {
    validationRules = { ...validationRules, ...domainValidation }
  }

  let rules: RegisterOptions = {
    required: required ? "Это поле обязательно" : undefined,
    ...validationRules,
  }

  if (type === "number") {
    rules = {
      ...rules,
      max: {
        // @ts-ignore
        value: max,
        message: `Максимальное значение: ${max}`,
      },
      min: {
        // @ts-ignore
        value: min,
        message: `Минимальное значение: ${min}`,
      },
    }
  }

  return (
    <InputWrapper label={label} name={name} required={required} errors={errors}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <input
            {...field}
            type={type}
            className={styles.input}
            placeholder={placeholder}
            max={max}
            min={min}
            onChange={(e) => {
              handleInputChange(e)
              field.onChange(e)
            }}
            onBlur={(event) => {
              onBlur && onBlur(event)
              field.onBlur()
            }}
            disabled={disabled}
          />
        )}
      />
    </InputWrapper>
  )
}
