import { baseCSF } from "Base/utils/store"

import {
  TGetUserAssetsFailurePayload,
  TGetUserAssetsReqPayload,
  TGetUserAssetsSuccessPayload,
} from "./types"

const prefix = "user-assets"

export const getUserAssetsAction = baseCSF<
  TGetUserAssetsReqPayload,
  TGetUserAssetsSuccessPayload,
  TGetUserAssetsFailurePayload,
  undefined
>(prefix, "get")
