import { modulesRoutes } from "./modulesRoutes"

const getProfileRoutes = (baseURL: string) => {
  const url = baseURL !== "" ? `${baseURL}` : ""
  return {
    root: `${url}`,
    title: "Profile",
    profile: {
      root: `${url}`,
      title: "Profile",
      settings: {
        root: `${url}/settings`,
        title: "Настройки",
      },
    },
  }
}

export const profileModuleRoutes = getProfileRoutes(modulesRoutes.profile.root)
