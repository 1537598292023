import {
  GetAuthLogoutCallPayload,
  GetAuthLogoutSuccessPayload,
  GetAuthLogoutFailurePayload,
} from "Base/store/user/authPersist/types"
import { baseCSF } from "Base/utils/store"

const prefix = "auth"

export const getAuthLogoutAction = baseCSF<
  GetAuthLogoutCallPayload,
  GetAuthLogoutSuccessPayload,
  GetAuthLogoutFailurePayload,
  undefined
>(prefix, "logout")
