import React from "react"
import styles from "./Button.module.scss"
import cn from "classnames"

interface ButtonProps {
  type?: "button" | "submit" | "reset"
  onClick?: () => void
  disabled?: boolean
  fluid?: boolean
  className?: string
  theme?: "primary" | "secondary"
  children: React.ReactNode
}

export const Button: React.FC<ButtonProps> = ({
  type = "button",
  onClick,
  disabled,
  fluid,
  className,
  theme = "primary",
  children,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={cn(styles.button, { [styles.button__fluid]: fluid }, styles[theme], className)}
    >
      {children}
    </button>
  )
}
