import styles from "./CurrencyNameItem.module.scss"
import React from "react"
import { TOption } from "Base/tsx/components/inputs/SelectCustom"

export const CurrencyNameItem = ({
  cryptocurrencyName,
  currencyOptionsObj,
}: {
  cryptocurrencyName: string
  currencyOptionsObj: { [key: string]: TOption<string> }
}) => {
  return (
    <div className={styles.cryptocurrency_name_item}>
      {currencyOptionsObj[cryptocurrencyName] && (
        <>
          <img src={currencyOptionsObj[cryptocurrencyName].icon} alt='' height={20} width={20} />
          {currencyOptionsObj[cryptocurrencyName].label}
          {` (${currencyOptionsObj[cryptocurrencyName].value})`}
        </>
      )}
    </div>
  )
}
