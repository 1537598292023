import { TRoleName } from "Base/api/rest/types/user/roles"

export const roleKeys: Record<TRoleName, TRoleName> = {
  admin: "admin",
  user: "user",
}

export const roles: Record<TRoleName, string> = {
  admin: "Администратор",
  user: "Пользователь",
}

export type TRoleOption = {
  label: string
  value: TRoleName
}

export const roleOptions = (Object.keys(roles) as TRoleName[]).map((key) => ({
  label: roles[key],
  value: key,
}))

export const roleOptionsObj: {
  [key: string]: TRoleOption
} = roleOptions.reduce(
  (roleOptionObj: { [key: string]: TRoleOption }, roleOption: TRoleOption) => ({
    ...roleOptionObj,
    [roleOption.value]: roleOption,
  }),
  {},
)
