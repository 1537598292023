import { Button, DropdownButton, Table } from "Base/tsx/components"
import { Column, Row } from "react-table"
import styles from "./Roles.module.scss"
import React, { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "Starter/store/configureStore"
import { AdminLayout } from "Admin/tsx/layouts/AdminLayout"
import { PageHeader } from "Base/tsx/containers/PageHeader"
import { RoleFormModal } from "Admin/tsx/modals/roles/RoleFormModal"
import { DeleteRoleFormModal } from "Admin/tsx/modals/roles/DeleteRoleFormModal"
import { TRole } from "Base/api/rest/types/user/roles"
import { PageContainer } from "Base/tsx/containers/PageContainer"
import { TableWindow } from "Base/tsx/components/other/TableWindow"
import { EditingPenIcon } from "Base/tsx/components/icons/EditingPenIcon"

type TData = TRole

export const Roles = () => {
  const { getRoles } = useSelector((state: RootState) => state.base.roles)
  // const { openSidebar } = useSidebar()
  const [isModalVisibleRole, setIsModalVisibleRole] = useState(false)
  const [isModalVisibleDeleteRole, setIsModalVisibleDeleteRole] = useState(false)
  const [roleChange, setRoleChange] = useState<TRole | undefined>()

  const columns: Column<TData>[] = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        width: 38,
      },
      {
        Header: "Name",
        accessor: "roleName",
        width: 200,
      },
      {
        id: "actions",
        Header: "Actions",
        sticky: "right",
        width: 76,
        Cell: ({ row }: { row: Row<TData> }) => {
          return (
            <div className={styles.actions}>
              <DropdownButton
                options={[
                  {
                    label: "edit",
                    onClick: () => {
                      setRoleChange(row.original)
                      setIsModalVisibleRole(true)
                    },
                  },
                  {
                    label: "delete",
                    onClick: () => {
                      setRoleChange(row.original)
                      setIsModalVisibleDeleteRole(true)
                    },
                  },
                ]}
              >
                <EditingPenIcon />
              </DropdownButton>
            </div>
          )
        },
      },
    ],
    [],
  )

  const tableData: TData[] = useMemo(() => getRoles?.data || [], [getRoles?.data])
  console.log("getRoles: ", getRoles)
  console.log("tableData: ", tableData)
  return (
    <PageContainer>
      <PageHeader>
        <Button onClick={() => setIsModalVisibleRole(true)}>Create role</Button>
      </PageHeader>
      <div className={styles.content}>
        <TableWindow<TData>
          fixedSizeListClassName={styles.fixed_size_list}
          columns={columns}
          data={tableData}
          update={() => undefined}
          hasNextPage={false}
          totalItems={1000}
        />
      </div>
      <DeleteRoleFormModal
        isModalVisible={isModalVisibleDeleteRole}
        setIsModalVisible={(value) => {
          setRoleChange(undefined)
          setIsModalVisibleDeleteRole(value)
        }}
        roleChange={roleChange}
      />
      <RoleFormModal
        isModalVisible={isModalVisibleRole}
        setIsModalVisible={(value) => {
          setRoleChange(undefined)
          setIsModalVisibleRole(value)
        }}
        roleChange={roleChange}
      />
    </PageContainer>
  )
}
