import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as financialAssetRatesActions from "Base/store/trade/financialAssetRates/actions"
import { TRequestInfoChildType } from "Base/types/store/reducer"

import { TGetFinancialAssetRatesFailureAction, TGetFinancialAssetRatesSuccessAction } from "./types"
import { TGetFinancialAssetRatesResponseData } from "Base/api/rest/types/trade/financialAssetRates"

export type TFinancialAssetRatesState = {
  getFinancialAssetRates: {
    data?: TGetFinancialAssetRatesResponseData
  } & TRequestInfoChildType
}

const initialState: TFinancialAssetRatesState = {
  getFinancialAssetRates: {
    ...RequestInfoChildState,
  },
}

export const financialAssetRatesReducer = createReducer(initialState, {
  [financialAssetRatesActions.getFinancialAssetRatesAction.call.type]: (state) => ({
    ...state,
    getFinancialAssetRates: {
      ...state.getFinancialAssetRates,
      isLoading: true,
    },
  }),
  [financialAssetRatesActions.getFinancialAssetRatesAction.success.type]: (
    state,
    action: TGetFinancialAssetRatesSuccessAction,
  ) => ({
    ...state,
    getFinancialAssetRates: {
      ...state.getFinancialAssetRates,
      data: action.payload,
      isLoading: false,
    },
  }),
  [financialAssetRatesActions.getFinancialAssetRatesAction.failure.type]: (
    state,
    action: TGetFinancialAssetRatesFailureAction,
  ) => ({
    ...state,
    getFinancialAssetRates: {
      ...state.getFinancialAssetRates,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
})
