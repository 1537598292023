import React from "react"
import { Route, Navigate } from "react-router-dom"

import { Login } from "Auth/tsx/pages"
import { authModuleRoutes } from "Base/constants/routes"

export const getAuthRouters = () => (
  <>
    <Route path={authModuleRoutes.auth.login.root} element={<Login />} />
    <Route
      path={`${authModuleRoutes.root}/*`}
      element={<Navigate to={authModuleRoutes.auth.login.root} replace={false} />}
    />
  </>
)
