import { createReducer } from "@reduxjs/toolkit"
import { transactionsActions } from "Base/store/deprecated/transactions/actions"

import {
  TArchiveTransactionFailureAction,
  TDeleteTransactionFailureAction,
  TGetTransactionsFailureAction,
  TGetTransactionsSuccessAction,
  TImportTransactionsFromFileFailureAction,
  TSetTransactionFailureAction,
  TUpdateTransactionFailureAction,
} from "./types"
import { TRequestInfoChildType } from "Base/types/store/reducer"
import { RequestInfoChildState } from "Base/constants/statesDefault"
import { TFullTransaction } from "Base/types/deprecated/trade/transactions"

export type TTransactionsState = {
  transactions: TFullTransaction[]
  getTransactions: TRequestInfoChildType
  importTransactionsFromFile: TRequestInfoChildType
  setTransaction: TRequestInfoChildType
  deleteTransaction: TRequestInfoChildType
  archiveTransaction: TRequestInfoChildType
  updateTransaction: TRequestInfoChildType
}

const initialState: TTransactionsState = {
  transactions: [],
  getTransactions: {
    ...RequestInfoChildState,
  },
  importTransactionsFromFile: {
    ...RequestInfoChildState,
  },
  setTransaction: {
    ...RequestInfoChildState,
  },
  deleteTransaction: {
    ...RequestInfoChildState,
  },
  archiveTransaction: {
    ...RequestInfoChildState,
  },
  updateTransaction: {
    ...RequestInfoChildState,
  },
}

export const transactionsReducer = createReducer(initialState, (builder) => {
  builder
    //getTransactions
    .addCase(transactionsActions.getTransactionsAction.call.type, (state) => {
      state.getTransactions.isLoading = true
    })
    .addCase(
      transactionsActions.getTransactionsAction.success.type,
      (state, action: TGetTransactionsSuccessAction) => {
        state.transactions = action.payload
        state.getTransactions.isLoading = false
      },
    )
    .addCase(
      transactionsActions.getTransactionsAction.failure.type,
      (state, action: TGetTransactionsFailureAction) => {
        state.getTransactions.error = action.payload.error
        state.getTransactions.isLoading = false
      },
    )
    //getTransactions
    .addCase(transactionsActions.importTransactionsFromFileAction.call.type, (state) => {
      state.getTransactions.isLoading = true
    })
    .addCase(transactionsActions.importTransactionsFromFileAction.success.type, (state) => {
      state.getTransactions.isLoading = false
    })
    .addCase(
      transactionsActions.importTransactionsFromFileAction.failure.type,
      (state, action: TImportTransactionsFromFileFailureAction) => {
        state.getTransactions.error = action.payload.error
        state.getTransactions.isLoading = false
      },
    )
    //setTransaction
    .addCase(transactionsActions.setTransactionAction.call.type, (state) => {
      state.setTransaction.isLoading = true
    })
    .addCase(transactionsActions.setTransactionAction.success.type, (state) => {
      state.setTransaction.isLoading = false
    })
    .addCase(
      transactionsActions.setTransactionAction.failure.type,
      (state, action: TSetTransactionFailureAction) => {
        state.setTransaction.error = action.payload.error
        state.setTransaction.isLoading = false
      },
    )
    //updateTransaction
    .addCase(transactionsActions.updateTransactionAction.call.type, (state) => {
      state.setTransaction.isLoading = true
    })
    .addCase(transactionsActions.updateTransactionAction.success.type, (state) => {
      state.setTransaction.isLoading = false
    })
    .addCase(
      transactionsActions.updateTransactionAction.failure.type,
      (state, action: TUpdateTransactionFailureAction) => {
        state.setTransaction.error = action.payload.error
        state.setTransaction.isLoading = false
      },
    )
    //deleteTransaction
    .addCase(transactionsActions.deleteTransactionAction.call.type, (state) => {
      state.deleteTransaction.isLoading = true
    })
    .addCase(transactionsActions.deleteTransactionAction.success.type, (state) => {
      state.deleteTransaction.isLoading = false
    })
    .addCase(
      transactionsActions.deleteTransactionAction.failure.type,
      (state, action: TDeleteTransactionFailureAction) => {
        state.deleteTransaction.error = action.payload.error
        state.deleteTransaction.isLoading = false
      },
    )
    //archiveTransaction
    .addCase(transactionsActions.archiveTransactionAction.call.type, (state) => {
      state.archiveTransaction.isLoading = true
    })
    .addCase(transactionsActions.archiveTransactionAction.success.type, (state) => {
      state.archiveTransaction.isLoading = false
    })
    .addCase(
      transactionsActions.archiveTransactionAction.failure.type,
      (state, action: TArchiveTransactionFailureAction) => {
        state.archiveTransaction.error = action.payload.error
        state.archiveTransaction.isLoading = false
      },
    )
})
