import { SVGProps } from "react"

export function UserIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' {...props}>
      <circle cx='12' cy='12' r='11' fill='none' stroke='currentColor' strokeWidth='2' />
      <path
        d='M12 4a4 4 0 100 8 4 4 0 000-8zm0 10c-2.7 0-8 1.35-8 4v2h16v-2c0-2.65-5.3-4-8-4z'
        fill='currentColor'
      ></path>
    </svg>
  )
}
