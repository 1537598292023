import React from "react"
import styles from "./Checkbox.module.scss"

interface CheckboxProps {
  checked: boolean
  onChange: (checked: boolean) => void
  title?: string
  id: string
}

export const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange, title, id }) => {
  return (
    <div className={styles.checkbox_container}>
      <input
        type='checkbox'
        className={styles.checkbox_input}
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <label htmlFor={id}>
        <span className={styles.checkbox}></span>
        {title && <span className={styles.title}>{title}</span>}
      </label>
    </div>
  )
}
