import { baseCSF } from "Base/utils/store"
import { TSetExchangeRateReqPayload } from "Base/store/deprecated/exchangeRate/types"

const prefix = "exchange-rate"

const setExchangeRateAction = baseCSF<TSetExchangeRateReqPayload, undefined, undefined, undefined>(
  prefix,
  "set-exchange-rate",
)

export const exchangeRateActions = {
  setExchangeRateAction,
}
