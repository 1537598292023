import { baseCSF } from "Base/utils/store"

import {
  TArchiveTransactionFailurePayload,
  TArchiveTransactionReqPayload,
  TArchiveTransactionSuccessPayload,
  TDeleteTransactionFailurePayload,
  TDeleteTransactionReqPayload,
  TDeleteTransactionSuccessPayload,
  TGetTransactionsFailurePayload,
  TGetTransactionsReqPayload,
  TGetTransactionsSuccessPayload,
  TImportTransactionsFromFileFailurePayload,
  TImportTransactionsFromFileReqPayload,
  TImportTransactionsFromFileSuccessPayload,
  TSetTransactionFailurePayload,
  TSetTransactionReqPayload,
  TSetTransactionSuccessPayload,
  TUpdateTransactionFailurePayload,
  TUpdateTransactionReqPayload,
  TUpdateTransactionSuccessPayload,
} from "./types"

const prefix = "transactions"

const getTransactionsAction = baseCSF<
  TGetTransactionsReqPayload,
  TGetTransactionsSuccessPayload,
  TGetTransactionsFailurePayload,
  undefined
>(prefix, "get")

const importTransactionsFromFileAction = baseCSF<
  TImportTransactionsFromFileReqPayload,
  TImportTransactionsFromFileSuccessPayload,
  TImportTransactionsFromFileFailurePayload,
  undefined
>(prefix, "import-from-file")

const setTransactionAction = baseCSF<
  TSetTransactionReqPayload,
  TSetTransactionSuccessPayload,
  TSetTransactionFailurePayload,
  undefined
>(prefix, "set")

const updateTransactionAction = baseCSF<
  TUpdateTransactionReqPayload,
  TUpdateTransactionSuccessPayload,
  TUpdateTransactionFailurePayload,
  undefined
>(prefix, "update")

const deleteTransactionAction = baseCSF<
  TDeleteTransactionReqPayload,
  TDeleteTransactionSuccessPayload,
  TDeleteTransactionFailurePayload,
  undefined
>(prefix, "delete")

const archiveTransactionAction = baseCSF<
  TArchiveTransactionReqPayload,
  TArchiveTransactionSuccessPayload,
  TArchiveTransactionFailurePayload,
  undefined
>(prefix, "archive")

export const transactionsActions = {
  getTransactionsAction,
  setTransactionAction,
  deleteTransactionAction,
  archiveTransactionAction,
  importTransactionsFromFileAction,
  updateTransactionAction,
}
