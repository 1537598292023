import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as usersActions from "Base/store/user/roles/actions"

import { TRequestInfoChildType } from "Base/types/store/reducer"

import {
  TGetRolesFailureAction,
  TGetRolesSuccessAction,
  TPostRolesFailureAction,
  TPostRolesSuccessAction,
  TPutRolesFailureAction,
  TPutRolesSuccessAction,
  TDeleteRolesSuccessAction,
  TDeleteRolesFailureAction,
} from "./types"
import {
  TDeleteRolesResponseData,
  TGetRolesResponseData,
  TPostRolesResponseData,
  TPutRolesResponseData,
} from "Base/api/rest/types/user/roles"

export type RolesStateType = {
  getRoles: {
    data?: TGetRolesResponseData
  } & TRequestInfoChildType
  postRoles: {
    data?: TPostRolesResponseData
  } & TRequestInfoChildType
  putRoles: {
    data?: TPutRolesResponseData
  } & TRequestInfoChildType
  deleteRoles: {
    data?: TDeleteRolesResponseData
  } & TRequestInfoChildType
}

const initialState: RolesStateType = {
  getRoles: {
    ...RequestInfoChildState,
  },
  postRoles: {
    ...RequestInfoChildState,
  },
  putRoles: {
    ...RequestInfoChildState,
  },
  deleteRoles: {
    ...RequestInfoChildState,
  },
}

export const rolesReducer = createReducer(initialState, {
  [usersActions.getRolesAction.call.type]: (state) => ({
    ...state,
    getRoles: {
      ...state.getRoles,
      isLoading: true,
    },
  }),
  [usersActions.getRolesAction.success.type]: (state, action: TGetRolesSuccessAction) => ({
    ...state,
    getRoles: {
      ...state.getRoles,
      data: action.payload,
      isLoading: false,
    },
  }),
  [usersActions.getRolesAction.failure.type]: (state, action: TGetRolesFailureAction) => ({
    ...state,
    getRoles: {
      ...state.getRoles,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
  //------------------------------------------------------------------
  [usersActions.postRolesAction.call.type]: (state) => ({
    ...state,
    postRoles: {
      ...state.postRoles,
      isLoading: true,
    },
  }),
  [usersActions.postRolesAction.success.type]: (state, action: TPostRolesSuccessAction) => ({
    ...state,
    postRoles: {
      ...state.postRoles,
      data: action.payload,
      isLoading: false,
    },
  }),
  [usersActions.postRolesAction.failure.type]: (state, action: TPostRolesFailureAction) => ({
    ...state,
    postRoles: {
      ...state.postRoles,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
  [usersActions.putRolesAction.call.type]: (state) => ({
    ...state,
    putRoles: {
      ...state.putRoles,
      isLoading: true,
    },
  }),
  [usersActions.putRolesAction.success.type]: (state, action: TPutRolesSuccessAction) => ({
    ...state,
    putRoles: {
      ...state.putRoles,
      data: action.payload,
      isLoading: false,
    },
  }),
  [usersActions.putRolesAction.failure.type]: (state, action: TPutRolesFailureAction) => ({
    ...state,
    putRoles: {
      ...state.putRoles,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
  [usersActions.deleteRolesAction.call.type]: (state) => ({
    ...state,
    deleteRoles: {
      ...state.deleteRoles,
      isLoading: true,
    },
  }),
  [usersActions.deleteRolesAction.success.type]: (state, action: TDeleteRolesSuccessAction) => ({
    ...state,
    deleteRoles: {
      ...state.deleteRoles,
      data: action.payload,
      isLoading: false,
    },
  }),
  [usersActions.deleteRolesAction.failure.type]: (state, action: TDeleteRolesFailureAction) => ({
    ...state,
    deleteRoles: {
      ...state.deleteRoles,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
})
