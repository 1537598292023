import React, { useEffect, useState } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  Column,
  Row,
  Cell,
  UseTableInstanceProps,
  HeaderGroup,
} from "react-table"
import styles from "./Table.module.scss"
import cn from "classnames"

interface TableProps<T extends object> {
  columns: Column<T>[]
  data: T[]
  defaultSorting?: { id: string; desc?: boolean }[]
}

export const Table = <T extends object>({ columns, data, defaultSorting }: TableProps<T>) => {
  const [columnVisibility, setColumnVisibility] = useState({})

  // @ts-ignore
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, setPageSize, page } =
    useTable<T>(
      {
        columns,
        data,
        initialState: {
          // @ts-ignore
          pageIndex: 0,
          pageSize: 100,
          sortBy: defaultSorting || [],
        },
      },
      useSortBy,
      usePagination,
    ) as UseTableInstanceProps<T>

  useEffect(() => {
    setPageSize(100)
    // Устанавливаем видимость всех колонок в 'true' при инициализации
    const initialVisibility = columns.reduce((acc, column) => {
      // @ts-ignore
      acc[column.id] = true
      return acc
    }, {} as Record<string, boolean>)
    setColumnVisibility(initialVisibility)
  }, [setPageSize, columns])

  // Функция переключения видимости колонки
  const toggleColumn = (columnId: string) => {
    setColumnVisibility((prev) => ({
      ...prev,
      // @ts-ignore
      [columnId]: !columnVisibility[columnId],
    }))
  }

  // @ts-ignore
  return (
    <div className={styles.table_container}>
      <table {...getTableProps()} className={styles.table}>
        <thead>
          {headerGroups.map((headerGroup: HeaderGroup<T>, i: number) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => (
                <th
                  // @ts-ignore
                  {...column.getHeaderProps(column.getSortByToggleProps)}
                  key={j}
                  // @ts-ignore
                  // className={cn({ [styles.collapsing]: !columnVisibility[column.id] })}
                >
                  <div className={styles.header_content}>
                    {/*@ts-ignore*/}
                    {/*{!columnVisibility?.[column.id] && column.render("Header")}*/}
                    {column.render("Header")}
                    {/*<input*/}
                    {/*  type='checkbox'*/}
                    {/*  // @ts-ignore*/}
                    {/*  checked={columnVisibility[column.id]}*/}
                    {/*  onChange={() => toggleColumn(column.id)}*/}
                    {/*  onClick={(e) => e.stopPropagation()} // Предотвращает сортировку при нажатии на чекбокс*/}
                    {/*/>*/}
                  </div>
                  {/*@ts-ignore*/}
                  <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: Row<T>, i: number) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell: Cell<T>, j) => (
                  <td {...cell.getCellProps()} key={j}>
                    {/*@ts-ignore*/}
                    {!columnVisibility?.[cell.column.id] && cell.render("Cell")}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
