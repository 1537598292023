import { Button, Modal } from "Base/tsx/components"
import styles from "./DeletePlatformFormModal.module.scss"
import React from "react"
import { useDispatch } from "react-redux"
import * as platformsActions from "Base/store/trade/platforms/actions"
import { TPlatform } from "Base/api/rest/types/trade/platforms"

export const DeletePlatformFormModal = ({
  platformChange,
  isModalVisible,
  setIsModalVisible,
}: {
  platformChange?: TPlatform
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
}) => {
  const dispatch = useDispatch()

  return (
    <Modal
      title='Platform removal confirmation'
      visible={isModalVisible}
      onClose={() => setIsModalVisible(false)}
    >
      <h3>Are you sure you want to delete the Platform?</h3>
      <div className={styles.main}>
        <Button
          onClick={() => {
            platformChange && dispatch(platformsActions.deletePlatformsAction.call(platformChange))
            setIsModalVisible(false)
          }}
        >
          Delete
        </Button>
        <Button
          theme='secondary'
          onClick={() => {
            setIsModalVisible(false)
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}
