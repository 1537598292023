import React, { useEffect, useMemo } from "react"
import styles from "./SidebarMenu.module.scss"
import cn from "classnames"
import { HamburgerIcon } from "Base/tsx/components"
import { useSidebar } from "Base/tsx/providers/SidebarProvider"
import { AccordionMenu } from "Base/tsx/components/AccordionMenu"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "Starter/store/configureStore"
import { getMenuObjs } from "Base/constants/menuList"

export function SidebarMenu() {
  const { profile } = useSelector((state: RootState) => state.base.profilePersist)

  const { isOpen, setIsOpen } = useSidebar()
  const location = useLocation()

  const labelGroupActive = useMemo(() => {
    const role = profile?.userRoles[0]?.role
    const activeLink = getMenuObjs(role).find((menu) => {
      return menu.links.some((link) => {
        return location.pathname.indexOf(link.to) !== -1
      })
    })
    return activeLink ? activeLink.label : ""
  }, [location.pathname, profile])

  useEffect(() => {
    document.documentElement.style.setProperty("--sidebar-width", isOpen ? "16rem" : "3.2rem")
  }, [isOpen])

  const toggleSidebar = () => {
    setIsOpen && setIsOpen((prev) => !prev)
  }

  return (
    <div className={cn(styles.sidebar)}>
      <div
        className={cn(styles.hamburger_icon, {
          [styles.hamburger_icon__open]: isOpen,
        })}
      >
        <HamburgerIcon onClick={toggleSidebar} isActive={isOpen} />
      </div>
      <div className={styles.nav_wrap}>
        <nav>
          {getMenuObjs(profile?.userRoles[0]?.role).map(
            ({ label: labelGroup, icon: IconGroup, links }) => (
              <AccordionMenu
                labelGroupActive={labelGroupActive}
                isSqueeze={isOpen}
                key={labelGroup}
                title={{ label: labelGroup, icon: IconGroup }}
                content={links}
              />
            ),
          )}
        </nav>
      </div>
    </div>
  )
}

export default SidebarMenu
