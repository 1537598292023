import { adminRoutes } from "Base/constants/routes/adminRoutes"
import { traderV2ModuleRoutes } from "Base/constants/routes/traderV2ModuleRoutes"
import { TRole } from "Base/api/rest/types/user/roles"
// import { roleKeys } from "Base/constants/roles"
import {
  InventoryIcon,
  ListOfObjectsIcon,
  MonitoringIcon,
  ReviewIcon,
  RolesIcon,
  ServicesListIcon,
  TopologyMapIcon,
  UsersAndGroupsIcon,
  UsersAndSettingsIcon,
} from "Base/tsx/components/icons/Sidebar"
import { roleKeys } from "Base/constants/roles"

export const menuAdminLinks = [
  {
    to: adminRoutes.roles.root,
    label: adminRoutes.roles.title,
    icon: <RolesIcon />,
  },
  {
    to: adminRoutes.platforms.root,
    label: adminRoutes.platforms.title,
    icon: <ListOfObjectsIcon />,
  },
  {
    to: adminRoutes.users.root,
    label: adminRoutes.users.title,
    icon: <UsersAndGroupsIcon />,
  },
  {
    to: adminRoutes["financial-asset-types"].root,
    label: adminRoutes["financial-asset-types"].title,
    icon: <TopologyMapIcon />,
  },
  {
    to: adminRoutes["financial-assets"].root,
    label: adminRoutes["financial-assets"].title,
    icon: <ReviewIcon />,
  },
]

export const menuTraderV2Links = [
  {
    to: traderV2ModuleRoutes.management.root,
    label: traderV2ModuleRoutes.management.title,
    icon: <ServicesListIcon />,
  },
  {
    to: traderV2ModuleRoutes["user-assets-control"].root,
    label: traderV2ModuleRoutes["user-assets-control"].title,
    icon: <InventoryIcon />,
  },
]

export const getMenuObjs = (role?: TRole) => {
  const adminLinks =
    role?.roleName === roleKeys.admin
      ? [
          {
            label: adminRoutes.title,
            links: menuAdminLinks,
            icon: <UsersAndSettingsIcon />,
          },
        ]
      : []
  console.log("getMenuObjs, adminLinks: ", adminLinks)
  return [
    {
      label: traderV2ModuleRoutes.title,
      links: menuTraderV2Links,
      icon: <MonitoringIcon />,
    },
    ...adminLinks,
  ]
}
