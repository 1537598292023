import { baseCSF } from "Base/utils/store"

import {
  TGetFinancialAssetTypesFailurePayload,
  TGetFinancialAssetTypesReqPayload,
  TGetFinancialAssetTypesSuccessPayload,
  TPostFinancialAssetTypesFailurePayload,
  TPostFinancialAssetTypesReqPayload,
  TPostFinancialAssetTypesSuccessPayload,
  TPutFinancialAssetTypesSuccessPayload,
  TPutFinancialAssetTypesFailurePayload,
  TPutFinancialAssetTypesReqPayload,
  TDeleteFinancialAssetTypesReqPayload,
  TDeleteFinancialAssetTypesSuccessPayload,
  TDeleteFinancialAssetTypesFailurePayload,
} from "./types"

const prefix = "financialAssetTypes"

export const getFinancialAssetTypesAction = baseCSF<
  TGetFinancialAssetTypesReqPayload,
  TGetFinancialAssetTypesSuccessPayload,
  TGetFinancialAssetTypesFailurePayload,
  undefined
>(prefix, "get")

export const postFinancialAssetTypesAction = baseCSF<
  TPostFinancialAssetTypesReqPayload,
  TPostFinancialAssetTypesSuccessPayload,
  TPostFinancialAssetTypesFailurePayload,
  undefined
>(prefix, "post")

export const putFinancialAssetTypesAction = baseCSF<
  TPutFinancialAssetTypesReqPayload,
  TPutFinancialAssetTypesSuccessPayload,
  TPutFinancialAssetTypesFailurePayload,
  undefined
>(prefix, "put")

export const deleteFinancialAssetTypesAction = baseCSF<
  TDeleteFinancialAssetTypesReqPayload,
  TDeleteFinancialAssetTypesSuccessPayload,
  TDeleteFinancialAssetTypesFailurePayload,
  undefined
>(prefix, "delete")
