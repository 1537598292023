// api/globalApiProviders.ts
import { UsersProvider } from "Base/api/rest/providers/user/UsersProvider"
import { UserAssetsProvider } from "Base/api/rest/providers/trade/UserAssetsProvider"
import { PlatformsProvider } from "Base/api/rest/providers/trade/PlatformsProvider"
import { AuthProvider } from "Base/api/rest/providers/user/AuthProvider"
import { ProfileProvider } from "Base/api/rest/providers/user/ProfileProvider"
import { FinancialAssetRatesProvider } from "Base/api/rest/providers/trade/FinancialAssetRatesProvider"
import { TradesProvider } from "Base/api/rest/providers/trade/TradesProvider"
import { FinancialAssetsProvider } from "Base/api/rest/providers/trade/FinancialAssetsProvider"
import { FinancialAssetTypesProvider } from "Base/api/rest/providers/trade/FinancialAssetTypesProvider"
import { MarketOrdersProvider } from "Base/api/rest/providers/trade/MarketOrdersProvider"
import { UserRolesProvider } from "Base/api/rest/providers/user/UserRolesProvider"
import { RolesProvider } from "Base/api/rest/providers/user/RolesProvider"

export const platformsProvider = new PlatformsProvider()
export const tradesProvider = new TradesProvider()
export const financialAssetRatesProvider = new FinancialAssetRatesProvider()
export const userAssetsProvider = new UserAssetsProvider()
export const financialAssetsProvider = new FinancialAssetsProvider()
export const financialAssetTypesProvider = new FinancialAssetTypesProvider()
export const marketOrdersProvider = new MarketOrdersProvider()

export const authProvider = new AuthProvider()
export const profileProvider = new ProfileProvider()

export const userRolesProvider = new UserRolesProvider()
export const usersProvider = new UsersProvider()
export const rolesProvider = new RolesProvider()
