import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as usersActions from "Base/store/user/userRoles/actions"

import { TRequestInfoChildType } from "Base/types/store/reducer"

import {
  TGetUserRolesFailureAction,
  TGetUserRolesSuccessAction,
  TPostUserRolesFailureAction,
  TPostUserRolesSuccessAction,
  TPutUserRolesFailureAction,
  TPutUserRolesSuccessAction,
  TDeleteUserRolesSuccessAction,
  TDeleteUserRolesFailureAction,
} from "./types"
import {
  TDeleteUserRolesResponseData,
  TGetUserRolesResponseData,
  TPostUserRolesResponseData,
  TPutUserRolesResponseData,
} from "Base/api/rest/types/user/userRoles"

export type UserRolesStateType = {
  getUserRoles: {
    data?: TGetUserRolesResponseData
  } & TRequestInfoChildType
  postUserRoles: {
    data?: TPostUserRolesResponseData
  } & TRequestInfoChildType
  putUserRoles: {
    data?: TPutUserRolesResponseData
  } & TRequestInfoChildType
  deleteUserRoles: {
    data?: TDeleteUserRolesResponseData
  } & TRequestInfoChildType
}

const initialState: UserRolesStateType = {
  getUserRoles: {
    ...RequestInfoChildState,
  },
  postUserRoles: {
    ...RequestInfoChildState,
  },
  putUserRoles: {
    ...RequestInfoChildState,
  },
  deleteUserRoles: {
    ...RequestInfoChildState,
  },
}

export const userRolesReducer = createReducer(initialState, {
  [usersActions.getUserRolesAction.call.type]: (state) => ({
    ...state,
    getUserRoles: {
      ...state.getUserRoles,
      isLoading: true,
    },
  }),
  [usersActions.getUserRolesAction.success.type]: (state, action: TGetUserRolesSuccessAction) => ({
    ...state,
    getUserRoles: {
      ...state.getUserRoles,
      data: action.payload,
      isLoading: false,
    },
  }),
  [usersActions.getUserRolesAction.failure.type]: (state, action: TGetUserRolesFailureAction) => ({
    ...state,
    getUserRoles: {
      ...state.getUserRoles,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
  //------------------------------------------------------------------
  [usersActions.postUserRolesAction.call.type]: (state) => ({
    ...state,
    postUserRoles: {
      ...state.postUserRoles,
      isLoading: true,
    },
  }),
  [usersActions.postUserRolesAction.success.type]: (
    state,
    action: TPostUserRolesSuccessAction,
  ) => ({
    ...state,
    postUserRoles: {
      ...state.postUserRoles,
      data: action.payload,
      isLoading: false,
    },
  }),
  [usersActions.postUserRolesAction.failure.type]: (
    state,
    action: TPostUserRolesFailureAction,
  ) => ({
    ...state,
    postUserRoles: {
      ...state.postUserRoles,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
  [usersActions.putUserRolesAction.call.type]: (state) => ({
    ...state,
    putUserRoles: {
      ...state.putUserRoles,
      isLoading: true,
    },
  }),
  [usersActions.putUserRolesAction.success.type]: (state, action: TPutUserRolesSuccessAction) => ({
    ...state,
    putUserRoles: {
      ...state.putUserRoles,
      data: action.payload,
      isLoading: false,
    },
  }),
  [usersActions.putUserRolesAction.failure.type]: (state, action: TPutUserRolesFailureAction) => ({
    ...state,
    putUserRoles: {
      ...state.putUserRoles,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------

  //------------------------------------------------------------------
  [usersActions.deleteUserRolesAction.call.type]: (state) => ({
    ...state,
    deleteUserRoles: {
      ...state.deleteUserRoles,
      isLoading: true,
    },
  }),
  [usersActions.deleteUserRolesAction.success.type]: (
    state,
    action: TDeleteUserRolesSuccessAction,
  ) => ({
    ...state,
    deleteUserRoles: {
      ...state.deleteUserRoles,
      data: action.payload,
      isLoading: false,
    },
  }),
  [usersActions.deleteUserRolesAction.failure.type]: (
    state,
    action: TDeleteUserRolesFailureAction,
  ) => ({
    ...state,
    deleteUserRoles: {
      ...state.deleteUserRoles,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //------------------------------------------------------------------
})
