import styles from "./TransactionsInfo.module.scss"
import React, { useMemo } from "react"
import { RootState } from "Starter/store/configureStore"
import { useSelector } from "react-redux"

export const TransactionsInfo = () => {
  const {
    transactionsInfo: { profit, cryptocurrenciesTurnover, currenciesTurnover },
    // exchangeRate: {
    //   providers: { binance },
    // },
  } = useSelector((state: RootState) => state.base)

  // console.log("binance: ", binance)
  return (
    <div className={styles.transactions_info}>
      <div className={styles.turnover}>
        <h4>Turnover:</h4>
        {Object.keys(currenciesTurnover).map((key) => (
          <span key={key}>
            {key}: {currenciesTurnover[key].toFixed(2)}
          </span>
        ))}
        {Object.keys(cryptocurrenciesTurnover).map((key) => (
          <span key={key}>
            {key}: {cryptocurrenciesTurnover[key].toFixed(9)}
          </span>
        ))}
      </div>
      <div className={styles.profit_info}>
        <h4>Profit:</h4>
        {Object.keys(profit).map((key, index) => (
          <span key={index}>
            {key}: {profit[key].toFixed(2)}
          </span>
        ))}
      </div>
      {/*{Object.keys(binance).map((key) => (*/}
      {/*  <span key={key}>*/}
      {/*    {key}: {binance[key].rate}*/}
      {/*  </span>*/}
      {/*))}*/}
    </div>
  )
}
