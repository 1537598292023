// api/UserAssetsProvider.ts
import {
  TGetUserAssetsResponse,
  TGetUserAssetsResponseData,
} from "Base/api/rest/types/trade/userAssets"
import { ApiProviderBase } from "Base/api/rest/ApiProviderBase"

export class UserAssetsProvider extends ApiProviderBase {
  async getUserAssets(): Promise<TGetUserAssetsResponse> {
    return this.request<TGetUserAssetsResponseData>({ url: "/user-assets", method: "GET" })
  }
}
