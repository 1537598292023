import React, { CSSProperties, forwardRef, useCallback, useEffect } from "react"
import {
  useTable,
  useBlockLayout,
  useSortBy,
  useRowSelect,
  Hooks,
  HeaderGroup,
  Column,
} from "react-table"
import { VariableSizeList } from "react-window"
import InfiniteLoader from "react-window-infinite-loader"
import { useSticky } from "react-table-sticky"
import styles from "./TableWindow.module.scss"
import cn from "classnames"

interface IndeterminateCheckboxProps {
  indeterminate?: boolean

  [key: string]: any
}

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef<HTMLInputElement>(null)
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      // @ts-ignore
      if (resolvedRef.current) {
        // @ts-ignore
        resolvedRef.current.indeterminate = indeterminate ?? false
      }
    }, [resolvedRef, indeterminate])
    return (
      <>
        <input type='checkbox' ref={resolvedRef} {...rest} />
      </>
    )
  },
)

export type TTableWindowColumn<T extends object> = Column<T> & {
  sticky?: string
}

interface TableWindowProps<T extends object> {
  columns: TTableWindowColumn<T>[]
  data: T[]
  update: () => void
  hasNextPage: boolean
  isNextPageLoading?: boolean
  totalItems: number
  fixedSizeListClassName?: string
  fixedSizeListStyle?: CSSProperties
  fixedSizeListHeight?: number
  onClickRow?: (obj: T) => void
}

export const TableWindow = <T extends object>({
  columns,
  data,
  update,
  hasNextPage,
  isNextPageLoading,
  totalItems,
  fixedSizeListClassName,
  fixedSizeListStyle,
  fixedSizeListHeight = 1000,
  onClickRow,
}: TableWindowProps<T>) => {
  const defaultColumn = React.useMemo(
    () => ({
      // minWidth: 100,
      // width: 300,
      // maxWidth: 800,
    }),
    [],
  )
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
    // @ts-ignore
    state: { selectedRowIds },
  } = useTable<T>(
    {
      columns,
      data,
      // @ts-ignore
      autoResetSelectedRows: false,
      defaultColumn,
    },
    useSortBy,
    useRowSelect,
    useBlockLayout,
    useSticky,
    (hooks: Hooks<any>) => {
      // @ts-ignore
      hooks.visibleColumns.push((columns: HeaderGroup<any>[]) => [
        // Let's make a column for selection
        // {
        //   id: "selection",
        //   sticky: "left",
        //   width: 35,
        //   // The header can use the table's getToggleAllRowsSelectedProps method
        //   // to render a checkbox
        //   // @ts-ignore
        //   Header: ({ getToggleAllRowsSelectedProps }) => (
        //     <div>
        //       <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        //     </div>
        //   ),
        //   // The cell can use the individual row's getToggleRowSelectedProps method
        //   // to the render a checkbox
        //   Cell: ({ row }) => (
        //     <div>
        //       {/*@ts-ignore*/}
        //       <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        //     </div>
        //   ),
        // },
        ...columns,
        // {
        //   id: "actions",
        //   sticky: "right",
        //   width: 100,
        //   Cell: ({ row }) => (
        //     <div>
        //       <Button onClick={() => console.log(row.original)}>Actions</Button>
        //     </div>
        //   ),
        // },
      ])
    },
  )

  const itemCount = hasNextPage ? rows.length + 1 : rows.length
  const loadMoreItems = isNextPageLoading ? () => {} : update
  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < rows.length,
    [hasNextPage, rows],
  )
  const RenderRow = useCallback(
    (rows: any) =>
      // eslint-disable-next-line react/display-name
      ({ index, style }: any) => {
        if (!isItemLoaded(index))
          return (
            <div className={styles.tr}>
              <div className={styles.td}>Loading</div>
            </div>
          )
        const row = rows[index]
        prepareRow(row)
        const { style: rowStyle, ...restRow } = row.getRowProps({ style })
        return (
          <div
            {...restRow}
            style={{ ...rowStyle, width: totalColumnsWidth }}
            className={cn(styles.tr, { [styles.trHover]: onClickRow })}
            id={`table-row-${index}`}
            onClick={onClickRow ? () => onClickRow(row.original) : undefined}
          >
            {row.cells.map((cell: any, indexCell: number) => {
              return (
                <div
                  {...cell.getCellProps()}
                  onClick={(event) => row.cells.length === indexCell + 1 && event.stopPropagation()}
                  key={indexCell}
                  className={cn(styles.td, {
                    [styles.actions]: cell?.column?.id === "actions",
                  })}
                >
                  {cell.render("Cell")}
                </div>
              )
            })}
          </div>
        )
      },
    [prepareRow, isItemLoaded, totalColumnsWidth],
  )

  const getItemSize = (index: any, data: any, columns: any, defaultHeight = 36) => {
    const row = data[index]
    let maxHeight = defaultHeight

    columns.forEach((column: any) => {
      const text = row[column.accessor]
      if (column?.height > maxHeight) {
        maxHeight = column?.height
        return
      }
      if (text) {
        // Предположим, что длина текста в символах, делённая на некий коэффициент (например, 20 символов на строку),
        // даст нам количество строк текста в этой ячейке. Это простая эвристика.
        const textLength = text.length
        const lines = Math.ceil(textLength / 18) // Предположим, что в одной строке умещается 20 символов
        const estimatedHeight = lines * 10 // Предположим, что высота одной строки текста равна 20px
        if (estimatedHeight > maxHeight) {
          maxHeight = estimatedHeight
        }
      }
    })

    return maxHeight
  }

  return (
    <>
      <div {...getTableProps()} className={cn(styles.table, styles.sticky)}>
        <div style={{ position: "relative", flex: 1, zIndex: 0 }}>
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <VariableSizeList
                className={fixedSizeListClassName}
                height={fixedSizeListHeight}
                style={{ height: fixedSizeListHeight, ...fixedSizeListStyle }}
                itemCount={rows.length}
                // itemSize={45}
                itemSize={(index) => getItemSize(index, data, columns)}
                width={"100%"}
                onItemsRendered={onItemsRendered}
                ref={ref}
                innerElementType={({ children, style, ...rest }) => (
                  <>
                    <div className={styles.header}>
                      <div style={{ width: totalColumnsWidth }}>
                        {headerGroups.map((headerGroup, headerGroupIndex) => (
                          <div
                            {...headerGroup.getHeaderGroupProps()}
                            className={styles.tr}
                            key={`headerGroup-${headerGroupIndex}`}
                          >
                            {headerGroup.headers.map((column, columnIndex) => (
                              <div
                                // @ts-ignore
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                className={styles.th}
                                key={`column-${column.id}-${columnIndex}`} // Убедитесь, что column.id уникален
                              >
                                {column.render("Header")}
                                <span>
                                  {/*@ts-ignore*/}
                                  {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                                </span>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div style={{ position: "relative" }}>
                      <div {...getTableBodyProps()} {...rest} style={style} key='table-body'>
                        {children}
                      </div>
                    </div>
                  </>
                )}
              >
                {RenderRow(rows)}
              </VariableSizeList>
            )}
          </InfiniteLoader>
        </div>
      </div>
    </>
  )
}
