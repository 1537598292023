import { takeLatest, put, call, select } from "redux-saga/effects"
import { binanceActions } from "Base/store/deprecated/binance/actions"
import { SagaIterator } from "redux-saga"
import axios, { AxiosResponse } from "axios"
import { TExchangeInfo } from "Base/store/deprecated/binance/types"
import { RootState } from "Starter/store/configureStore"
import {
  getExchangeInfoSymbolsSaga,
  saveExchangeInfoSymbolsSaga,
} from "Base/store/indexedDB/exchangeInfoSaga"

export function* getExchangeInfoSaga(): SagaIterator {
  try {
    const res = yield call(getExchangeInfoSymbolsSaga)
    console.log("getExchangeInfoSaga, res: ", res)
    if (res.length) {
      const response: AxiosResponse<TExchangeInfo> = yield call(
        axios.get,
        "https://api.binance.com/api/v3/exchangeInfo",
      )
      // @ts-ignore
      yield saveExchangeInfoSymbolsSaga(response.data.symbols)
    }
  } catch (e) {
    yield put(
      binanceActions.getExchangeInfoAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* searchingForProfitableCurrencyPairSaga(): SagaIterator {
  try {
    const { exchangeRate: exchangeRate } = yield select(({ base }: RootState) => base)
    const res = yield call(getExchangeInfoSymbolsSaga)
  } catch (e) {
    console.log(e)
  }
}

export function* binanceWatcher(): SagaIterator {
  yield takeLatest(binanceActions.getExchangeInfoAction.call.type, getExchangeInfoSaga)
  yield takeLatest(
    binanceActions.searchingForProfitableCurrencyPairAction.call.type,
    searchingForProfitableCurrencyPairSaga,
  )
}
