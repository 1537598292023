import { SVGProps } from "react"

export function LogoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1280.000000pt'
      height='1280.000000pt'
      viewBox='0 0 1280.000000 1280.000000'
      {...props}
    >
      <g
        transform='translate(0.000000,1280.000000) scale(0.100000,-0.100000)'
        fill='currentColor'
        stroke='none'
      >
        <path
          d='M5470 12780 c-2178 -292 -4019 -1655 -4929 -3648 -252 -552 -433
-1182 -516 -1798 l-26 -191 3 -754 3 -754 28 -175 c89 -553 212 -1005 407
-1490 78 -196 261 -572 369 -760 710 -1237 1796 -2184 3117 -2718 1243 -502
2673 -598 3994 -267 691 173 1359 468 1952 861 1559 1035 2577 2711 2787 4589
31 277 41 462 41 760 0 298 -10 483 -41 760 -130 1161 -578 2273 -1288 3190
-287 371 -679 771 -1036 1057 -780 625 -1688 1053 -2670 1258 -82 17 -235 45
-340 63 l-190 31 -765 2 c-691 2 -778 1 -900 -16z m580 -1940 l0 -1410 -30 0
c-58 0 -337 31 -500 56 -407 62 -893 184 -1277 320 l-101 36 38 91 c237 570
593 1227 980 1811 127 192 289 427 309 448 3 4 74 16 156 27 186 25 207 27
328 29 l97 2 0 -1410z m830 1400 c52 -5 144 -17 204 -26 l108 -17 60 -81 c195
-264 536 -803 738 -1166 206 -369 546 -1089 524 -1109 -14 -14 -392 -135 -574
-185 -406 -111 -877 -196 -1232 -222 l-98 -7 0 1412 0 1411 88 0 c48 0 130 -5
182 -10z m-2170 -225 c0 -3 -40 -63 -89 -133 -244 -349 -505 -804 -707 -1234
-88 -187 -254 -572 -254 -588 0 -6 -3 -10 -6 -10 -3 0 -90 38 -192 85 -324
148 -677 346 -946 529 l-118 81 48 44 c569 518 1296 938 2009 1160 228 71 255
78 255 66z m3417 -39 c759 -227 1461 -624 2080 -1175 30 -27 52 -54 49 -60
-11 -17 -315 -216 -461 -301 -207 -120 -769 -399 -781 -388 -1 2 -33 75 -69
163 -264 633 -620 1285 -954 1748 -28 38 -37 57 -28 57 9 0 82 -20 164 -44z
m-6027 -1692 c268 -186 753 -449 1206 -653 l161 -73 -32 -87 c-56 -150 -182
-539 -240 -741 -198 -686 -316 -1313 -360 -1917 l-7 -93 -1134 0 -1135 0 5 48
c3 26 8 92 11 147 14 246 69 618 131 895 204 904 617 1738 1203 2429 54 64 98
108 105 104 6 -4 45 -30 86 -59z m8594 28 c69 -76 202 -240 289 -356 418 -561
747 -1248 926 -1937 100 -383 168 -808 187 -1171 l7 -128 -1131 0 -1132 0 -4
23 c-3 12 -8 74 -11 137 -35 631 -244 1579 -535 2420 -45 129 -82 241 -83 248
-1 8 51 37 133 73 454 202 975 485 1245 677 39 28 71 51 73 51 1 1 17 -16 36
-37z m-6585 -996 c601 -244 1313 -425 1811 -461 47 -3 95 -8 108 -11 l22 -4 0
-1060 0 -1060 -1330 0 -1331 0 6 73 c18 244 28 350 46 497 75 627 254 1339
501 1998 18 47 36 81 42 78 6 -2 62 -25 125 -50z m4640 -93 c277 -763 443
-1486 506 -2208 8 -93 15 -198 15 -232 l0 -63 -1330 0 -1330 0 0 1060 0 1059
33 5 c17 2 103 12 189 20 500 50 1136 215 1683 435 94 38 172 69 175 70 3 1
29 -65 59 -146z m-5915 -3195 c41 -752 239 -1644 560 -2525 36 -100 66 -186
66 -190 0 -5 -64 -37 -143 -72 -455 -204 -951 -474 -1230 -668 -43 -29 -82
-51 -87 -48 -5 3 -57 63 -115 133 -688 823 -1124 1821 -1269 2904 -22 160 -31
258 -51 526 l-4 62 1133 0 1133 0 7 -122z m3216 -941 l0 -1064 -132 -11 c-537
-48 -1170 -208 -1773 -447 -90 -36 -166 -65 -169 -65 -13 0 -170 466 -252 750
-187 646 -276 1136 -329 1823 l-6 77 1331 0 1330 0 0 -1063z m3217 941 c-30
-594 -184 -1364 -414 -2073 -54 -165 -152 -446 -158 -453 -2 -2 -85 28 -183
67 -602 237 -1174 382 -1727 439 l-170 17 -3 1063 -2 1062 1331 0 1332 0 -6
-122z m2833 65 c0 -32 -7 -137 -15 -233 -105 -1204 -550 -2299 -1303 -3202
-63 -76 -117 -138 -120 -138 -2 0 -57 35 -121 78 -295 200 -695 416 -1146 622
-99 45 -183 85 -186 88 -4 4 15 66 42 137 309 822 540 1821 574 2490 3 61 8
134 11 163 l5 52 1130 0 1129 0 0 -57z m-6050 -4069 l0 -1415 -67 5 c-38 3
-108 8 -158 11 -87 6 -271 29 -328 41 -20 4 -48 35 -116 128 -439 605 -858
1367 -1170 2124 -45 109 -48 123 -33 131 53 27 467 151 692 206 349 85 881
170 1158 184 l22 1 0 -1416z m885 1385 c444 -49 932 -156 1393 -306 106 -34
172 -61 172 -69 0 -7 -40 -107 -89 -221 -320 -750 -672 -1382 -1109 -1995
l-104 -146 -62 -11 c-102 -19 -305 -39 -416 -43 l-105 -3 -3 1400 c-1 770 0
1406 3 1413 5 14 24 13 320 -19z m-3176 -792 c234 -596 569 -1215 921 -1701
32 -43 38 -57 24 -53 -11 3 -48 13 -84 22 -639 159 -1319 502 -1894 957 -111
88 -325 270 -325 278 -1 13 321 225 514 338 210 123 714 373 750 372 5 0 48
-96 94 -213z m5379 156 c264 -119 559 -276 797 -426 142 -89 325 -217 325
-228 0 -7 -126 -118 -260 -230 -448 -373 -1038 -704 -1592 -894 -154 -53 -448
-138 -454 -132 -2 2 13 25 32 53 379 524 718 1161 969 1817 20 53 41 97 46 97
6 0 67 -26 137 -57z'
        />
      </g>
    </svg>
  )
}
